import React, { useEffect, useMemo, useState } from 'react';
import { Button, logout, selectUserProfileId } from '@hwm/ui-lib';
import { Trans } from '@lingui/macro';
import { makeStyles, Menu, Theme } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import PersonIcon from '@material-ui/icons/Person';
import { useAppDispatch, useAppSelector } from '@core/store/store';
import { selectedAccStateSelector } from '@core/store/library-stores/selectors';
import { useUserDetailsDataSource } from './userDetailsDataSource';
import { environment } from '@root/environment';
import { Link } from 'react-router-dom';
import { accountsReducer } from '@core/store/library-stores/accounts';

export const useStyles = makeStyles((theme: Theme) => ({
  userDetailsMenuWrapper: {
    top: '66px !important',
    left: 'unset !important',
    right: 8,
  },
  version: {
    fontSize: '12px',
    textAlign: 'right',
    width: '100%',
    color: '#B6BCCE',
    borderTop: '1px solid #B6BCCE',
    paddingTop: '7px',
    marginTop: '13px',
  },
}));

export const UserDetails: React.FC = () => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { userMainAccount } = useAppSelector(selectedAccStateSelector);

  const userId: number = useAppSelector(selectUserProfileId);

  const { userAccessProfile, getUserAccessProfile, loading, error } = useUserDetailsDataSource();

  useEffect(() => {
    if (userId) {
      getUserAccessProfile(userId);
    }
  }, [userId, getUserAccessProfile]);

  const userRole = useMemo(
    () =>
      userAccessProfile
        ? userAccessProfile?.roles?.find((role) => role.applicationName === environment.appName)?.roleName || null
        : '',
    [userAccessProfile]
  );

  useEffect(() => {
    if (!userAccessProfile || loading || !userId || error) return;

    dispatch(accountsReducer.slice.actions.setUserRole(userRole));
    dispatch(accountsReducer.slice.actions.setRoles(userAccessProfile.roles));
  }, [dispatch, userAccessProfile, userId, userRole, loading, error]);

  return (
    <div>
      <Button
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          setAnchorEl(event.currentTarget);
        }}
        variant="outlined"
        style={{ fontSize: 16 }}
        endIcon={Boolean(anchorEl) ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        startIcon={<PersonIcon />}
      >
        {userMainAccount?.userName}
      </Button>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        classes={{
          paper: `${classes.userDetailsMenuWrapper} px-5 py-3`,
          list: 'flex items-center flex-col text-grey font-bold',
        }}
      >
        <div className="mb-2 text-base">
          <Trans>Parent Account</Trans>: {userMainAccount?.name}
        </div>
        <div className="mb-2 text-base">
          <Trans>User Level</Trans>: {loading ? '' : userRole}
        </div>
        <div className="flex flex-col space-y-2">
          <Link to={`/users/edit/${userId}`} style={{ textDecoration: 'none' }} onClick={() => setAnchorEl(null)}>
            <Button style={{ fontSize: '16px' }}>
              <Trans>My Profile</Trans>
            </Button>
          </Link>
          <Button
            variant="outlined"
            onClick={() => 
              logout({
                sessionCookieDomain: environment.sessionCookieDomain,
                sessionCookieIsSecure: environment.sessionCookieSecure,
                sessionIdleTimeout: environment.sessionCookieIdleTimeout,
              })
            }
            style={{ fontSize: '16px' }}
          >
            <Trans>Log out</Trans>
          </Button>
        </div>
        <div className={classes.version}>
          <Trans comment="Application version">Version</Trans>: {environment.version}
        </div>
      </Menu>
    </div>
  );
};
