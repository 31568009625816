import { AccountToAssociate, AssociatedAccount } from '@models/api/models';
import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../rootReducer';
import { createAccountAssociation, getAccountsToAssociate, getAssociatedAccounts } from './associations.thunks';

const accountsAdapter = createEntityAdapter({
  selectId: (account: AssociatedAccount) => account.id,
  sortComparer: (a, b) => a.name?.localeCompare(b.name) ?? 0,
});

const accountsToAssociateAdapter = createEntityAdapter({
  selectId: (account: AccountToAssociate) => account.id,
  sortComparer: (a, b) => a.name?.localeCompare(b.name) ?? 0,
});

export const associationsSlice = createSlice({
  name: 'associations',
  initialState: {
    accountsEntity: accountsAdapter.getInitialState(),
    accountsToAssociateEntity: accountsToAssociateAdapter.getInitialState(),
  },
  reducers: {
    removeAccountFromEntity(state, action: PayloadAction<number>) {
      const item = accountsAdapter
        .getSelectors()
        .selectAll(state.accountsEntity)
        .find((x) => x.accountId === action.payload);
      if (item) {
        accountsAdapter.removeOne(state.accountsEntity, item.id);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAssociatedAccounts.fulfilled, (state, action: PayloadAction<AssociatedAccount[]>) => {
      accountsAdapter.setAll(state.accountsEntity, action.payload);
    });
    builder.addCase(getAccountsToAssociate.fulfilled, (state, action: PayloadAction<AccountToAssociate[]>) => {
      accountsToAssociateAdapter.setAll(state.accountsToAssociateEntity, action.payload);
    });
    builder.addCase(createAccountAssociation.fulfilled, (state, action: PayloadAction<AssociatedAccount>) => {
      accountsAdapter.addOne(state.accountsEntity, action.payload);
    });
  },
});

export type AssociationsState = ReturnType<typeof associationsSlice.reducer>;
export const accountsEntitySelectors = accountsAdapter.getSelectors<RootState>(
  (state) => state.associations.accountsEntity
);
export const accountsToAssociateEntitySelectors = accountsToAssociateAdapter.getSelectors<RootState>(
  (state) => state.associations.accountsToAssociateEntity
);
export const { removeAccountFromEntity } = associationsSlice.actions;
