import { applicationPaths } from '@core/http/applicationPaths';
import httpClient from '@core/http/httpClient';
import { buildAsyncThunk } from '@core/store/utils';
import { CreateAlarmConditionsRequest } from '@models/api/createAlarmConditionsRequest';
import { DeviceCommandStatus } from '@models/api/deviceCommandStatus';
import {
	AlarmListFilter,
	AlarmListItemPagedResponse,
	BulkEditRequest,
	CreateSiteRequest,
	DefaultLocationDetails,
	Device,
	DeviceDetails,
	EditSiteRequest,
	HierarchyAccount,
	HubDetails,
	ListFilterTypes,
	SiteDetails,
	SiteGpsSettings,
	SiteHistoryEntry,
	SiteLocation,
	SitePhoto,
	SitesAssociationFilter,
	SitesList,
	SitesAndMastsOnMap,
	TransmitterRecording,
	UpdateSiteLocationRequest,
	UpdateSiteSonicSens2Request,
	UpdateSiteSonicSens3Request,
	SiteGisValidationReport,
	GisSiteValidationError,
	GisSiteValidationStatus,
	SiteRadarSensorsModel,
  	SiteOrDeviceHistoryType,
} from '@models/api/models';
import { SendAlarmOptionsRequest } from '@models/api/sendAlarmOptionsRequest';
import { SendBoundaryAlarmConditionRequest } from '@models/api/sendBoundaryAlarmConditionRequest';
import { SendChannelBasedAlarmConditionRequest } from '@models/api/sendChannelBasedAlarmConditionRequest';
import { SendSingleAlarmConditionRequest } from '@models/api/sendSingleAlarmConditionRequest';
import { SendTransientAlarmRequest } from '@models/api/sendTransientAlarmRequest';
import { SendAlarmTriggerCombinationRequest } from '@models/api/sendAlarmTriggerCombinationRequest';
import { SonicSensVersion } from '@models/api/sonicSensVersion';
import { PagedResponse } from '@models/interfaces/PagedResponse';
import { environment } from '@root/environment';
import getFileFromResponse from '@shared/utils/getFileFromResponse';
import { AxiosResponse } from 'axios';
import { SiteClustersList } from '@models/api/siteClustersList';
import { SiteItem } from '@models/api/siteItem';
import { IUpdateGasDeploymentInfo } from '@models/api/updateGasDeploymentInfo';
import { isArray } from 'lodash';
import { SendTimeProfileAlarmConditionRequest } from '@models/api/sendTimeProfileAlarmConditionRequest';


export interface IMapBounds {
  topLeftLat?: number;
  topLeftLng?: number;
  bottomRightLat?: number;
  bottomRightLng?: number;
}

export interface ISitesForListReqConfig {
  search?: string;
  searchIn?: string;
  filterType?: string;
  associationFilter?: SitesAssociationFilter;
  accountId?: number;
  pageSize?: number;
  currentPage?: number;
  showSitesWithoutLocation?: boolean;
}
export interface FetchSitesForMapThunk extends IMapBounds, ISitesForListReqConfig {}

export const fetchSitesForMap = buildAsyncThunk('SITES_FOR_MAP_FETCH', async (params: FetchSitesForMapThunk) => {
  const response: AxiosResponse<SitesAndMastsOnMap> = await httpClient.get(applicationPaths.sites.sitesForMap, {
    params,
  });
  return response.data;
});

export interface GetSiteListThunk {
  filterType: ListFilterTypes;
  associationFilter: SitesAssociationFilter;
  search: string;
  accountId?: number;
  currentPage: number;
  pageSize?: number;
  searchIn?: string;
  orderBy?: string;
  ascending?: boolean;
}

export const fetchSitesForList = buildAsyncThunk('sites/fetchSitesList', async (thunkData: GetSiteListThunk) => {
  if (!thunkData.pageSize) {
    thunkData.pageSize = environment.siteListPageSize;
  }

  const response: AxiosResponse<SitesList> = await httpClient.get(applicationPaths.sites.sitesForList, {
    params: thunkData,
  });

  return response.data;
});

export const fetchNumberOfSelectedSites = buildAsyncThunk(
  'sites/numberOfSelectedSites',
  async ({ accountId }: { accountId: number }) => {
    const response: AxiosResponse<number> = await httpClient.get(applicationPaths.sites.numberOfSelectedSites, {
      params: Number.isFinite(accountId) ? { accountId } : {},
    });

    return response.data;
  }
);

export const fetchSiteDetails = buildAsyncThunk('sites/fetchSiteDetails', async (id: number) => {
  const response: AxiosResponse<SiteDetails> = await httpClient.get(applicationPaths.sites.siteDetails.details(id));
  return response.data;
});

export const fetchSite = buildAsyncThunk('sites/fetchSite', async (id: number) => {
  const response: AxiosResponse<SiteDetails> = await httpClient.get(applicationPaths.sites.siteDetails.details(id));
  return response.data;
});

export interface FetchDefaultSiteLocationThunk {
  accountId: number;
}

export const fetchDefaultSiteLocation = buildAsyncThunk(
  'sites/fetchDefaultSiteLocation',
  async (params: FetchDefaultSiteLocationThunk) => {
    const response: AxiosResponse<DefaultLocationDetails> = await httpClient.get(
      applicationPaths.sites.defaultLocation(),
      { params }
    );
    return response.data;
  }
);

export interface UpdateSiteDetailsThunk {
  siteDetailsId?: number;
  params?: EditSiteRequest;
}

export const updateSiteDetails = buildAsyncThunk(
  'sites/updateSiteDetails',
  async (requestConfig: UpdateSiteDetailsThunk) => {
    const response: AxiosResponse<SiteDetails> = await httpClient.put(
      applicationPaths.sites.siteDetails.site(requestConfig.siteDetailsId),
      requestConfig.params
    );
    return response.data;
  }
);

export const getAlarmForwardingStatus = buildAsyncThunk('sites/getAlarmForwardingStatus', async (siteId: number) => {
  const response: AxiosResponse = await httpClient.get(applicationPaths.sites.siteDetails.alarmForwarding(siteId));
  return response.data;
});

export const enableAlarmForwarding = buildAsyncThunk('sites/enableAlarmForwarding', async (siteId: number) => {
  const response: AxiosResponse = await httpClient.post(applicationPaths.sites.siteDetails.alarmForwarding(siteId));
  return response.data;
});

export const disableAlarmForwarding = buildAsyncThunk('sites/disableAlarmForwarding', async (siteId: number) => {
  const response: AxiosResponse = await httpClient.delete(applicationPaths.sites.siteDetails.alarmForwarding(siteId));
  return response.data;
});

export const isSiteFavourite = buildAsyncThunk('sites/isSiteFavourite', async (siteId: number) => {
  const response: AxiosResponse = await httpClient.get(applicationPaths.sites.siteDetails.isFavourite(siteId));
  return response.data;
});

export interface CreateSiteDetailsThunk {
  params?: CreateSiteRequest;
}

export const createSiteDetails = buildAsyncThunk(
  'sites/createSiteDetails',
  async (requestConfig: CreateSiteDetailsThunk) => {
    const response: AxiosResponse<SiteDetails> = await httpClient.post(
      applicationPaths.sites.siteDetails.root,
      requestConfig.params
    );
    return response.data;
  }
);

export interface UploadSitePhotoThunk {
  siteId: number;
  description: string;
  file: Blob;
  note: string;
}

export const uploadSitePhoto = buildAsyncThunk('sites/uploadPhoto', async (thunkData: UploadSitePhotoThunk) => {
  const formData = new FormData();
  formData.append('File', thunkData.file);
  formData.append('Description', thunkData.description);
  formData.append('Note', thunkData.note);
  const response: AxiosResponse<SitePhoto> = await httpClient.post(
    applicationPaths.sites.siteDetails.uploadPhoto(thunkData.siteId),
    formData,
  );
  return response.data;
});

export interface DeleteSitePhotoThunk {
  siteId: number;
  photoId: number;
}

export const deleteSitePhoto = buildAsyncThunk('sites/deletePhoto', async (thunkData: DeleteSitePhotoThunk) => {
  await httpClient.delete(applicationPaths.sites.siteDetails.sitePhoto(thunkData.siteId, thunkData.photoId));
});

export interface UpdateSitePhotoThunk {
  siteId: number;
  sitePhotoId: number;
  description: string;
  note: string;
}

export const updateSitePhoto = buildAsyncThunk('sites/updatePhoto', async (thunkData: UpdateSitePhotoThunk) => {
  await httpClient.patch(applicationPaths.sites.siteDetails.sitePhoto(thunkData.siteId, thunkData.sitePhotoId), {
    description: thunkData.description,
    note: thunkData.note,
  });
});

export interface UpdateSiteLocationThunk {
  id: number;
  latEast: string;
  longNorth: string;
}

export const updateSiteLocation = buildAsyncThunk('sites/updateSiteLocation', async (data: UpdateSiteLocationThunk) => {
  const url = applicationPaths.sites.updateSiteLocation(data.id);
  const response: AxiosResponse<SiteLocation> = await httpClient.put(url, {
    latEast: data.latEast,
    longNorth: data.longNorth,
  } as UpdateSiteLocationRequest);
  return response.data;
});

export interface FetchDevicesToLinkThunk {
  ascending?: boolean | null;
  orderBy?: string | null;
  ownerId?: number;
  searchPhrase?: string;
  assignmentType?: string;
  pageSize?: number;
  currentPage?: number;
}

export const fetchDevicesToLink = buildAsyncThunk(
  'sites/fetchDevicesToLink',
  async (params: FetchDevicesToLinkThunk) => {
    const response: AxiosResponse<PagedResponse<Device>> = await httpClient.get(applicationPaths.devices.list(), {
      params,
    });
    return response.data;
  }
);

export interface FetchDeviceToLinkThunk {
  deviceId: number;
  siteId?: number;
}

export const fetchDeviceToLinkDetails = buildAsyncThunk(
  'sites/fetchDeviceToLinkDetails',
  async (data: FetchDeviceToLinkThunk) => {
    const detailsResponse: AxiosResponse<DeviceDetails> = await httpClient.get(
      applicationPaths.devices.details(data.deviceId)
    );
    const sensorSerialNumber: AxiosResponse<string> = await httpClient.get(
      applicationPaths.devices.getSensorSerialNumber(data.deviceId),
      {
        params: {
          siteId: data.siteId,
        },
      }
    );
    return {
      ...detailsResponse.data,
      sensorSerialNumber: sensorSerialNumber.data,
    } as DeviceDetails;
  }
);

export interface GetSiteAvailableOwnersThunk {
  searchPhrase?: string;
}

export const fetchAvailableOwners = buildAsyncThunk(
  'sites/fetchAvailableOwners',
  async (data: GetSiteAvailableOwnersThunk) => {
    let params = {};

    if (data.searchPhrase) {
      params = {
        searchPhrase: data.searchPhrase,
      };
    }

    const response: AxiosResponse<HierarchyAccount[]> = await httpClient.get(applicationPaths.accounts.hierarchy(), {
      params: params,
    });
    return response.data;
  }
);

export interface FetchHistoryThunk {
	siteId: number;
	params: {
		currentPage: number;
		pageSize: number;
		from: string;
		to: string;
		orderBy: string | null;
		ascending: boolean | null;
		searchPhrase?: string;
    TypeFilter: SiteOrDeviceHistoryType;
	};
}

export const fetchHistory = buildAsyncThunk(
	'sites/fetchHistory',
	async ({ siteId, params }: FetchHistoryThunk) => {
		const response: AxiosResponse<SiteHistoryEntry[]> = await httpClient.get(
			applicationPaths.sites.siteDetails.fetchHistory(siteId),
			{
				params: params
			}
		);
		return response.data;
	}
);

export interface ExportHistoryThunk {
  siteId: number;
  params: {
    from: string;
    to: string;
    searchPhrase: string;
    TypeFilter: SiteOrDeviceHistoryType;
  };
}

export const exportHistory = buildAsyncThunk('sites/exportHistory', async (thunkData: ExportHistoryThunk) => {
  const response: AxiosResponse = await httpClient.get(
    applicationPaths.sites.siteDetails.exportHistory(thunkData.siteId),
    {
      params: thunkData.params,
    }
  );
  return getFileFromResponse(response);
});

export interface FetchAlarmsThunk {
  siteId: number;
  params: {
    currentPage: number;
    pageSize: number;
    from: string;
    to: string;
    showSystemAlarms: boolean;
    conditionFilter: string;
    filter: AlarmListFilter;
    orderBy?: string;
    ascending?: boolean;
  };
}

export const fetchAlarms = buildAsyncThunk('sites/fetchAlarms', async (thunkData: FetchAlarmsThunk) => {
  const response: AxiosResponse<AlarmListItemPagedResponse> = await httpClient.get(
    applicationPaths.sites.siteDetails.fetchAlarms(thunkData.siteId),
    { params: thunkData.params }
  );
  return response.data;
});

export interface FetchAlarmConditionsThunk {
  siteIds: number[];
  from: string;
  to: string;
  showSystemAlarms: boolean;
}
export interface AlarmConditionsModel {
  conditions: string[];
}
export const fetchAlarmConditions = buildAsyncThunk(
  'sites/fetchAlarmConditions',
  async (params: FetchAlarmConditionsThunk) => {
    const response: AxiosResponse<AlarmConditionsModel> = await httpClient.get(
      applicationPaths.sites.siteDetails.fetchAlarmConditions(),
      {
        params,
        paramsSerializer: (params) => {
          const serializedParams = Object.entries(params ?? {})
            ?.map(([key, val]) => (isArray(val) ? val?.map((v) => `${key}=${v}`)?.join('&') : `${key}=${val ?? ''}`))
            ?.join('&');

          return serializedParams;
        },
      }
    );
    return response.data;
  }
);

export interface ExportAlarmsThunk {
  siteId: number;
  params: {
    from: string;
    to: string;
    showSystemAlarms: boolean;
    filter: AlarmListFilter;
    conditionFilter: string;
  };
}

export const exportAlarms = buildAsyncThunk('sites/exportAlarms', async (thunkData: ExportAlarmsThunk) => {
  const response: AxiosResponse = await httpClient.get(
    applicationPaths.sites.siteDetails.exportAlarms(thunkData.siteId),
    { params: thunkData.params }
  );
  return getFileFromResponse(response);
});

export interface AcknowledgeAlarmThunk {
  siteId: number;
  alarmId: number;
}

export const acknowledgeAlarm = buildAsyncThunk('sites/acknowledgeAlarm', async (thunkData: AcknowledgeAlarmThunk) => {
  const response: AxiosResponse = await httpClient.post(
    applicationPaths.sites.siteDetails.acknowledgeAlarm(thunkData.siteId, thunkData.alarmId)
  );

  return response.data;
});

export interface AcknowledgeAllAlarmsThunk {
  siteId: number;
  from: string;
  to: string;
  showSystemAlarms: boolean;
  conditionFilter: string;
}

export const acknowledgeAllAlarms = buildAsyncThunk(
  'sites/acknowledgeAllAlarms',
  async ({ siteId, ...data }: AcknowledgeAllAlarmsThunk) => {
    const response: AxiosResponse = await httpClient.put(
      applicationPaths.sites.siteDetails.acknowledgeAllAlarms(siteId),
      data
    );
    return response.data;
  }
);

export const bulkEdit = buildAsyncThunk('sites/bulkEdit', async (thunkData: BulkEditRequest) => {
  const response: AxiosResponse = await httpClient.put(applicationPaths.sites.bulkEdit(), thunkData);
  return response.data;
});

export const bulkEditList = buildAsyncThunk('sites/bulkEditList', async (thunkData: BulkEditRequest) => {
  const response: AxiosResponse = await httpClient.put(applicationPaths.sites.bulkEditList(), thunkData);
  return response.data;
});

export interface GetSonicSensThunk {
  siteId: number;
}

export const getSonicSens = buildAsyncThunk('sites/getSonicSens', async (thunkData: GetSonicSensThunk) => {
  const response: AxiosResponse = await httpClient.get(
    applicationPaths.sites.siteDetails.fetchSonicSens(thunkData.siteId)
  );
  return response.data;
});

export interface UpdateSonicSensThunk {
  siteId: number;
  version: SonicSensVersion;
  data: UpdateSiteSonicSens2Request | UpdateSiteSonicSens3Request;
}

export const updateSonicSens = buildAsyncThunk('sites/updateSonicSens', async (thunkData: UpdateSonicSensThunk) => {
  const response: AxiosResponse = await httpClient.put(
    applicationPaths.sites.siteDetails.updateSonicSens(thunkData.siteId, thunkData.version),
    { ...thunkData.data }
  );
  return response.data;
});

export interface getDeploymentInfoThunk {
  siteId: number;
}
export interface UpdateGasDeploymentInfo {
  siteId: number;
  data: IUpdateGasDeploymentInfo;
}

export const getGasDeploymentInfo = buildAsyncThunk(
  'sites/getGasDeploymentInfo',
  async (thunkData: getDeploymentInfoThunk) => {
    const response: AxiosResponse = await httpClient.get(
      applicationPaths.sites.siteDetails.gasDeploymentInfo(thunkData.siteId)
    );
    return response.data;
  }
);

export const updateGasDeploymentInfo = buildAsyncThunk(
  'sites/updateGasDeploymentInfo',
  async (thunkData: IUpdateGasDeploymentInfo) => {
    const response: AxiosResponse = await httpClient.put(
      applicationPaths.sites.siteDetails.gasDeploymentInfo(thunkData.siteId),
      { ...thunkData.data }
    );
    return response.data;
  }
);

export const fetchLegacyAlarmLevels = buildAsyncThunk('sites/fetchLegacyAlarmLevels', async (siteId: number) => {
  const response: AxiosResponse = await httpClient.get(applicationPaths.sites.siteDetails.legacyAlarmLevels(siteId));
  return response.data;
});

export interface UpdateAlarmLevelThunk {
  siteId: number;
  channelId: number;
  params: {
    lowAlarmLevel: number;
    highAlarmLevel: number;
    minNightFlowAlarmLevel: number;
    rateOfChangeAlarmLevel: number;
  };
}

export const updateLegacyAlarmLevel = buildAsyncThunk(
  'sites/updateLegacyAlarmLevel',
  async (datatThunk: UpdateAlarmLevelThunk) => {
    const response: AxiosResponse = await httpClient.put(
      applicationPaths.sites.siteDetails.updateLegacyAlarmLevel(datatThunk.siteId, datatThunk.channelId),
      { ...datatThunk.params }
    );
    return response.data;
  }
);

export const fetchLegacyAlarmConditions = buildAsyncThunk(
  'sites/fetchLegacyAlarmConditions',
  async (siteId: number) => {
    const response: AxiosResponse = await httpClient.get(
      applicationPaths.sites.siteDetails.legacyAlarmConditions(siteId)
    );
    return response.data;
  }
);

export interface AddAlarmConditionsThunk {
  siteId: number;
  params: CreateAlarmConditionsRequest;
}

export const addLegacyAlarmCondition = buildAsyncThunk(
  'sites/addLegacyAlarmConditions',
  async (dataThunk: AddAlarmConditionsThunk) => {
    const response: AxiosResponse = await httpClient.post(
      applicationPaths.sites.siteDetails.legacyAlarmConditions(dataThunk.siteId),
      { ...dataThunk.params }
    );
    return response.data;
  }
);

export interface DeleteAlarmConditionThunk {
  siteId: number;
  conditionId: number;
}

export const deleteLegacyAlarmCondition = buildAsyncThunk(
  'sites/deleteLegacyAlarmCondition',
  async ({ siteId, conditionId }: DeleteAlarmConditionThunk) => {
    const response: AxiosResponse = await httpClient.delete(
      applicationPaths.sites.siteDetails.deleteLegacyAlarmCondition(siteId, conditionId)
    );

    return response.data;
  }
);
export interface FetchCommandsThunk {
  siteId: number;
  params: {
    ascending: boolean | null;
    orderBy: string | null;
    from: string;
    to: string;
    status: DeviceCommandStatus;
    commandType?: string;
    pageSize: number;
    currentPage: number;
  };
}

export const fetchCommands = buildAsyncThunk('sites/getCommands', async (thunkData: FetchCommandsThunk) => {
  const response: AxiosResponse = await httpClient.get(
    applicationPaths.sites.siteDetails.fetchCommands(thunkData.siteId),
    {
      params: thunkData.params,
    }
  );
  return response.data;
});

export interface ExportCommandsThunk {
  siteId: number;
  params: {
    from: string;
    to: string;
    status: DeviceCommandStatus;
    commandType?: string;
    decodingEnabled: boolean;
  };
}

export const exportCommands = buildAsyncThunk('sites/exportCommands', async (thunkData: ExportCommandsThunk) => {
  const response: AxiosResponse = await httpClient.get(
    applicationPaths.sites.siteDetails.exportCommands(thunkData.siteId),
    {
      params: thunkData.params,
    }
  );
  return getFileFromResponse(response);
});

export interface CancelAllDeviceCommandsThunk {
  siteId: number;
  params: {
    from: string;
    to: string;
    status: DeviceCommandStatus;
    commandType?: string;
  };
}

export const cancelAllDeviceCommands = buildAsyncThunk(
  'sites/cancelAllDeviceCommands',
  async (thunkData: CancelAllDeviceCommandsThunk) => {
    const response: AxiosResponse = await httpClient.delete(
      applicationPaths.sites.siteDetails.cancelAllDeviceCommands(thunkData.siteId),
      { data: { ...thunkData.params } }
    );
    return response.data;
  }
);

export interface CancelOneDeviceCommandThunk {
  siteId: number;
  deviceCommandId: number;
}

export const cancelOneDeviceCommand = buildAsyncThunk(
  'sites/cancelOneDeviceCommand',
  async (thunkData: CancelOneDeviceCommandThunk) => {
    const response: AxiosResponse = await httpClient.delete(
      applicationPaths.sites.siteDetails.cancelOneDeviceCommand(thunkData.siteId, thunkData.deviceCommandId)
    );
    return response.data;
  }
);

export interface ExportSitesThunk {
  filterType: ListFilterTypes;
  associationFilter: SitesAssociationFilter;
  search: string;
  accountId?: number;
  searchIn?: string;
}

export const exportSites = buildAsyncThunk('users/exportSites', async (thunkData: ExportSitesThunk) => {
  const response: AxiosResponse = await httpClient.get(applicationPaths.sites.exportSites(), { params: thunkData });
  return getFileFromResponse(response);
});

export const fetchAlarmsOptions = buildAsyncThunk('sites/fetchAlarmsOptions', async (siteId: number) => {
  const response: AxiosResponse = await httpClient.get(applicationPaths.sites.siteDetails.alarmUnilogOptions(siteId));
  return response.data;
});

export interface UpdateAlarmsOptionsThunk {
  siteId: number;
  data: SendAlarmOptionsRequest;
}

export const updateAlarmsOptions = buildAsyncThunk(
  'sites/updateAlarmsOptions',
  async (dataThunk: UpdateAlarmsOptionsThunk) => {
    const response: AxiosResponse = await httpClient.post(
      applicationPaths.sites.siteDetails.alarmUnilogOptions(dataThunk.siteId),
      { ...dataThunk.data }
    );
    return response.data;
  }
);

export interface FetchAlarmUnilogLevelsThunk {
  siteId: number;
  conditionNumber?: number;
}

export const fetchAlarmUnilogLevels = buildAsyncThunk(
  'sites/fetchAlarmUnilogLevels',
  async ({ siteId, ...params }: FetchAlarmUnilogLevelsThunk) => {
    const response: AxiosResponse = await httpClient.get(applicationPaths.sites.siteDetails.alarmUnilogLevels(siteId), {
      params,
    });
    return response.data;
  }
);

export const fetchAlarmMicrologTriggerActions = buildAsyncThunk(
  'sites/fetchAlarmMicrologTriggerActions',
  async (siteId: number) => {
    const response: AxiosResponse = await httpClient.get(
      applicationPaths.sites.siteDetails.alarmMicrologTriggerActions(siteId)
    );
    return response.data;
  }
);

export interface AddAlarmUnilogLevelsSingleThunk {
  siteId: number;
  channelId: number;
  data: SendSingleAlarmConditionRequest;
}

export const addAlarmUnilogLevelsSingle = buildAsyncThunk(
  'sites/addAlarmUnilogLevelsSingle',
  async (dataThunk: AddAlarmUnilogLevelsSingleThunk) => {
    const response: AxiosResponse = await httpClient.post(
      applicationPaths.sites.siteDetails.addAlarmUnilogSingle(dataThunk.siteId, dataThunk.channelId),
      { ...dataThunk.data }
    );
    return response.data;
  }
);

export interface AddAlarmUnilogTimeProfileThunk {
  siteId: number;
  channelId: number;
  data: SendTimeProfileAlarmConditionRequest;
}

export const addAlarmUnilogTimeProfile = buildAsyncThunk(
  'sites/addAlarmUnilogTimeProfile',
  async ({ siteId, channelId, data }: AddAlarmUnilogTimeProfileThunk) => {
    const response: AxiosResponse = await httpClient.post(
      applicationPaths.sites.siteDetails.addAlarmUnilogTimeProfile(siteId, channelId),
      { ...data }
    );
    return response.data;
  }
);

export interface DisableAlarmCondition {
  siteId: number;
  conditionNumber: number;
}

export const disableAlarmCondition = buildAsyncThunk(
  'sites/DisableAlarmCondition',
  async (dataThunk: DisableAlarmCondition) => {
    const response: AxiosResponse = await httpClient.delete(
      applicationPaths.sites.siteDetails.disableAlarmCondition(dataThunk.siteId, dataThunk.conditionNumber)
    );
    return response.data;
  }
);

export interface AddAlarmUnilogLevelsBoundaryThunk {
  siteId: number;
  channelId: number;
  data: SendBoundaryAlarmConditionRequest;
}

export const addAlarmUnilogBoundary = buildAsyncThunk(
  'sites/addAlarmUnilogBoundary',
  async (dataThunk: AddAlarmUnilogLevelsBoundaryThunk) => {
    const response: AxiosResponse = await httpClient.post(
      applicationPaths.sites.siteDetails.addAlarmUnilogBoundary(dataThunk.siteId, dataThunk.channelId),
      { ...dataThunk.data }
    );
    return response.data;
  }
);
export interface AddAlarmUnilogLevelsConditionBasedThunk {
  siteId: number;
  channelId: number;
  conditionalChannelId: number;
  data: SendChannelBasedAlarmConditionRequest;
}

export const addAlarmUnilogConditionBased = buildAsyncThunk(
  'sites/addAlarmUnilogConditionBased',
  async (dataThunk: AddAlarmUnilogLevelsConditionBasedThunk) => {
    const response: AxiosResponse = await httpClient.post(
      applicationPaths.sites.siteDetails.addAlarmUnilogBasedOnChannel(
        dataThunk.siteId,
        dataThunk.channelId,
        dataThunk.conditionalChannelId
      ),
      { ...dataThunk.data }
    );
    return response.data;
  }
);

export interface AddAlarmTriggerCombinationsThunk {
  siteId: number;
  data: SendAlarmTriggerCombinationRequest;
}

export const addAlarmTriggerCombinations = buildAsyncThunk(
  'sites/addAlarmTriggerCombinations',
  async (dataThunk: AddAlarmTriggerCombinationsThunk) => {
    const response: AxiosResponse = await httpClient.post(
      applicationPaths.sites.siteDetails.alarmMicrologTriggerActions(dataThunk.siteId),
      { ...dataThunk.data }
    );
    return response.data;
  }
);

export const fetchTransient = buildAsyncThunk('sites/fetchTransient', async (siteId: number) => {
  const response: AxiosResponse = await httpClient.get(applicationPaths.sites.siteDetails.fetchTransient(siteId));
  return response.data;
});

export interface UpdateTransientThunk {
  siteId: number;
  data: SendTransientAlarmRequest;
}

export const updateTransient = buildAsyncThunk('sites/updateTransient', async (dataThunk: UpdateTransientThunk) => {
  const response: AxiosResponse = await httpClient.post(
    applicationPaths.sites.siteDetails.updateTransient(dataThunk.siteId),
    dataThunk.data
  );
  return response.data;
});

export const fetchGpsSettings = buildAsyncThunk('sites/fetchGpsSettings', async (siteId: number) => {
  const response: AxiosResponse<SiteGpsSettings> = await httpClient.get(
    applicationPaths.sites.siteDetails.siteGpsSettings(siteId)
  );
  return response.data;
});

export interface UpdateGpsSettingsThunk {
  siteId: number;
  data: SiteGpsSettings;
}

export const updateGpsSettings = buildAsyncThunk(
  'sites/updateGpsSettings',
  async (dataThunk: UpdateGpsSettingsThunk) => {
    const response: AxiosResponse = await httpClient.post(
      applicationPaths.sites.siteDetails.siteGpsSettings(dataThunk.siteId),
      dataThunk.data
    );
    return response.data;
  }
);

export const fetchHub = buildAsyncThunk('sites/fetchHub', async (siteId: number) => {
  const response: AxiosResponse<HubDetails> = await httpClient.get(applicationPaths.sites.siteDetails.siteHub(siteId));
  return response.data;
});

export const fetchHubTransmitterRecording = buildAsyncThunk(
  'sites/fetchHubTransmitterRecording',
  async (siteId: number) => {
    const response: AxiosResponse<TransmitterRecording[]> = await httpClient.get(
      applicationPaths.sites.siteDetails.siteHubTransmitterRecording(siteId)
    );
    return response.data;
  }
);

export interface FetchClustersThunk {
  topLeftLat?: number;
  topLeftLng?: number;
  bottomRightLat?: number;
  bottomRightLng?: number;
  accountId?: number;
  search?: string;
  searchIn?: string;
  filterType?: string;
  associationFilter?: SitesAssociationFilter;
  zoom?: number;
  showSitesWithoutLocation?: boolean;
}

export const fetchClusters = buildAsyncThunk('sites/fetchClusters', async (params: FetchClustersThunk) => {
  const response: AxiosResponse<SiteClustersList> = await httpClient.get(applicationPaths.sites.fetchClusters(), {
    params,
  });
  return response.data;
});

interface GetAllSitesThunk {
  search?: string;
  searchIn?: string;
  accountId?: number;
}

export const getAllSites = buildAsyncThunk('sites/getAllSites', async (params: GetAllSitesThunk) => {
  const response: AxiosResponse<SiteItem[]> = await httpClient.get(applicationPaths.sites.allSites(), {
    params,
  });
  return response.data;
});

export interface GetGisValidationThunk {
  importId?: string;
  ascending?: boolean;
  orderBy?: string;
  validationStatus?: GisSiteValidationStatus;
  validationError?: GisSiteValidationError;
}

export const getGisValidation = buildAsyncThunk('sites/getGisValidation', async (params: GetGisValidationThunk) => {
  const response: AxiosResponse<SiteGisValidationReport> = await httpClient.get(
    applicationPaths.sites.gisValidation(),
    {
      params,
    }
  );
  return response.data;
});

export interface ValidateSitesAgainstGisThunk {
  importId: string;
}

export const validateGis = buildAsyncThunk('sites/validateGis', async (dataThunk: ValidateSitesAgainstGisThunk) => {
  const response: AxiosResponse = await httpClient.put(applicationPaths.sites.validateGis(), {
    ...dataThunk,
  });
  return response.data;
});

export interface GetRadarSensorsThunk {
  siteId: number;
}

export const getRadarSensors = buildAsyncThunk('sites/getRadarSensors', async (thunkData: GetRadarSensorsThunk) => {
  const response: AxiosResponse<SiteRadarSensorsModel> = await httpClient.get(
    applicationPaths.sites.siteDetails.fetchRadarSensors(thunkData.siteId)
  );
  return response.data;
});

export interface UpdateRadarSensorsThunk {
  siteId: number;
  data: SiteRadarSensorsModel;
}

export const updateRadarSensors = buildAsyncThunk(
  'sites/updateRadarSensors',
  async (thunkData: UpdateRadarSensorsThunk) => {
    const {
      siteId,
      data: { ...formData },
    } = thunkData;

    const response: AxiosResponse = await httpClient.put(
      applicationPaths.sites.siteDetails.updateRadarSensors(siteId, formData.radarSensorsType),
      { ...formData }
    );
    return response.data;
  }
);
