import { AccountType, Application, Country, Unit, GasZone } from '@models/api/models';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  fetchCountriesDictionary,
  fetchChannelTypes,
  fetchUnitsDictionary,
  fetchAccountTypes,
  fetchApplications,
  fetchGasZones,
} from './dictionaries.thunk';
import { ChannelType } from '@models/api/models';
import { translate } from '@shared/utils/dictionariesTranslations';

export const dictionariesSlice = createSlice({
  name: 'dictionaries',
  initialState: {
    units: [] as Unit[],
    countries: {
      list: [] as Country[],
      isFetched: false,
    },
    channelTypes: [] as ChannelType[],
    applications: [] as Application[],
    accountTypes: [] as AccountType[],
    gasZones: [] as GasZone[],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCountriesDictionary.fulfilled, (state, action: PayloadAction<Country[]>) => {
      state.countries.list = action.payload;
      state.countries.isFetched = true;
    });
    builder.addCase(fetchUnitsDictionary.fulfilled, (state, action: PayloadAction<Unit[]>) => {
      const payload = action.payload;
      payload.forEach((x) => (x.type = translate.channelType(x.type)));
      state.units = payload;
    });
    builder.addCase(fetchChannelTypes.fulfilled, (state, action: PayloadAction<ChannelType[]>) => {
      const payload = action.payload;
      payload.forEach((x) => (x.name = translate.channelType(x.name)));

      state.channelTypes = payload;
    });
    builder.addCase(fetchAccountTypes.fulfilled, (state, action: PayloadAction<AccountType[]>) => {
      const payload = action.payload;
      payload.forEach((x) => (x.name = translate.accountType(x.name)));

      state.accountTypes = payload;
    });
    builder.addCase(fetchApplications.fulfilled, (state, action: PayloadAction<Application[]>) => {
      state.applications = action.payload;
    });
    builder.addCase(fetchGasZones.fulfilled, (state, action: PayloadAction<GasZone[]>) => {
      state.gasZones = action.payload;
    });
  },
});

export type DictionariesState = ReturnType<typeof dictionariesSlice.reducer>;

export default dictionariesSlice;
