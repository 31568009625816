declare global {
  interface Window {
    _env_: any;
  }
}

export const environment = {
  // helmet meta data
  metaTitle: window._env_.REACT_APP_META_TITLE,
  metaDescription: window._env_.REACT_APP_META_DESC,
  metaUrl: window._env_.REACT_APP_META_URL,
  metaImg: window._env_.REACT_APP_META_IMG,
  metaImgAlt: window._env_.REACT_APP_META_IMG_ALT,
  //
  apiUrl: window._env_.REACT_APP_API_URL,
  clientId: window._env_.REACT_APP_IS_CLIENT,
  authority: window._env_.REACT_APP_IS_URL,
  scope: window._env_.REACT_APP_IS_SCOPE,
  silentLoginTimeout: 20000, // ms
  appName: 'DataGate',
  defaultCoords: {
    lat: 51.505717,
    lng: -0.1269986,
  },
  isRunningInDocker: window._env_.REACT_APP_RUNNING_IN_DOCKER,
  apiKey: window._env_.REACT_APP_GMAPS_KEY,
  defaultLocale: 'en-GB',
  displayDateFormat: 'P',
  displayDateFormatWithHours: 'P p',
  displayDateFormatWithHoursAndSeconds: 'P pp',
  imageExtensions: ['.jpeg', '.jpg', '.gif', '.png', '.heic'],
  gisExtensions: ['.zip'],
  maxImageFileSize: 1024 * 1024 * 5, //5 MB,
  siteListPageSize: 50,
  useLocalTranslations: window._env_.REACT_APP_USE_LOCAL_TRANSLATIONS,
  devicePlotsHistoryDaysRange: window._env_.REACT_APP_DEVICE_PLOTS_HISTORY_DAYS_RANGE,
  gisImport: {
    pollingIntervalMilliseconds: 1500,
    warnUserAfterAttemptsCount: 20,
  },
  sessionCookieDomain: window._env_.REACT_APP_SESSION_COOKIE_DOMAIN,
  sessionCookieSecure: Boolean(window._env_.REACT_APP_SESSION_COOKIE_SECURE),
  sessionCookieIdleTimeout: Number(window._env_.REACT_APP_SESSION_COOKIE_IDLE_TIMEOUT),
  minInputWidth: 90,
  version: window._env_.REACT_APP_VERSION,
  minimumPasswordLength: Number(window._env_.REACT_APP_MINIMUM_PASSWORD_LENGTH),
  sessionCookie: 'hwm-session-expiration',
  // map
  clusterMarkerSize: 36,
  // map zoom
  zoomForClusters: 14,
  zoomLevelOnSingleSite: 16,
  zoomLevelMastOnly: 13,
  minMapZoom: 3,
  // GIS
  pnetRedirectUrl: window._env_.REACT_APP_PNET_REDIRECT_URL,
};
