import { lazy } from 'react';
import { PermissionsTypes } from '@models/permission/PermissionsTypes';
import { pathFactory } from '@hwm/ui-lib';

const DashboardPageComponent = lazy(() => import('@features/DashboardPage/DashboardPage'));
const UsersComponent = lazy(() => import('@features/Users/Users'));
const AccountsComponent = lazy(() => import('@features/Accounts/Accounts'));
const DevicesComponent = lazy(() => import('@features/Devices/Devices'));
const GISComponent = lazy(() => import('@features/GIS/GIS'));
const TranslationEditorComponent = lazy(() => import('@features/TranslationEditor/TranslationEditor'));
const SetPasswordComponent = lazy(() => import('@features/Password/SetPassword'));
const ResetPasswordComponent = lazy(() => import('@features/Password/ResetPassword'));
const UtilitiesComponent = lazy(() => import('@features/Utilities/Utilities'));
const ReportingComponent = lazy(() => import('@features/ReportingOverview/ReportingOverview'));
const ScheduledReportsComponent = lazy(() => import('@features/ScheduledReports/ScheduledReports'));
const FeatureFlagsComponent = lazy(() => import('@features/FeatureFlags'));

export interface EntityDetailsRouteParams {
  id?: string;
  action?: string;
  actionMode?: string;
  actionItemId?: string;
}

export const AppPaths = {
  dataGate: pathFactory(
    {
      base: '/dashboard',
    },
    DashboardPageComponent,
    true
  ),
  devices: pathFactory(
    { base: '/devices' },

    DevicesComponent,
    true
  ),
  gis: pathFactory(
    { base: '/gis' },

		GISComponent,
		true
	),
	users: pathFactory({ base: '/users' }, UsersComponent, true),
	accounts: pathFactory({ base: '/accounts' }, AccountsComponent, true),
	translations: pathFactory(
		{ base: { path: '/translations', permissions: [PermissionsTypes.CanReadTemplateTranslations] } },
		TranslationEditorComponent,
		true
	),
	utilities: pathFactory({ base: '/utilities' }, UtilitiesComponent, true),
	reporting: pathFactory({ base: '/reporting' }, ReportingComponent, true),
	scheduledReports: pathFactory(
		{ base: { path: '/scheduledReports', } },
		ScheduledReportsComponent,
		true
	),
	featureFlags: pathFactory(
		{ base: { path: '/featureFlags', permissions: [PermissionsTypes.CanManageFeatureFlags] } },
		FeatureFlagsComponent,
		true
	),
	setPassword: pathFactory({ base: '/set-password' }, SetPasswordComponent, false),
	resetPassword: pathFactory({ base: '/reset-password' }, ResetPasswordComponent, false),
};
