/**
 * Datagate 3 API Doc
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type ConfigParameterSimplifiedDataType =
  | 'Numeric'
  | 'Text'
  | 'PhoneNumber'
  | 'Date'
  | 'DataSendTime'
  | 'ChannelConfig'
  | 'EclBlockConfig'
  | 'PulsePinModeConfig'
  | 'PulseDataModeConfig'
  | 'DropDown';

export const ConfigParameterSimplifiedDataType = {
  Numeric: 'Numeric' as ConfigParameterSimplifiedDataType,
  Text: 'Text' as ConfigParameterSimplifiedDataType,
  PhoneNumber: 'PhoneNumber' as ConfigParameterSimplifiedDataType,
  Date: 'Date' as ConfigParameterSimplifiedDataType,
  DataSendTime: 'DataSendTime' as ConfigParameterSimplifiedDataType,
  ChannelConfig: 'ChannelConfig' as ConfigParameterSimplifiedDataType,
  EclBlockConfig: 'EclBlockConfig' as ConfigParameterSimplifiedDataType,
  PulsePinModeConfig: 'PulsePinModeConfig' as ConfigParameterSimplifiedDataType,
  PulseDataModeConfig: 'PulseDataModeConfig' as ConfigParameterSimplifiedDataType,
  DropDown: 'DropDown' as ConfigParameterSimplifiedDataType,
};
