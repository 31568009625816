import { t } from '@lingui/macro';

export interface BaseRadarSensorsModel {
  radarSensorsType: RadarSensorsType;
  sensorMinRange?: number;
  sensorMaxRange?: number;
  depthDistanceMode?: RadarSensDepthDistanceModeType;
  sensorChamberDepth?: number;
  lostReflection?: number;
}

export interface RadarSensSensorModel extends BaseRadarSensorsModel {
  thresholdType?: RadarSensThresholdType;
  thresholdFixedLevel?: number;
  customThreshold?: Array<CustomThresholdRow>;
}
export interface UDLiveSensorModel extends BaseRadarSensorsModel {}

export interface CustomThresholdRow {
  index?: number;
  amplitude?: number;
}

export type SiteRadarSensorsModel = RadarSensSensorModel | UDLiveSensorModel;
export type SiteRadarSensorFormModel<RSType extends RadarSensorsType> = RSType extends 'RadarSens'
  ? RadarSensSensorModel
  : RSType extends 'UDLive'
    ? UDLiveSensorModel
    : never;

const RadarSensors = ['RadarSens', 'UDLive'] as const;
export type RadarSensorsType = (typeof RadarSensors)[number];
export const RadarSensorsOptionsFn = (): Record<RadarSensorsType, string> => ({
  RadarSens: t`RadarSens`,
  UDLive: t`UDLive`,
});

const RadarSensDepthDistanceMode = {
  Distance: 'Distance',
  Depth: 'Depth',
} as const;
export type RadarSensDepthDistanceModeType =
  (typeof RadarSensDepthDistanceMode)[keyof typeof RadarSensDepthDistanceMode];
export const RadarSensDepthDistanceModeOptions: Record<RadarSensDepthDistanceModeType, string> = {
  [RadarSensDepthDistanceMode.Distance]: t`Distance`,
  [RadarSensDepthDistanceMode.Depth]: t`Depth`,
};

const RadarSensThreshold = {
  FixedLevel: 'FixedLevel',
  Custom: 'Custom',
  None: 'None',
} as const;
export type RadarSensThresholdType = (typeof RadarSensThreshold)[keyof typeof RadarSensThreshold];
export const RadarSensThresholdOptions: Record<RadarSensThresholdType, string> = {
  [RadarSensThreshold.FixedLevel]: t`Fixed Level`,
  [RadarSensThreshold.Custom]: t`Custom`,
  [RadarSensThreshold.None]: t`None`,
};
