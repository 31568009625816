import { t } from '@lingui/macro';
import CustomObject from '@models/interfaces/CustomObject';

export const channelTypesDictionary = (): CustomObject => {
  return {
    'Abs. Humidity': t`Abs. Humidity`,
    'Actual Conductivity': t`Actual Conductivity`,
    'Air density': t`Air density`,
    'Air Pressure': t`Air Pressure`,
    'Batch Litres': t`Batch Litres`,
    Battery: t`Battery`,
    'Chlorine (Cl2)': t`Chlorine (Cl2)`,
    CO2: t`CO2`,
    Compass: t`Compass`,
    Count: t`Count`,
    CSQ: t`CSQ`,
    Current: t`Current`,
    Depth: t`Depth`,
    'Dew Point': t`Dew Point`,
    'Dissolved Oxygen': t`Dissolved Oxygen`,
    Distance: t`Distance`,
    Electricity: t`Electricity`,
    Error: t`Error`,
    Flow: t`Flow`,
    'Flow (4-20)': t`Flow (4-20)`,
    'Flow (US)': t`Flow (US)`,
    'Flow Balance': t`Flow Balance`,
    'Flow Interval': t`Flow Interval`,
    'Flow Rate': t`Flow Rate`,
    Frequency: t`Frequency`,
    Gas: t`Gas`,
    'Gas Inlet Pressure': t`Gas Inlet Pressure`,
    'Gas Outlet Pressure': t`Gas Outlet Pressure`,
    'Global Radiation': t`Global Radiation`,
    H2S: t`H2S`,
    Humidity: t`Humidity`,
    Interval: t`Interval`,
    Leak: t`Leak`,
    'Lightning Events': t`Lightning Events`,
    Methane: t`Methane`,
    Nitrate: t`Nitrate`,
    Noise: t`Noise`,
    ORP: t`ORP`,
    Other: t`Other`,
    'Oxygen Saturation': t`Oxygen Saturation`,
    pH: t`pH`,
    'Precipitation Amount': t`Precipitation Amount`,
    'Precipitation Intensity': t`Precipitation Intensity`,
    'Precipitation Type': t`Precipitation Type`,
    Pressure: t`Pressure`,
    Quality: t`Quality`,
    Rainfall: t`Rainfall`,
    Salinity: t`Salinity`,
    'Salinity Saturation': t`Salinity Saturation`,
    SpeedOfSound: t`SpeedOfSound`,
    Spread: t`Spread`,
    Status: t`Status`,
    Temperature: t`Temperature`,
    Time: t`Time`,
    'Total Dissolved Solids': t`Total Dissolved Solids`,
    'Total Litres': t`Total Litres`,
    Turbidity: t`Turbidity`,
    Velocity: t`Velocity`,
    Voltage: t`Voltage`,
    Water: t`Water`,
    'Water (US)': t`Water (US)`,
    'Wet Bulb Temperature': t`Wet Bulb Temperature`,
    'Wind Chill': t`Wind Chill`,
    'Wind Direction': t`Wind Direction`,
    'Wind Quality': t`Wind Quality`,
    'Wind Speed': t`Wind Speed`,
  };
};
