import { combineReducers } from '@reduxjs/toolkit';
import { sitesSlice } from './sites/sites.reducer';
import { statisticsSlice } from './statistics/statistics.reducer';
import { channelsSlice } from './channels/channels.reducer';
import { associationsSlice } from './associations/associations.reducer';
import { devicesSlice } from './devices/devices.reducer';
import { usersSlice } from './users/users.reducer';
import { rolesSlice } from './roles/roles.reducer';
import { dictionariesSlice } from './dictionaries/dictionaries.reducer';
import { favouriteListsSlice } from './favouriteLists/favouriteLists.reducer';
import { errorsSlice } from './errors/errors.reducer';
import { sidebarSlice } from './sidebar/sidebar.reducer';
import { preferencesSlice } from './preferences/preferences.reducer';
import { gisManagementSlice } from './gis/gisManagement.reducer';
import mapSlice from './map/map.reducer';
import { userProfileReducer } from './library-stores/user-profile';
import { accountsReducer } from './library-stores/accounts';

export const rootReducer = combineReducers({
  [sitesSlice.name]: sitesSlice.reducer,
  [associationsSlice.name]: associationsSlice.reducer,
  [devicesSlice.name]: devicesSlice.reducer,
  [channelsSlice.name]: channelsSlice.reducer,
  [statisticsSlice.name]: statisticsSlice.reducer,
  [usersSlice.name]: usersSlice.reducer,
  [rolesSlice.name]: rolesSlice.reducer,
  [dictionariesSlice.name]: dictionariesSlice.reducer,
  [favouriteListsSlice.name]: favouriteListsSlice.reducer,
  [errorsSlice.name]: errorsSlice.reducer,
  [sidebarSlice.name]: sidebarSlice.reducer,
  [preferencesSlice.name]: preferencesSlice.reducer,
  [gisManagementSlice.name]: gisManagementSlice.reducer,
  [mapSlice.name]: mapSlice.reducer,
  [accountsReducer.slice.name]: accountsReducer.slice.reducer,
  [userProfileReducer.slice.name]: userProfileReducer.slice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
