/**
 * Datagate 3 API Doc
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type MessageTypeFilter = 'All' | 'Config' | 'Data' | 'Sms' | 'Gprs' | 'LocalConfig' | 'Alarm' | 'Manual';

export const MessageTypeFilter = {
  All: 'All' as MessageTypeFilter,
  Config: 'Config' as MessageTypeFilter,
  Data: 'Data' as MessageTypeFilter,
  Sms: 'Sms' as MessageTypeFilter,
  Gprs: 'Gprs' as MessageTypeFilter,
  LocalConfig: 'LocalConfig' as MessageTypeFilter,
  Alarm: 'Alarm' as MessageTypeFilter,
  Manual: 'Manual' as MessageTypeFilter,
};
