import { applicationPaths } from '@core/http/applicationPaths';
import { httpClient, simpleClient } from '@core/http/httpClient';
import { environment } from '@root/environment';

interface CompiledTranslations {
  [key: string]: any;
}

export const loadLocale = async (lang: string) => {
  let compiledMessages: CompiledTranslations = {};
  const useLocalTranslations = environment.useLocalTranslations;

  const localTranslations = await fetchLocalLang(lang);

  if ((typeof useLocalTranslations === 'string' && useLocalTranslations === 'true') || useLocalTranslations === true) {
    compiledMessages = localTranslations;
  } else {
    try {
      const langUrl = await fetchLiveTranslationUrl(lang);
      const liveTranslations = await fetchLiveTranslations(langUrl);
      const keys = Object.keys(liveTranslations);
      keys.forEach((key: string) => {
        liveTranslations[key] = interpolateLocales(liveTranslations[key]) || localTranslations?.messages?.[key] || key;
      });

      compiledMessages = {
        messages: liveTranslations,
      };
    } catch (e) {
      console.warn(`Live translations unavailable for ${lang} - switching to local translations`);
      compiledMessages = localTranslations;
    }
  }

  return compiledMessages;
};

const fetchLocalLang = async (lang: string) => await import(`@lingui/loader!../../locales/${lang}.json`);

const fetchLiveTranslationUrl = async (languageCode: string) => {
  const liveUrlResponse = await httpClient.get<string>(applicationPaths.translations.live(languageCode, 'Datagate'));
  const url = liveUrlResponse.data;
  return url;
};

const fetchLiveTranslations = async (url: string) => {
  const result = await simpleClient.get<CompiledTranslations>(url);
  const translations = result.data;
  return translations;
};

const interpolateLocales = (value: string) => {
  const regexMatch = new RegExp('{.*?}', 'g');
  const interpolation = value?.match(regexMatch);

  if (!interpolation) return value;

  const regexSplit = new RegExp('{.*?}');
  const splitted = value.split(regexSplit);
  const result: Array<string | string[]> = [];
  let index = 0;

  splitted.forEach((part) => {
    result.push(part);
    if (interpolation && index < interpolation.length) {
      let interpolatedPart = interpolation[index];
      if (interpolatedPart) {
        interpolatedPart = interpolatedPart.slice(1, -1);
        result.push([interpolatedPart]);
      }
    }
    index++;
  });

  if (interpolation && index < interpolation.length) {
    for (let i = index; i < interpolation.length; i++) {
      let interpolatedPart = interpolation[index];
      if (interpolatedPart) {
        interpolatedPart = interpolatedPart.slice(1, -1);
        result.push([interpolatedPart]);
      }
    }
  }

  return result;
};
