/**
 * Datagate 3 API Doc
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type GisSiteValidationError =
  | 'NoSiteCoordinates'
  | 'SiteCoordinatesDoNotMatchAsset'
  | 'NoAssetsWithMatchingName'
  | 'MultipleAssetsWithMatchingName'
  | 'NoAssetCoordinates'
  | 'InvalidAssetCoordinateCount'
  | 'SuspectDefaultAssetCoordinates'
  | 'SuspectDefaultSiteCoordinates';

export const GisSiteValidationError = {
  NoSiteCoordinates: 'NoSiteCoordinates' as GisSiteValidationError,
  SiteCoordinatesDoNotMatchAsset: 'SiteCoordinatesDoNotMatchAsset' as GisSiteValidationError,
  NoAssetsWithMatchingName: 'NoAssetsWithMatchingName' as GisSiteValidationError,
  MultipleAssetsWithMatchingName: 'MultipleAssetsWithMatchingName' as GisSiteValidationError,
  NoAssetCoordinates: 'NoAssetCoordinates' as GisSiteValidationError,
  InvalidAssetCoordinateCount: 'InvalidAssetCoordinateCount' as GisSiteValidationError,
  SuspectDefaultAssetCoordinates: 'SuspectDefaultAssetCoordinates' as GisSiteValidationError,
  SuspectDefaultSiteCoordinates: 'SuspectDefaultSiteCoordinates' as GisSiteValidationError,
};
