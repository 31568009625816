import {
  IActionsHandlerCallback as IActionsHandlerCallbackBase,
  IActionsHandler as IActionsHandlerBase,
  createActionInterceptor,
} from '@hwm/ui-lib';
import { RootState } from '../rootReducer';

export interface IActionsHandlerCallback extends IActionsHandlerCallbackBase<RootState> {}

export interface IActionsHandler extends IActionsHandlerBase<RootState> {}
export const actionInterceptor = createActionInterceptor<RootState>();
