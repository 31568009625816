/**
 * Datagate 3 API Doc
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type SitesAssociationFilter = 'Associated' | 'Owned' | 'Both' | 'DirectAssociated' | 'DirectAssociatedAndOwned';

export const SitesAssociationFilter = {
  Associated: 'Associated' as SitesAssociationFilter,
  Owned: 'Owned' as SitesAssociationFilter,
  Both: 'Both' as SitesAssociationFilter,
  DirectAssociated: 'DirectAssociated' as SitesAssociationFilter,
  DirectAssociatedAndOwned: 'DirectAssociatedAndOwned' as SitesAssociationFilter,
};
