import { t } from '@lingui/macro';
import CustomObject from '@models/interfaces/CustomObject';

export const configChannelTypesDictionary = (): CustomObject => {
  return {
    '<< disabled >>': t`<< disabled >>`,
    'Pressure 1': t`Pressure 1`,
    'Pressure 2': t`Pressure 2`,
    'Pressure 3': t`Pressure 3`,
    'Pressure 4': t`Pressure 4`,
    '4 - 20mA': t`4 - 20mA`,
    'Analogue external 1': t`Analogue external 1`,
    'Analogue external 2': t`Analogue external 2`,
    'Flow 1': t`Flow 1`,
    'Flow 2': t`Flow 2`,
    'Flow 3': t`Flow 3`,
    'Flow 4': t`Flow 4`,
    'Flow Bidirection(1 / 2)': t`Flow Bidirection(1 / 2)`,
    'Flow AMR': t`Flow AMR`,
    'Flow Bidirection(3 / 4)': t`Flow Bidirection(3 / 4)`,
    'Flow Bidirection(5 / 6)': t`Flow Bidirection(5 / 6)`,
    'Permalog Leak': t`Permalog Leak`,
    'Permalog Noise Level': t`Permalog Noise Level`,
    'Permalog Spread': t`Permalog Spread`,
    SonicSense1: t`SonicSense1`,
    SonicSense2: t`SonicSense2`,
    SonicSense3: t`SonicSense3`,
    SonicSense4: t`SonicSense4`,
    SonicSense5: t`SonicSense5`,
    SonicSense6: t`SonicSense6`,
    SonicSense7: t`SonicSense7`,
    SonicSenseQ: t`SonicSenseQ`,
    'Internal Temperature': t`Internal Temperature`,
    'Battery voltage': t`Battery voltage`,
    'Half battery voltage': t`Half battery voltage`,
    CSQ: t`CSQ`,
    'Loaded battery volt': t`Loaded battery volt`,
    'LNS Leak': t`LNS Leak`,
    'LNS Noise Level': t`LNS Noise Level`,
    'LNS Spread': t`LNS Spread`,
    'LNS dB': t`LNS dB`,
    'LNS gain': t`LNS gain`,
    PRVTarget: t`PRVTarget`,
    'LNS min dB': t`LNS min dB`,
    'I2C Pressure sensor 1 temperature': t`I2C Pressure sensor 1 temperature`,
    'I2C Pressure sensor 2 temperature': t`I2C Pressure sensor 2 temperature`,
    'I2C Pressure sensor 3 temperature': t`I2C Pressure sensor 3 temperature`,
    'I2C Pressure sensor 4 temperature': t`I2C Pressure sensor 4 temperature`,
    'Intelligens External Temperature': t`Intelligens External Temperature`,
    'Flow Och': t`Flow Och`,
    Modbus1: t`Modbus1`,
    Modbus2: t`Modbus2`,
    Modbus3: t`Modbus3`,
    Modbus4: t`Modbus4`,
    Modbus5: t`Modbus5`,
    Modbus6: t`Modbus6`,
    Modbus7: t`Modbus7`,
    Modbus8: t`Modbus8`,
    Modbus9: t`Modbus9`,
    Modbus10: t`Modbus10`,
    Modbus11: t`Modbus11`,
    Modbus12: t`Modbus12`,
    Modbus13: t`Modbus13`,
    Modbus14: t`Modbus14`,
    Modbus15: t`Modbus15`,
    Modbus16: t`Modbus16`,
    'Flow 5': t`Flow 5`,
    'Flow 6': t`Flow 6`,
    '0 - 01V': t`0 - 01V`,
    '0 - 10V': t`0 - 10V`,
    '4 - 20mA 1': t`4 - 20mA 1`,
    '4 - 20mA 2': t`4 - 20mA 2`,
    'Raw Intelligens PT100': t`Raw Intelligens PT100`,
    'Test channel': t`Test channel`,
    Status1_0: t`Status1_0`,
    Status2_0: t`Status2_0`,
    Status3_0: t`Status3_0`,
    Status4_0: t`Status4_0`,
    Status1_1: t`Status1_1`,
    Status2_1: t`Status2_1`,
    Status3_1: t`Status3_1`,
    Status4_1: t`Status4_1`,
    'Not Fitted': t`Not Fitted`,
    '4-20mA3': t`4-20mA3`,
    '0-1V 2': t`0-1V 2`,
    '0-10V 2': t`0-10V 2`,
    '4-20mA4': t`4-20mA4`,
    '0 - 1V ch3': t`0 - 1V ch3`,
    '0 - 10V ch3': t`0 - 10V ch3`,
    'Level capacitance 1': t`Level capacitance 1`,
    'Level capacitance 2': t`Level capacitance 2`,
    'Level(mm)': t`Level(mm)`,
    'Level Status': t`Level Status`,
    'Thermistor 1': t`Thermistor 1`,
    'Thermistor 2': t`Thermistor 2`,
    'Pulse 1': 'Pulse 1',
    'Pulse 2': 'Pulse 2',
    'Pulse 3': 'Pulse 3',
    'Pulse 4': 'Pulse 4',
    'Pulse 5': 'Pulse 5',
    'Pulse 6': 'Pulse 6',
    'Pulse 7': 'Pulse 7',
    'Pulse 8': 'Pulse 8',
    'Pulse 9': 'Pulse 9',
    'Pulse 10': 'Pulse 10',
    'Pulse 11': 'Pulse 11',
    'Pulse 12': 'Pulse 12',
    'Pulse 13': 'Pulse 13',
    'Pulse 14': 'Pulse 14',
  };
};
