import { Channel } from '@models/api/models';
import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { translate } from '@shared/utils/dictionariesTranslations';
import { RootState } from '../rootReducer';
import { fetchSiteChannels } from './channels.thunks';

const channelsAdapter = createEntityAdapter({
  selectId: (channel: Channel) => channel.id,
});

export const channelsSlice = createSlice({
  name: 'channels',
  initialState: {
    channelsEntity: channelsAdapter.getInitialState(),
  },
  reducers: {
    removeChannelFromEntity(state, action: PayloadAction<number>) {
      channelsAdapter.removeOne(state.channelsEntity, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSiteChannels.fulfilled, (state, action) => {
      const payload = action.payload;
      payload.channels?.forEach((x: Channel) => (x.type = translate.channelType(x.type)));
      channelsAdapter.setAll(state.channelsEntity, payload.channels);
    });
  },
});

export type ChannelsState = ReturnType<typeof channelsSlice.reducer>;
export const { removeChannelFromEntity } = channelsSlice.actions;
export const channelsSelectors = channelsAdapter.getSelectors<RootState>((state) => state.channels.channelsEntity);
