import { AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit';

export const buildAsyncThunk = <T>(actionType: string, callback: AsyncThunkPayloadCreator<any, T>) => {
  return createAsyncThunk(actionType, async (data: T, store) => {
    try {
      const result = await callback(data, store);
      return result;
    } catch (err) {
      return store.rejectWithValue(err);
    }
  });
};
