import { ReactElement, cloneElement } from 'react';
import { Location } from 'history';
import { Button, INavLink } from '@hwm/ui-lib';
import { uniqueId } from 'lodash';
import { Grid, GridSize, MenuItem } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { actionIconStyles } from '@features/Accounts/subPages/createEditAccount/CreateEditAccountHeaderActionsConfig';
import { PermissionsTypes } from '@models/permission/PermissionsTypes';
import { Trans, t } from '@lingui/macro';
//
import DraftsIcon from '@material-ui/icons/Drafts';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import UploadIcon from '@material-ui/icons/CloudUpload';
import MapIcon from '@material-ui/icons/Map';
import UpdateIcon from '@material-ui/icons/Update';
import DeviceIcon from '@material-ui/icons/Devices';
import LockIcon from '@material-ui/icons/Lock';
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
import FirmwareIcon from '@material-ui/icons/SystemUpdateAlt';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import { ChannelIcon } from '@shared/components/Icons/ChannelIcon';
import { LogsIcon } from '@shared/components/Icons/Icons';
import { AppPaths } from '@core/routes/Routes';
import { Link, ListAlt, Videocam } from '@material-ui/icons';

export const getMenu = (
	hasPermission: (permission: string) => boolean,
	onDashboardClick: () => void,
	resetPreviouslyVisitedAccount: () => void,
	openUtilitiesDropdown: (e: React.MouseEvent<HTMLElement>) => void,
	openReportinngDropdown: (e: React.MouseEvent<HTMLElement>) => void
): INavLink[] => {
	return [
		{
			label: <Trans>Dashboard</Trans>,
			path: AppPaths.dataGate.path.base,
			onClick: onDashboardClick,
		},
		{
			label: <Trans>Devices</Trans>,
			path: AppPaths.devices.path.base,
			onClick: resetPreviouslyVisitedAccount,
		},
		{
			label: <Trans>Accounts</Trans>,
			path: AppPaths.accounts.path.base,
			onClick: resetPreviouslyVisitedAccount,
		},
		...(hasPermission(PermissionsTypes.CanSeeUsers)
			? [
					{
						label: <Trans>Users</Trans>,
						path: AppPaths.users.path.base,
					} as INavLink,
			  ]
			: []),
		...(hasPermission(PermissionsTypes.CanReadTemplateTranslations)
			? [
					{
						label: <Trans>Translations</Trans>,
						path: AppPaths.translations.path.base.path,
						onClick: resetPreviouslyVisitedAccount,
					} as INavLink,
			  ]
			: []),
		...(hasPermission(PermissionsTypes.CanAccessFunctions) ||
		hasPermission(PermissionsTypes.CanAccessAdvancedFunctions) ||
		hasPermission(PermissionsTypes.CanCreateEditDevices)
			? [
					{
						label: <Trans>Utilities</Trans>,
						path: '',
						absolutePath: true,
						onClick: (event) => {
							event.preventDefault();
							openUtilitiesDropdown(event);
						},
					} as INavLink,
			  ]
			: []),
		...(hasPermission(PermissionsTypes.CanSeeImports)
			? [
					{
						label: <Trans>GIS Management</Trans>,
						path: `/gis/any/${encodeURIComponent(window.location.href)}`,
						onClick: resetPreviouslyVisitedAccount,
					} as INavLink,
			  ]
			: []),
		...(hasPermission(PermissionsTypes.CanSeeEditFleetReports)
			? [
					{
						label: <Trans>Reporting</Trans>,
						path: '',
						onClick: (event) => {
							event.preventDefault();
							openReportinngDropdown(event);
						},
					} as INavLink,
			  ]
			: []),
		...(hasPermission(PermissionsTypes.CanManageFeatureFlags)
			? [
					{
						label: <Trans>Feature Flags</Trans>,
						path: AppPaths.featureFlags.path.base.path,
						onClick: resetPreviouslyVisitedAccount,
					} as INavLink,
			  ]
			: []),
	];
};

interface DropdownMenuItem {
  label: string;
  icon: ReactElement;
  route: string;
  disabled?: boolean;
  visible?: boolean;
}

export const getDropdownMenu = (
	classes: any,
	dropdownMenuItems: DropdownMenuItem[],
	resetPreviouslyVisitedAccount: () => void,
	location: Location<any>,
	displayAsColumn: boolean,
) => {
  const xs: GridSize = 12;
  let sm: GridSize;
  let md: GridSize;
  dropdownMenuItems = dropdownMenuItems.filter((x) => x.visible === undefined || x.visible === true);

  const noOfItems = dropdownMenuItems.length;

	if (displayAsColumn) {
		sm = 12;
		md = 12;
	} else if (noOfItems % 2 === 0) {
		sm = 6;
		md = 6;
	} else if (noOfItems % 3 === 0) {
		sm = 12;
		md = 4;
	} else {
		sm = 12;
		md = 12;
	}

  const items = dropdownMenuItems.map((dropdownMenuItem: DropdownMenuItem) => {
    const icon = cloneElement(dropdownMenuItem.icon, { style: actionIconStyles });

    const button = (
      <Button className="hover:text-white" style={{ fontSize: '13px' }}>
        {icon}
        {dropdownMenuItem.label}
      </Button>
    );

    return (
      <Grid item key={uniqueId()} xs={xs} sm={sm} md={md}>
        <MenuItem className={classes.navbarDropdownLink} disableGutters>
          {!!dropdownMenuItem.disabled ? (
            <>{button}</>
          ) : (
            <NavLink
              to={{
                pathname: dropdownMenuItem.route,
                state: { prevPath: location.pathname },
              }}
              onClick={resetPreviouslyVisitedAccount}
            >
              {button}
            </NavLink>
          )}
        </MenuItem>
      </Grid>
    );
  });

  return (
    <Grid container spacing={2}>
      {items}
    </Grid>
  );
};

export const getReportingMenuConfiguration = (userPermissions: string[]): DropdownMenuItem[] => {
	const requireCanSeeEditFleetReports = userPermissions.some((x) => x === PermissionsTypes.CanSeeEditFleetReports);

	const requireCanManageScheduledReports = userPermissions.some(
		(x) => x === PermissionsTypes.CanManageScheduledReports
	);

	return [
		{
			label: t`Fleet Reporting`,
			route: AppPaths.reporting.path.base,
			visible: requireCanSeeEditFleetReports,
			icon: <ListAlt />,
		},
		{
			label: t`Scheduled Reports`,
			route: AppPaths.scheduledReports.path.base.path,
			visible: requireCanManageScheduledReports,
			icon: <ListAlt />,
		},
	];
};

export const getUtilitiesMenuConfiguration = (userPermissions: string[]): DropdownMenuItem[] => {
  const requireCanAccessFunctionOrCanAccessAdvancedFunctions = userPermissions.some(
    (x) => x === PermissionsTypes.CanAccessFunctions || x === PermissionsTypes.CanAccessAdvancedFunctions
  );
  const requireCanAccessAdvancedFunctions = userPermissions.some(
    (x) => x === PermissionsTypes.CanAccessAdvancedFunctions
  );
  const requireCanCreateEditDevices = userPermissions.some((x) => x === PermissionsTypes.CanCreateEditDevices);
  const requireCanAdvanceConfigureDevice = userPermissions.some(
    (x) => x === PermissionsTypes.CanAdvanceConfigureDevice
  );
  const requireCanChangeDeviceOwnerAndAmendNotes = userPermissions.some(
	(x) => x === PermissionsTypes.CanChangeDeviceOwnerAndAmendNotes
  );
  const requireCanManageMonitoredSites = userPermissions.some((x) => x === PermissionsTypes.CanManageMonitoredSites);
  const requireCanLinkHierarchy = userPermissions.some((x) => x === PermissionsTypes.CanLinkHierarchy);

  return [
    {
      label: t`Decode Message`,
      route: '/utilities/decodemessage',
      icon: <DraftsIcon />,
      visible: requireCanAccessFunctionOrCanAccessAdvancedFunctions,
    },
    {
      label: t`Download Config`,
      route: '/utilities/downloadconfig',
      icon: <FileCopyIcon />,
      visible: requireCanAccessAdvancedFunctions,
    },
    {
      label: t`Device Reconfigure`,
      route: '/utilities/devicereconfigure',
      icon: <PhonelinkSetupIcon />,
      visible: requireCanAccessFunctionOrCanAccessAdvancedFunctions,
    },
    {
      label: t`Manage Secure Communication with Device`,
      route: '/utilities/securecommunication',
      icon: <LockIcon />,
      visible: requireCanAdvanceConfigureDevice,
    },
    {
      label: t`Log Records`,
      route: '/utilities/logrecords',
      icon: <LogsIcon />,
      visible: requireCanAccessAdvancedFunctions,
    },
    {
      label: t`Map Certain Loggers`,
      route: '/utilities/mapcertainloggers',
      icon: <MapIcon />,
      visible: requireCanAccessFunctionOrCanAccessAdvancedFunctions,
    },
    {
      label: t`4-20mA`,
      route: '/utilities/420ma',
      icon: <ChannelIcon />,
      visible: requireCanAccessFunctionOrCanAccessAdvancedFunctions,
    },
    {
      label: t`Device Upload`,
      route: '/utilities/deviceupload',
      icon: <UploadIcon />,
      visible: requireCanAccessAdvancedFunctions || requireCanCreateEditDevices,
    },
    {
      label: t`Firmware Update List`,
      route: '/utilities/firmwareupdatelist',
      icon: <FirmwareIcon />,
      visible: requireCanAccessAdvancedFunctions,
    },
    {
      label: t`Update Site/Device Info`,
      route: '/utilities/updatesitedeviceinfo',
      icon: <UpdateIcon />,
      visible: requireCanAccessAdvancedFunctions,
    },
    {
      label: t`Device Data`,
      route: '/utilities/devicedata',
      icon: <DeviceIcon />,
      visible: requireCanAccessAdvancedFunctions,
    },
    {
      label: t`Alarms Upload`,
      route: '/utilities/alarmupload',
      icon: <UploadIcon />,
      visible: requireCanAccessAdvancedFunctions,
    },
    {
      label: t`Recycled Sites`,
      route: '/utilities/recycledsites',
      icon: <DeleteSweepIcon />,
      visible: requireCanAccessAdvancedFunctions,
    },
    {
      label: t`Device Bulk Actions`,
      route: '/utilities/devicebulkactions',
      icon: <ListAlt />,
      visible: requireCanAccessAdvancedFunctions || requireCanChangeDeviceOwnerAndAmendNotes,
    },
    {
      label: t`Monitored Sites`,
      route: '/utilities/monitoredsites',
      icon: <Videocam />,
      visible: requireCanManageMonitoredSites && requireCanAccessAdvancedFunctions,
    },
    {
      label: t`Hierarchy Linking`,
      route: '/utilities/hierarchylinking',
      icon: <Link />,
      visible: requireCanLinkHierarchy,
    },
  ];
};
