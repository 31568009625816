import { INavLink, usePermission, useSetLayoutProps } from '@hwm/ui-lib';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Menu, makeStyles } from '@material-ui/core';
import { getDropdownMenu, getMenu, getUtilitiesMenuConfiguration, getReportingMenuConfiguration } from './MainMenu.utilities';
import { useAppDispatch, useAppSelector } from '@core/store/store';
import { useLocation } from 'react-router-dom';
import { userProfileSelector } from '@core/store/library-stores/selectors';
import { setIsSiteListTableView } from '@core/store/sites/sites.reducer';
import { styleConstants } from '@styles/constants';
import { accountsReducer } from '@core/store/library-stores/accounts';

export const DGA_APP_NAME = 'DataGate Admin';
const GIS_MANAGEMENT = 'GIS Management';

const appBarHeight = 66;

const useStyles = makeStyles((theme) => ({
  navbarDropdownMenu: {
    margin: '0 6px',
  },
  navbarDropdownMenuWrapper: {
    top: `${appBarHeight}px !important`,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '712px',
    },
  },
  navbarDropdownLink: {
    display: 'flex',
    width: '100%',
    flexGrow: 1,
    '& a': {
      width: '100%',
      textDecoration: 'none',
    },
    '&.MuiButtonBase-root': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '&.Mui-focusVisible, &:hover, &.Mui-selected': {
      backgroundColor: 'white !important',
    },
    '& button': {
      display: 'flex',
      justifyContent: 'flex-start',
      width: '100%',
      height: 52,
      background: '#d3e8f3',
      textAlign: 'left',
      textWrap: 'wrap',
      lineHeight: '16px',
      color: styleConstants.colors.fontDarkBlue,
    },
  },
}));

export interface MainMenuProps {
  links: INavLink[];
  setLinks: (links: INavLink[]) => void;
}

export const MainMenu: React.FC<MainMenuProps> = ({ links, setLinks }) => {
  const classes = useStyles();

  const location = useLocation();
  const dispatch = useAppDispatch();
  const { hasPermission } = usePermission();
  const { permissions } = useAppSelector(userProfileSelector);

	const [dropdownMenuAnchor, setDropdownMenuAnchor] = useState<HTMLElement>(null);
	const [dropdownMenuContext, setDropdownMenuContext] = useState<'reporting' | 'utilities'>(null);

	const [setLayoutProps, { appName }] = useSetLayoutProps();

  const onGisPage = useMemo(() => location.pathname.includes('/gis/'), [location.pathname]);

  useEffect(() => {
    if (onGisPage) {
      setLayoutProps({ appName: GIS_MANAGEMENT });
      return;
    }

    if (appName === DGA_APP_NAME) return;

    setLayoutProps({ appName: DGA_APP_NAME });
  }, [onGisPage, appName, location.pathname, setLayoutProps]);

  const resetPreviouslyVisitedAccount = useCallback(
    () => dispatch(accountsReducer.slice.actions.setPreviouslyVisitedAccount(null)),
    [dispatch]
  );

  const onDashboardClick = useCallback(() => {
    resetPreviouslyVisitedAccount();
    dispatch(setIsSiteListTableView(false));
  }, [dispatch, resetPreviouslyVisitedAccount]);

	const openUtilitiesDropdown = (event: React.MouseEvent<HTMLElement>) => {setDropdownMenuAnchor(event.currentTarget); setDropdownMenuContext('utilities')};

	const utilitiesMenu = getDropdownMenu(
		classes,
		getUtilitiesMenuConfiguration(permissions ?? []),
		resetPreviouslyVisitedAccount,
		location,
		false,
	);

	const openReportingDropdown = (event: React.MouseEvent<HTMLElement>) => {setDropdownMenuAnchor(event.currentTarget); setDropdownMenuContext('reporting')};

	const reportingMenu = getDropdownMenu(
		classes,
		getReportingMenuConfiguration(permissions ?? []),
		resetPreviouslyVisitedAccount,
		location,
		true,
	);

  useEffect(() => {
    if (links.length > 0) {
      links.splice(0, links.length);
    }

    if (onGisPage) {
      setLinks([]);
      return;
    }

		const menu = getMenu(hasPermission, onDashboardClick, resetPreviouslyVisitedAccount, openUtilitiesDropdown, openReportingDropdown);

    links.push(...menu);

    setLinks(menu);
  }, [onGisPage, links, hasPermission, setLinks, onDashboardClick, resetPreviouslyVisitedAccount]);

	return (
		<>
			<Menu
				style={{ zIndex: 10000 }}
				anchorEl={dropdownMenuAnchor}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				getContentAnchorEl={null}
				keepMounted
				open={Boolean(dropdownMenuAnchor)}
				onClick={() => {setDropdownMenuAnchor(null); setDropdownMenuContext(null)}}
				classes={{
					list: classes.navbarDropdownMenu,
					paper: classes.navbarDropdownMenuWrapper,
				}}
			>
				{dropdownMenuContext === 'reporting' ? reportingMenu : dropdownMenuContext === 'utilities' ? utilitiesMenu : null}
			</Menu>
		</>
	);
};
