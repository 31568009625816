import { lighten, makeStyles } from '@material-ui/core/styles';
import { styleConstants } from './constants';
import { createAppTheme } from '@hwm/ui-lib';

export const theme = createAppTheme(styleConstants);

export const globalButtonStyles = makeStyles({
  btn: {
    alignItems: 'center',
    borderRadius: '5px',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '13px',
    justifyContent: 'center',
    fontWeight: 700,
    height: '36px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textTransform: 'none',
  },
  headerBtn: {
    marginLeft: '10px !important',
    marginRight: '0px !important',
  },
  active: {
    border: `3px solid ${styleConstants.colors.blue}`,
    color: styleConstants.colors.blue,
    padding: '0 17px',
  },
  inactive: {
    backgroundColor: styleConstants.colors.grey2,
    color: '#596379',
    padding: '0 20px',
  },
  filled: {
    backgroundColor: styleConstants.colors.blue,
    color: 'white',
    '&:hover': {
      backgroundColor: lighten(styleConstants.colors.blue, 0.2),
    },
  },
  filledGrey: {
    backgroundColor: '#929394',
    color: 'white',
  },
  disabled: {
    opacity: 0.4,
  },
});

export default theme;
