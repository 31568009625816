import { t } from '@lingui/macro';
import CustomObject from '@models/interfaces/CustomObject';

export const GenericException = 'Generic.GenericException';

export const errorCodesDictionary = (): CustomObject => {
  return {
    'Account.AccountNotFound': t`Account not found`,
    'Account.NotAllowedToUseAccount': t`You are not allowed to use selected account`,
    'Account.AccountNameNotUnique': t`Account name is not unique`,
    'Account.AccountTypeNotFound': t`Account type not found`,
    'Account.AccountMustHaveParentAccount': t`Account must have a parent account`,
    'Account.AccountParentIncorrect': t`Account parent cannot be assigned as that account is a child of the account`,
    'Account.AccountNotInHierarchy': t`Account is not in hierarchy`,
    'Account.AccountCantAssignSelfAsOwner': t`Account cannot be owned by itself`,
    'Account.TopLevelAccountsAvailableOnlyForSuperUser': t`You do not have access to top level accounts`,
    'Account.AccountParentIsZero': t`Parent account selected is zero`,
    'Account.AccountLinkedHierarchyExists': t`Account linked hierarchy already exists`,
    'Channel.MaxRangeGreaterThanMinRange': t`Channel max range is grater than min range`,
    'Device.IsNotSmsNumber': t`Value is not sms number`,
    'Device.DeviceNotFound': t`Device not found`,
    'Device.DeviceFirmwareNotFound': t`Device firmware not found`,
    'Device.DeviceFirmwareUpdateAllowedOnlyForSuperUser': t`You do not have access to update device firmware`,
    'Device.NewerSoftwareAlreadyInstalled': t`Newer software already installed`,
    'Device.DeviceLogNotFound': t`Device recorded log not found`,
    'Device.DeviceLogFileNotFound': t`File related to device log not found`,
    'Device.SmsNumberNotUnique': t`Sms number is not unique`,
    'Device.OwnerAccountIsNotFromHierarchy': t`Account owner is not from hierarchy`,
    'Device.NoSonicSensModuleInstalled': t`SonicSens module is not installed`,
    'Device.NoSonicSens2ModuleInstalled': t`SonicSens2 module is not installed`,
    'Device.NoSonicSens3ModuleInstalled': t`SonicSens3 module is not installed`,
    'DeviceCommand.CommandIncorrectStatus': t`Command has incorrect status`,
    'DeviceCommand.CommandNotFound': t`Command not found`,
    'Device.ConfigParameterReadonly': t`Config parameter is readonly and cannot be send to device`,
    'Device.ConfigParameterIncorrectDataType': t`Config parameter has invalid data type and cannot be send `,
    'Device.ConfigParameterTextLengthExceeded': t`Config parameter exceeded length limit`,
    'Device.ConfigParameterValueExceeded': t`Config parameter exceeded max value`,
    'Device.ConfigParameterInvalidPhoneNumber': t`Config parameter value has incorrect phone number format`,
    'File.InvalidExtension': t`Invalid extension`,
    'GisFiles.AlreadyUploaded': t`An upload with the same name already exists for one or more of the accounts`,
    'GisFiles.FormatUndefined': t`GIS File format was not recognized`,
    'GisFiles.ImportNotPermittedForOneOrMoreAccounts': t`Only DMA accounts are permitted for PermaNET import`,
    'GisFiles.ImportDoesNotExist': t`This import has been deleted`,
    'GisFiles.DeleteNotPermittedForOneOrMoreAccounts': t`You are not permitted to delete this import`,
    'GisFiles.NameNotSpecified': t`A name must be specified for the import`,
    'GisFiles.NameMaximumLengthExceeded': t`The name can be a maximum of 100 characters`,
    'GisFiles.FileSizeLimitExceeded': t`File size is greater than allowed limit`,
    'GisFiles.ImportLimitedToOneAccount': t`Only a single account can be selected`,
    'Imports.NotAllowedToContinueImport': t`You are not allowed to continue this import`,
    'Imports.DefaultDiameterRequired': t`Diameters are missing, a default diameter must be specified to complete this import`,
    'Imports.DefaultMaterialRequired': t`Materials are missing, a default material must be specified to complete this import`,
    'Imports.SourceAndDestinationAccountsMustBeDifferent': t`The source and destination accounts must be different`,
    'Imports.DestinationAccountDoesNotSupportAdvancedImports': t`The destination account does not support PermaNET imports`,
    'Imports.FileExistsInDestinationAccount': t`The file exists in the destination account`,
    'Imports.NameExistsInDestinationAccount': t`An import with this name exists in the destination account`,
    'Message.MessageNotFound': t`Message not found`,
    'Query.InvalidSortColumn': t`Invalid sort column`,
    'Regex.IsNotNumeric': t`Value is not numeric`,
    'Site.InvalidCoordinates': t`Invalid coordinates`,
    'Site.PhotoNotFound': t`Photo not found`,
    'Site.ChannelNotFound': t`Channel not found`,
    'Site.SiteChannelNumberAlreadyExists': t`Channel number already exist`,
    'Site.SiteChannelNumberTooLow': t`Channel number too low`,
    'Site.SiteChannelNumberTooLarge': t`Channel number too large`,
    'Site.AccountNotFound': t`Account not found`,
    'Sites.AccountAssociationAlreadyExists': t`Account association already exists`,
    'Site.UserAlarmForwardingAlreadyEnabled': t`User alarm forwarding already enabled`,
    'Site.OwnerCannotBeUnassigned': t`Owner cannot be unassigned`,
    'Site.SiteNotFound': t`Site not found`,
    'Site.SiteAccountAssociationNotFound': t`Account association not found`,
    'Site.FavouriteSitesListNotFound': t`List not found`,
    'Site.FavouriteSitesListNameNotUnique': t`List name already exists`,
    'Site.SiteNameTaken': t`Site name is already taken`,
    'Site.UserCantDeleteFavouriteListBecauseItsNotCreator': t`User cannot delete favourite list because is not its creator`,
    'Site.OwnerAccountIsNotFromHierarchy': t`Owner account is not from hierarchy`,
    'Site.MinimumPhotosNumberNotReached': t`Minimum Photos Number not reached.`,
    'Site.DeviceNotLinked': t`Device is not linked`,
    'Site.StartDateEmpty': t`Start date is mandatory when end date is set`,
    'Site.EndDateEmpty': t`End date is mandatory when start date is set`,
    'Site.EndDateBeforeStartDate': t`End date cannot be before start date`,
    'Device.ConfigurationNotFound': t`Configuration not found`,
    'Device.NextBatteryReplacementEarlierThanLastBatteryReplacement': t`Next Battery Replacement is earlier than Last Battery Replacement`,
    'Device.LastBatteryReplacementLaterThanNextBatteryReplacement': t`Last Battery Replacement is later than Next Battery Replacement`,
    'Time.IsNotHour': t`Invalid hour`,
    'Time.IsNotMinute': t`Invalid minutes`,
    'Device.DataSendTimeAddressOutOfRange': t`Data send time address out of range`,
    'Device.ChannelSettingsTypeNotAllowed': t`Channel type not allowed`,
    'Device.ChannelSettingsModeNotAllowed': t`Channel mode not allowed`,
    'Generic.GenericException': t`Unhandled exception has occured`,
    'Alarm.PersistenceXLowerOrEqualThanY': t`Persistence X must be lower or equal than Y`,
    'Alarm.BoundaryUpperLevelGreaterThanLowerLevel': t`Boundary upper level must be greater than lower level`,
    'Alarm.UnilogPersistenceMustBeBetweenOneToFifteen': t`Persistance must be in range of 1 to 15`,
    'Alarm.LegacyPersistenceMustBeBetweenOneToEight': t`Persistance must be in range of 1 to 8`,
    'Alarm.UnilogConditionsMustBeBetweenOneToEight': t`Unilog supports a maximum of 8 alarm conditions`,
    'Alarm.UnilogConditionalChannelNotSet': t`Conditional channel was not selected for the alarm condition`,
    'Alarm.UnilogEventDurationMinFirmwareNotMet': t`Device does not support Event Duration alarm condition`,
    'Alarm.UnilogEventDurationConditionMustBeBetweenOneToEight': t`Event Duration condition must be in range of 1 to 8`,
    'Alarm.AlarmOptionIncorrectMinutes': t`Alarm option has incorrect minutes value`,
    'Alarm.AlarmOptionIncorrectHour': t`Alarm option has incorrect hour value`,
    'Alarm.AlarmTransientAtLeastOneChannelSelected': t`At least one channel should be selected`,
    'Alarm.DeviceIsNotUnilog': t`Device is not unilog, unilog alarm settings are not supported`,
    'Alarm.DeviceIsNotMultilog': t`Device is not multilog, legacy alarm settings are not supported`,
    'Alarm.DeviceDoesNotSupportBinaryAlarms': t`The logger does not support binary alarm types`,
    'Alarm.DeviceDoesNotSupportServerAlarms': t`The logger does not support server alarm types`,
    'Alarm.MaximumConditionsReached': t`The maximum number of alarm conditions was exceeded`,
    'Alarm.ConditionChannelNotSet': t`The channel was not selected for the alarm condition`,
    'Alarm.ConditionLevelNotSet': t`The level was not set for the specified alarm condition type`,
    'Alarm.ConditionTypeNotRecognised': t`The alarm condition type was not valid`,
    'Device.ConfigurationChunksOutOfRange': t`Configuration Chunks Count exceeds configuration range`,
    'Device.ConfigurationDataTypeSizeIncorrect': t`Chunk Data Type Has Incorrect Size Defined`,
    'Utilities.NoSitesSelected': t`No Sites Selected.`,
    'Utilities.EmptySmsNumberList': t`SmsNumber List cannot be empty`,
    'Utilities.FilterByAccountOrSelectedSiteListAllowed': t`You can filter only by Account or Selected Site List.`,
    'Utilities.InvalidValues420Sensor': t`High Value should be greater or equal than Low Value.`,
    'Utilities.NumChannelsMustBeBetweenOneAndEight': t`Number of Channels should have value between 1 and 8.`,
    'Utilities.SampleRateSecCustomValueMustBePositive': t`Sample Rate Sec custom value should be positive.`,
    'Utilities.SystemMemoryValueMustBePositive': t`System Memory value should be positive`,
    'Utilities.ValueMustBePositive': t`Value should be positive.`,
    'Utilities.ValueMustBeEqualToOrGreaterThanZero': t`Value should be equal to or greater than zero.`,
    'Device.DeviceNotInHierarchy': t`Device is outside hierarchy.`,
    'Utilities.NoSiteFilterCriterionSpecified': t`Please select an account & sites list to configure devices`,
    'Site.SelectedSiteListNotOwned': t`You are not an owner of the Selected Site List`,
    'Utilities.MultipleDeviceTypes': t`Selected sites contain different logger types, cannot update to specific version`,
    'Utilities.LoggerTypeMissingForFirmwareVersion': t`No logger type set for selected sites`,
    'Utilities.ErrorDuringDeviceReconfiguration': t`Error occured during devices reconfiguration. Some devices could not have results of reconfiguration`,
    'Utilities.SomeDevicesAreUnlinked': t`Some sites has not linked devices`,
    'Utilities.ReconfigurationOldDataRequestDateInTheFuture': t`Selected request date is in the future.`,
    'Utilities.ReconfigurationDeviceRestartedSinceStartDate': t`Logger has restarted since the start date entered.`,
    'Utilities.ReconfigurationDurationExeeded': t`Duration exceeds the current date and time. The duration has been reduced to the current data and time.`,
    'Utilities.ReconfigurationTimeSpanGreaterThanTimeLeftInDay': t`Call in time span is greater than time left in the day.`,
    'Utilities.ReconfigurationDeviceNotSupportCallIn': t`Device not support call in.`,
    'Utilities.ReconfigurationDeviceNotSupportCallInWhenNewData': t`Device not support call in when new data`,
    'Utilities.ReconfigurationDeviceNotSupportAlarmWhenLeak': t`Device not support set alarm when leak`,
    'Utilities.ReconfigurationDeviceNotSupportNoLeakDays': t`Device not support no leak days before sound recording`,
    'Utilities.ReconfigurationDeviceNotSupportDialFaster': t`Device not support faster dial`,
    'Utilities.ReconfigurationDeviceNotSupportDisableTemperatureSensor': t`Device not support disabling temperature sensor`,
    'Utilities.ReconfigurationMultilogRequireMemoryTypeForMemoryWrite': t`Need to Specify Memory Area For Multilog Type`,
    'Utilities.ReconfigurationDeviceNotSupportMemoryWrite': t`Device not support memory write`,
    'Utilities.ReconfigurationDeviceNotSupportMemoryRead': t`Device not support memory read`,
    'Utilities.ReconfigurationDeviceNotSupportSoundRecordingWhenGoingFromNoLeakToLeak': t`Device not support sound recording when going from no leak to leak`,
    'Utilities.ReconfigurationDeviceNotSupportRequestDeviceConfiguration': t`Device not support request device configuration`,
    'Device.DeviceFirmwareUpdateError': t`An error occured while updating the firmware`,
    'Utilities.NoSuitableSitesFound': t`No suitable sites found`,
    'Utilities.ReconfigurationDeviceNotSupportSmsBackup': t`Device not support sms backup`,
    'Utilities.SomeSitesNotInHierarchy': t`Some sites have been excluded because they are not in hierarchy`,
    'Utilities.InsufficientPermissionsFirmwareUpdate': t`You have not enough permissions to execute firmware update`,
    'Device.DeviceIsNotHubOrTransmitter': t`Device is neither Hub nor Transmitter`,
    'Devies.RequestConfigurationNotUnilog': t`Request device configuration is dedicated only for unilog devices`,
    'Utilities.UploadDeviceFileContainDeviceThatExists': t`Import Cancelled due to some device (s) already in the system`,
    'Utilities.UploadDevicesUpdateFileContainDeviceThatNotExists': t`Import cancelled as one or more devices do not exist in the system`,
    'Utilities.BulkCreateDevicesContainNotAllowedAccounts': t`Bulk create devices csv file contain accounts that you are not allowed to use`,
    'Utilities.BulkCreateDevicesFileInvalidFormat': t`File that you are using for bulk create devices has invalid format. Please correct CSV format`,
    'Utilities.BulkUpdateDevicesFileInvalidFormat': t`File that you are using for bulk update devices has invalid format. Please correct CSV format`,
    'Utilities.UploadDeviceFileContainDuplicatedDevices': t`Bulk create devices csv contain duplicated devices`,
    'Utilities.YouAreNotAllowedToSendCommandToDevice': t`You are not allowed to send command to that device`,
    'Utilities.YouAreNotAllowedToSendCommandToSomeDevicesFromYourList': t`You are not allowed to send command to some devices from your selected list`,
    'Utilities.DeviceCommandUploadSmsNumberIsIncorrect': t`File name contain incorrect sms number, command cannot be send`,
    'Utilities.DeviceCommandUploadSelectListEmpty': t`Your selected list doesnt has any devices, command cannot be send`,
    'Utilities.DeviceCommandUploadContainInvalidCheckSum': t`File contain command that has incorrect check sum`,
    'Permissions.Insufficient': t`You have insufficient permission`,
    'Device.DeviceCommandDependencyNotMet': t`The required device command could not be sent`,
    'Channel.DoesNotSupportMeter': t`The channel does not support a meter`,
    'User.PasswordConfirmationRequired': t`User sensitive  information require password confirmation`,
    'User.PasswordConfirmationFailed': t`Password confirmation not match password`,
    'User.Password.SecurityCount': t`Your password cannot be the same as one of your 5 last passwords`,
    'Users.Username.MustBeUnique': t`Username must be unique`,
    'GasZone.InvalidZone': t`Gas Zone must be selected`,
    'Utilities.BulkUploadAlarmsFileInvalidFormat': t`File that you are using for bulk upload alarms has invalid format. Please correct CSV format`,
    'Utilities.EnableSecureCommunicationDeviceImeiNotExists': t`Device IMEI doesn't exist`,
    'Utilities.DisableSecureCommunicationDeviceImeiNotExists': t`Device IMEI doesn't exist`,
    'Utilities.ErrorDuringEnableSecureCommunicationDevice': t`An error occurred while enabling secure communication with device`,
    'Utilities.ErrorDuringDisableSecureCommunicationDevice': t`An error occurred while disabling secure communication with device`,
    'Utilities.EnableSecureCommunicationDeviceSoftwareNotCompatible': t`Device software is not compatible. Secure communication cannot be enabled`,
    'Utilities.DisableSecureCommunicationDeviceSoftwareNotCompatible': t`Device software is not compatible. Secure communication cannot be disabled`,
    'FleetReport.InvalidOrderByColumn': t`Cannot sort on specified column`,
    'FleetReport.RequestHasNoSites': t`Request has no Sites, please select some sites to generate a fleet report`,
    'Device.NoRadarSensorModulePermission': t`ou have not enough permissions to execute Radar Sensors module`,
    'Device.NoRadarSensModuleInstalled': t`Radar Sens module is not installed`,
  };
};
