import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchApplicationsList, fetchRolesList } from './roles.thunk';
import { Application, ApplicationRole } from '@models/api/models';

export const rolesSlice = createSlice({
  name: 'roles',
  initialState: {
    applicationsList: [] as Application[],
    rolesList: [] as ApplicationRole[],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchApplicationsList.fulfilled, (state, action: PayloadAction<Application[]>) => {
        state.applicationsList = action.payload;
      })
      .addCase(fetchRolesList.fulfilled, (state, action: PayloadAction<ApplicationRole[]>) => {
        state.rolesList = action.payload;
      });
  },
});

export type RolesState = ReturnType<typeof rolesSlice.reducer>;

export default rolesSlice;
