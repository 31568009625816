import { applicationPaths } from '@core/http/applicationPaths';
import httpClient, { simpleClient } from '@core/http/httpClient';
import { UsersListSearchInFilterTypes } from '@features/Users/subPages/usersList/UsersList';
import {
  AccessLevel,
  AccessProfile,
  AssignedRole,
  CreateUserRequest,
  HierarchyAccount,
  SetPasswordRequest,
  UpdateUserRequest,
  UpdateUserRolesRequest,
  UserListItemPagedResponse,
} from '@models/api/models';
import { FileDownloadResult } from '@models/interfaces/FileDownloadResult';
import getFileFromResponse from '@shared/utils/getFileFromResponse';
import { AxiosResponse } from 'axios';
import { buildAsyncThunk } from '../utils';
import { UserAlarmForwardingSettings } from '@models/api/userAlarmForwardingSettings';
import { TestSmsMessageResult } from '@models/api/testSmsMessageResult';
import { SelectedUserAccountHistoryListItem, SelectedUserHistoryListItem } from '@models/api/selectedUserHistory';
import { isNil } from 'lodash';
import { omitAbortSignalConfig } from '@hwm/ui-lib';

export interface FetchUsersThunk {
  ascending?: boolean | null;
  orderBy?: string | null;
  filterBy?: string;
  pageSize?: number;
  currentPage?: number;
  showDeletedUsers: boolean;
  showUsersFromHierarchy: boolean;
  searchOptions?: string;
  filterByAccount?: number;
}

export const fetchUsersList = buildAsyncThunk('users/fetchUsersList', async (params: FetchUsersThunk) => {
  const response: AxiosResponse<UserListItemPagedResponse> = await httpClient.get(applicationPaths.users.list(), {
    params,
  });
  return response.data;
});

export const fetchUserDetails = buildAsyncThunk('users/fetchUserDetails', async (id: number) => {
  const response: AxiosResponse = await httpClient.get(applicationPaths.users.detailedUser(id));
  return response.data;
});

export const deleteUserById = buildAsyncThunk('users/deleteUser', async (id: number) => {
  const response: AxiosResponse = await httpClient.delete(applicationPaths.users.detailedUser(id));
  return response.data;
});

export const createUser = buildAsyncThunk('users/createUser', async (userData: CreateUserRequest) => {
  const response: AxiosResponse = await httpClient.post(applicationPaths.users.list(), userData);
  return response.data;
});

export interface EditUserThunkData {
  id: number;
  data: UpdateUserRequest;
}

export const editUser = buildAsyncThunk('users/editUser', async (editUserData: EditUserThunkData) => {
  const response: AxiosResponse = await httpClient.put(
    applicationPaths.users.detailedUser(editUserData.id),
    editUserData.data
  );
  return response.data;
});

export const sendUserTestMessage = buildAsyncThunk('users/sendUserTestMessage', async (userId: number) => {
  const response: AxiosResponse<TestSmsMessageResult> = await httpClient.post(
    applicationPaths.users.sendUserTestMessage(userId)
  );
  return response.data;
});

export const fetchAccessProfile = buildAsyncThunk('users/fetchAccessProfile', async (id: number) => {
  const response: AxiosResponse<AccessProfile> = await httpClient.get(applicationPaths.users.getAccessProfile(id));
  return response.data;
});

export interface updateAccessProfileThunk {
  userId: number;
  roles: Array<AssignedRole>;
}

export const updateAccessProfile = buildAsyncThunk(
  'users/updateAccessProfile',
  async (data: updateAccessProfileThunk) => {
    const params: UpdateUserRolesRequest = {
      roles: data.roles,
    };
    const response: AxiosResponse = await httpClient.put(applicationPaths.users.updateUserRoles(data.userId), params);
    return response.data;
  }
);

export interface AddUserRoleThunk {
  userId: number;
  applicationId: number;
  roleId: number;
}

export interface GetUserAvailableParentsThunk {
  includeAccountId?: number;
  searchPhrase?: string;
  filterRestricted?: boolean;
}

export const fetchAvailableParents = buildAsyncThunk(
  'users/fetchAvailableParents',
  async (data: GetUserAvailableParentsThunk) => {
    const restrictedNotNull = !isNil(data.filterRestricted);

    const params = {
      ...(data.searchPhrase ? { searchPhrase: data.searchPhrase } : {}),
      ...(restrictedNotNull ? { filterRestricted: data.filterRestricted } : {}),
    };

    const response: AxiosResponse<HierarchyAccount[]> = await httpClient.get(
      applicationPaths.accounts.hierarchy(data.includeAccountId),
      {
        params,
        ...(restrictedNotNull ? { signal: omitAbortSignalConfig } : {}),
      }
    );
    return response.data;
  }
);

export interface ChangeUserPasswordThunk {
  userId: number;
  data: SetPasswordRequest;
}

export const setUserPassword = buildAsyncThunk('users/setUserPassword', async (userData: ChangeUserPasswordThunk) => {
  const response: AxiosResponse = await simpleClient.post(
    applicationPaths.users.setPassword(userData.userId),
    userData.data
  );
  return response.data;
});

export const resetUserPassword = buildAsyncThunk(
  'users/setResetUserPassword',
  async (userData: ChangeUserPasswordThunk) => {
    const response: AxiosResponse = await simpleClient.post(
      applicationPaths.users.resetPassword(userData.userId),
      userData.data
    );
    return response.data;
  }
);

export interface ExportUsersThunk {
  filterBy?: string;
  showDeletedUsers?: boolean;
  showUsersFromHierarchy?: boolean;
  searchOptions?: UsersListSearchInFilterTypes;
  filterByAccount?: number;
}

export const exportUsers = buildAsyncThunk('users/exportUsers', async (params: ExportUsersThunk) => {
  const response: AxiosResponse = await httpClient.get(applicationPaths.users.exportUsers(), { params });
  return getFileFromResponse(response);
});

export const getUserAccessLevels = buildAsyncThunk('users/getUserAccessLevels', async (noArgs: void) => {
  const response: AxiosResponse<AccessLevel[]> = await httpClient.get(applicationPaths.users.getUserAccessLevels());
  return response.data;
});

export const restoreUserById = buildAsyncThunk('users/restoreUser', async (id: number) => {
  const response: AxiosResponse = await httpClient.patch(applicationPaths.users.detailedUser(id));
  return response.data;
});

export interface ExportSelectedUserHistoryThunk {
  userId: number;
  from?: string;
  to?: string;
}

export const exportSelectedUserHistory = buildAsyncThunk(
  'users/exportSelectedUserHistory',
  async ({ userId, ...params }: ExportSelectedUserHistoryThunk) => {
    const response: AxiosResponse<FileDownloadResult> = await httpClient.get(
      applicationPaths.users.exportSelectedUserHistory(userId),
      { params }
    );
    return getFileFromResponse(response);
  }
);

export const exportSelectedUserAccountHistory = buildAsyncThunk(
  'users/exportSelectedUserAccountHistory',
  async ({ userId, ...params }: ExportSelectedUserHistoryThunk) => {
    const response: AxiosResponse<FileDownloadResult> = await httpClient.get(
      applicationPaths.users.exportSelectedUserAccountHistory(userId),
      { params }
    );
    return getFileFromResponse(response);
  }
);

export interface GetSelectedUserHistoryThunk {
  userId: number;
  orderBy?: string;
  ascending?: boolean;
  from?: string;
  to?: string;
}

export const getSelectedUserHistory = buildAsyncThunk(
  'users/getSelectedUserHistory',
  async ({ userId, ...params }: GetSelectedUserHistoryThunk) => {
    const response: AxiosResponse<SelectedUserHistoryListItem[]> = await httpClient.get(
      applicationPaths.users.getSelectedUserHistory(userId),
      { params }
    );
    return response.data;
  }
);

export const getSelectedUserAccountHistory = buildAsyncThunk(
  'users/getSelectedUserAccountHistory',
  async ({ userId, ...params }: GetSelectedUserHistoryThunk) => {
    const response: AxiosResponse<SelectedUserAccountHistoryListItem[]> = await httpClient.get(
      applicationPaths.users.getSelectedUserAccountHistory(userId),
      { params }
    );
    return response.data;
  }
);

export const getAlarmForwarding = buildAsyncThunk('users/getAlarmForwarding', async (userId: number) => {
  const response: AxiosResponse<UserAlarmForwardingSettings> = await httpClient.get(
    applicationPaths.users.alarmForwarding(userId)
  );
  return response.data;
});

export interface GenerateResetUserPasswordThunk {
  userId: number;
  passwordConfirmation: string;
}

export const generateResetUserPassword = buildAsyncThunk(
  'users/generateResetUserPassword',
  async ({ userId, ...data }: GenerateResetUserPasswordThunk) => {
    const response: AxiosResponse = await httpClient.post(
      applicationPaths.users.generateResetUserPassword(userId),
      data
    );
    return response.data;
  }
);

export interface VerifyMobileThunk {
  userId: number;
  mobile: string;
  passCode?: string;
}

export const verifyMobile = buildAsyncThunk(
  'users/VerifyMobile',
  async ({ userId, mobile, passCode }: VerifyMobileThunk) => {
    const response: AxiosResponse = await httpClient.post(applicationPaths.users.verifyMobile(userId), {
      mobile,
      passCode: passCode ?? undefined,
    });

    return response.data;
  }
);
