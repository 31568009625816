import { AppInit, INavLink } from '@hwm/ui-lib';
import { styleConstants } from '@styles/constants';
import { lazy, useState } from 'react';
import 'uplot/dist/uPlot.min.css';
import store from './core/store/store';
import { environment } from './environment';
import './index.css';
import theme from './styles/theme';
import { AppPaths } from '@core/routes/Routes';
import { userProfileReducer } from '@core/store/library-stores/user-profile';
import { UserDetails } from '@shared/components/UserDetails';
import { MainMenu } from '@shared/components/MainMenu';
import { DGA } from '@shared/components/MainMenu';
import { I18nProvider } from '@lingui/react';
import { i18n } from '@lingui/core';
import { Provider } from 'react-redux';
import { actionInterceptor } from '@core/store/middleware/actionsInterceptorMiddleware';

const LazyLayout = lazy(() => import('./shared/components/Layout'));

export const AppComponent: React.FC = () => {
  const [links, setLinks] = useState<INavLink[]>([]);

  return (
    <I18nProvider i18n={i18n as any} forceRenderOnLocaleChange={false}>
      <Provider store={store}>
        {AppInit({
          LazyLayout: LazyLayout,
          actionInterceptor: actionInterceptor as any,
          applicationPaths: AppPaths,
          styleConstants: styleConstants,
          theme: theme,
          additionalConfig: {
            authority: environment.authority,
            clientId: environment.clientId,
            scope: environment.scope,
            silentLoginTimeout: environment.silentLoginTimeout,
          },
          initialLayoutProps: {
            appName: DGA,
            customButtons: [<UserDetails />, <MainMenu links={links} setLinks={setLinks} />],
            links: links,
            className: 'hide-logout',
            sessionConfig: {
              sessionCookieDomain: environment.sessionCookieDomain,
              sessionCookieIsSecure: environment.sessionCookieSecure,
              sessionIdleTimeout: environment.sessionCookieIdleTimeout,
            },
            accountHierarchyReset: false,
          },
          redirectEmptyUrl: '/dashboard',
          setOidcUser: (d) => store.dispatch(userProfileReducer.slice.actions.setOidcUser(d)),
        })}
      </Provider>
    </I18nProvider>
  );
};
