/**
 * Datagate 3 API Doc
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type ListFilterTypes = 'All' | 'Active' | 'Recent' | 'Selected' | 'Quiet' | 'Expired' | 'InAlarm' | 'Recycled';

export const ListFilterTypes = {
  All: 'All' as ListFilterTypes,
  Active: 'Active' as ListFilterTypes,
  Recent: 'Recent' as ListFilterTypes,
  Selected: 'Selected' as ListFilterTypes,
  Quiet: 'Quiet' as ListFilterTypes,
  Expired: 'Expired' as ListFilterTypes,
  InAlarm: 'InAlarm' as ListFilterTypes,
  Recycled: 'Recycled' as ListFilterTypes,
};
