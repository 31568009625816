import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

export const errorsSlice = createSlice({
  name: 'errors',
  initialState: {
    error: null as AxiosResponse<any>,
  },
  reducers: {
    addError(state, action: PayloadAction<AxiosResponse<any>>) {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export type ErrorsState = ReturnType<typeof errorsSlice.reducer>;
export const { addError } = errorsSlice.actions;
