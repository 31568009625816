/**
 * Datagate 3 API Doc
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DeviceOwner } from './deviceOwner';
import { GeoLocation } from './geoLocation';
import { SoftwareType } from './softwareType';
import { t } from '@lingui/macro';

export interface DeviceDetails {
  id?: number;
  smsNumber?: string;
  gsmNumber?: string;
  easyId?: string;
  serialNumber?: string;
  customerSerialNumber?: string;
  deviceType?: string;
  owner?: DeviceOwner;
  softwareType?: SoftwareType;
  modemType?: string;
  modemVersion?: string;
  sensorSerialNumber?: string;
  network?: string;
  logRateMs?: number;
  networkAddress?: string;
  batteryLevel?: number;
  signalLevel?: number;
  serviceCost?: number;
  serviceDescription?: string;
  lastCallInType?: string;
  utcOffset?: number;
  imei?: string;
  imsi?: string;
  notes?: string;
  createDate?: Date;
  lastCallInDate?: Date;
  lastCallInDateString?: string;
  lastRestartDate?: Date;
  lastBatteryReplacementDate?: Date;
  nextBatteryReplacementDate?: Date;
  serviceExpirationDate?: Date;
  warrantyExpirationDate?: Date;
  simExpirationDate?: Date;
  isTransient?: boolean;
  hasSonicSens?: boolean;
  isSonicSens3?: boolean;
  isAccessible?: boolean;
  isTransmitter?: boolean;
  isHub?: boolean;
  mastLocation?: GeoLocation;
  accessTechnologyType?: string;
  ecl?: number;
  rsrp?: number;
  rsrq?: number;
  hasRadarSensors?: boolean;
  orientation?: IOrientation;
  loggerExpansionBoards?: ILoggerExpansionBoards[];
  supportsSecureMode?: boolean;
  secureMode?: boolean;
}

export const OrientationPossibility = ['NotSet', 'Horizontal', 'Vertical', 'Inverted', 'NotKnown'] as const;
export type OrientationPossibilityType = (typeof OrientationPossibility)[number];
export const translatedOrientation: Record<OrientationPossibilityType, string> = {
  NotSet: t`Not set`,
  Horizontal: t`Horizontal`,
  Vertical: t`Vertical`,
  Inverted: t`Inverted`,
  NotKnown: t`Not known`,
};
export interface IOrientation {
  isOrientationOk: boolean;
  startingOrientation?: OrientationPossibilityType;
  currentOrientation?: OrientationPossibilityType;
}

export interface ILoggerExpansionBoards {
  name: string;
  version: string;
  error?: boolean;
}
