import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SetIsSidebarOpenPayload {
  open: boolean;
  deviceId?: number;
}

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: {
    open: false,
    deviceId: null,
  },
  reducers: {
    setIsSidebarOpen(state, action: PayloadAction<SetIsSidebarOpenPayload>) {
      state.open = action.payload.open;
      if (action.payload.open) {
        state.deviceId = action.payload.deviceId;
      } else {
        state.deviceId = null;
      }
    },
  },
});

export type SidebarState = ReturnType<typeof sidebarSlice.reducer>;

export default sidebarSlice;

export const { setIsSidebarOpen } = sidebarSlice.actions;
