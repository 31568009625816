import { AxiosResponse } from 'axios';
import { FileDownloadResult } from '@models/interfaces/FileDownloadResult';

const getFileFromResponse = (response: AxiosResponse) => {
  if (response.headers['content-disposition']) {
    const fileNameRegex = `filename[^;\n=]*=([^;\n]*)`;
    const fileName = response.headers['content-disposition']?.match(fileNameRegex)[1].replace(/['"]+/g, '');
    const file = new Blob([response.data], {
      type: response.headers['content-type'],
    });
    return {
      file: file,
      fileName: fileName,
    } as FileDownloadResult;
  }
  return response.data;
};

export default getFileFromResponse;
