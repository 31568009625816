import { createSelector } from 'reselect';
import { accountsAdapter } from './accounts';
import { RootState } from '../rootReducer';

const accountsState = (state: RootState) => state.accounts;

export const selectedAccStateSelector = createSelector([accountsState], (state) => ({
  ...state,
  loading: state.loadingPending > 0,
  subaccounts: accountsAdapter.getSelectors().selectAll(state.subaccounts),
}));

//

const userProfileState = (state: RootState) => state?.userProfile;

export const userProfileSelector = createSelector([userProfileState], (state) => ({
  ...state,
  loading: state.loadingPending > 0,
}));
