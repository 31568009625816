import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const mapSlice = createSlice({
  name: 'map',
  initialState: {
    showSitesWithoutLocation: false,
  },
  reducers: {
    setShowSitesWithoutLocation(state, { payload }: PayloadAction<boolean>) {
      state.showSitesWithoutLocation = payload;
    },
  },
});

export type MapState = ReturnType<typeof mapSlice.reducer>;

export default mapSlice;

export const { setShowSitesWithoutLocation } = mapSlice.actions;
