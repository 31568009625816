import { RemoveChannelThunk, FetchChannelThunk } from '@core/store/channels/channels.thunks';
import { FetchComplexMessageThunk } from '@core/store/messages/messages.thunks';
import { RadarSensorsType, SonicSensVersion } from '@models/api/models';

export const applicationPaths = {
  accounts: {
    root: 'api/accounts',
    subaccounts: (accountId?: number) =>
      `${applicationPaths.accounts.root}/subaccounts${accountId ? `?accountId=${accountId}` : ''}`,
    profile: () => `${applicationPaths.accounts.root}/profile`,
    permissions: () => `${applicationPaths.accounts.root}/profile/permissions`,
    hierarchy: (accountId?: number) =>
      `${applicationPaths.accounts.root}/profile/hierarchy${accountId ? `?includeAccountId=${accountId}` : ''}`,
    accounts: () => `${applicationPaths.accounts.root}`,
    exportAccounts: () => `${applicationPaths.accounts.root}/csv`,
    fetchAccount: (id: number) => `${applicationPaths.accounts.root}/${id}`,
    createAccount: () => `${applicationPaths.accounts.root}`,
    updateAccount: (id: number) => `${applicationPaths.accounts.root}/${id}`,
    accountLogo: (id: number) => `${applicationPaths.accounts.root}/${id}/logo`,
    alarmForwarding: (accountId: number) => `${applicationPaths.accounts.root}/${accountId}/alarmForwarding`,
    alarms: (id: number) => `${applicationPaths.accounts.root}/${id}/alarms`,
    alarmConditions: (id: number) => `${applicationPaths.accounts.root}/${id}/alarms/filters`,
    exportAlarms: (id: number) => `${applicationPaths.accounts.root}/${id}/alarms/csv`,
    acknowledgeAllAlarms: (id: number) => `${applicationPaths.accounts.root}/${id}/alarms/acknowledge/all`,
    appSettings: (id: number) => `${applicationPaths.accounts.root}/${id}/appSettings`,
    minimumPhotoNumber: (id: number) => `${applicationPaths.accounts.root}/${id}/minimumPhotoNumber`,
    parentsHierarchy: (id: number) => `${applicationPaths.accounts.root}/${id}/parentsHierarchy`,
    availableParents: () => `${applicationPaths.accounts.root}/availableParents`,
    permittingGisImport: () => `${applicationPaths.accounts.root}/permittingGisImport`,
    sitesHistory: (id: number) => `${applicationPaths.accounts.root}/${id}/sitesHistory`,
    exportSitesHistory: (id: number) => `${applicationPaths.accounts.root}/${id}/sitesHistory/csv`,
    searchAccountSites: () => `${applicationPaths.accounts.root}/sites`,
  },
  dictionaries: {
    root: '/api/dictionaries',
    countries: () => `${applicationPaths.dictionaries.root}/countries`,
    channelTypes: () => `${applicationPaths.dictionaries.root}/channelTypes`,
    configChannelTypes: () => `${applicationPaths.dictionaries.root}/config/channelTypes`,
    units: () => `${applicationPaths.dictionaries.root}/units`,
    accountTypes: () => `${applicationPaths.dictionaries.root}/accountTypes`,
    applications: () => `${applicationPaths.dictionaries.root}/applications`,
    gasZones: () => `${applicationPaths.dictionaries.root}/gas/zones`,
  },
  devices: {
    root: '/api/devices',
    list: () => `${applicationPaths.devices.root}`,
    owners: () => `${applicationPaths.devices.root}/owners`,
    details: (id: number) => `${applicationPaths.devices.root}/${id}`,
    getSensorSerialNumber: (deviceId: number) => `${applicationPaths.devices.root}/${deviceId}/sensorSerial`,
    validateSmsNumber: (smsNumber: string) => `${applicationPaths.devices.root}/validateSmsNumber/${smsNumber}`,
    messages: {
      getMessages: (id: number) => `${applicationPaths.devices.root}/${id}/messages`,
      exportMessages: (id: number) => `${applicationPaths.devices.root}/${id}/messages/export`,
    },
    history: {
      getBatteryLevelHistory: (id: number) => `${applicationPaths.devices.root}/${id}/batteryLevelHistory`,
      getSignalLevelHistory: (id: number) => `${applicationPaths.devices.root}/${id}/signalLevelHistory`,
      getAdditionalSignalsLevelHistory: (id: number) =>
        `${applicationPaths.devices.root}/${id}/additionalSignalsLevelHistory`,
    },
    config: {
      setDeviceConfig: (id: number, configType: string, dataType: string) =>
        `${applicationPaths.devices.root}/${id}/config/${configType}/${dataType}`,
      getDeviceConfig: (id: number) => `${applicationPaths.devices.root}/${id}/config`,
      exportConfig: (id: number) => `${applicationPaths.devices.root}/${id}/config/export`,
    },
    logs: {
      getDeviceLogs: (id: number) => `${applicationPaths.devices.root}/${id}/logs`,
      downloadDeviceLog: (id: number, logId: number) => `${applicationPaths.devices.root}/${id}/logs/${logId}/download`,
    },
    fetchCommands: (deviceId: number) => `${applicationPaths.devices.root}/${deviceId}/commands`,
    exportCommands: (deviceId: number) => `${applicationPaths.devices.root}/${deviceId}/commands/csv`,
    cancelAllDeviceCommands: (deviceId: number) => `${applicationPaths.devices.root}/${deviceId}/commands`,
    cancelOneDeviceCommand: (deviceId: number, commandId: number) =>
      `${applicationPaths.devices.root}/${deviceId}/commands/${commandId}`,
    exportDevices: () => `${applicationPaths.devices.root}/csv`,
  },
  gis: {
    root: 'api/gis',
    upload: () => `${applicationPaths.gis.root}/imports/upload`,
    pipes: () => `${applicationPaths.gis.root}/pipes`,
    materialTypes: () => `${applicationPaths.gis.root}/imports/pipeMaterialTypes`,
    assetTypes: () => `${applicationPaths.gis.root}/imports/assetTypes`,
    assets: () => `${applicationPaths.gis.root}/assets`,
    imports: () => `${applicationPaths.gis.root}/imports`,
    detailedImport: (id: string) => `${applicationPaths.gis.root}/imports/${id}`,
    importsProperties: () => `${applicationPaths.gis.root}/imports/mappings/properties/default`,
    importsMaterialCodes: () => `${applicationPaths.gis.root}/imports/mappings/materialCodes/default`,
    changeImportProperties: (id: string) => `${applicationPaths.gis.root}/imports/${id}/mappings/properties`,
    changeImportMaterialCodes: (id: string) => `${applicationPaths.gis.root}/imports/${id}/mappings/materialCodes`,
    contentNames: () => `${applicationPaths.gis.root}/contentNames`,
    importsMappingData: (id: string) => `${applicationPaths.gis.root}/imports/${id}/mappings/data/`,
    importsSettings: (importType: string) => `${applicationPaths.gis.root}/imports/settings/${importType}`,
    moveImports: () => `${applicationPaths.gis.root}/imports/move`,
  },
  messages: {
    root: '/api/messages',
    fetchComplexMessage: ({ messageId }: FetchComplexMessageThunk) =>
      `${applicationPaths.messages.root}/${messageId}/decodeFull`,
  },
  preferences: {
    root: '/api/preferences',
    units: (id: number) => `${applicationPaths.preferences.root}/units/account/${id}`,
  },
  roles: {
    root: '/api/applications',
    applicationList: () => `${applicationPaths.roles.root}`,
    rolesList: (id: number) => `${applicationPaths.roles.root}/${id}/roles`,
    assignableRolesList: (id: number) => `${applicationPaths.roles.root}/${id}/roles/assignable`,
    appPermissionsList: (id: number) => `${applicationPaths.roles.root}/${id}/permissions`,
    deleteRole: (applicationId: number, roleId: number) =>
      `${applicationPaths.roles.root}/${applicationId}/roles/${roleId}`,
    roleName: (applicationId: number, roleId: number) =>
      `${applicationPaths.roles.root}/${applicationId}/roles/${roleId}/name`,
    rolePermissions: (applicationId: number, roleId: number) =>
      `${applicationPaths.roles.root}/${applicationId}/roles/${roleId}/permissions`,
    roleAccessLevel: (applicationId: number, roleId: number) =>
      `${applicationPaths.roles.root}/${applicationId}/roles/${roleId}/accessLevel`,
  },
  sites: {
    root: 'api/sites',
    sitesForMap: '/api/sites/map',
    sitesStats: '/api/sites/stats',
    sitesForList: '/api/sites',
    numberOfSelectedSites: '/api/sites/selected/count',
    bulkEdit: () => `${applicationPaths.sites.root}/bulkEdit/favourite`,
    bulkEditList: () => `${applicationPaths.sites.root}/bulkEdit/list`,
    exportSites: () => `${applicationPaths.sites.root}/csv`,
    fetchClusters: () => `${applicationPaths.sites.root}/map/clusters`,
    defaultLocation: () => `${applicationPaths.sites.siteDetails.root}/defaultlocation`,
    allSites: () => `${applicationPaths.sites.siteDetails.root}/search`,
    gisValidation: () => `${applicationPaths.sites.root}/validation/gis`,
    validateGis: () => `${applicationPaths.sites.root}/validate/gis`,
    siteDetails: {
      root: '/api/sites',
      details: (id: number) => `${applicationPaths.sites.siteDetails.root}/${id}`,
      fetchChannels: (id: string) => `${applicationPaths.sites.siteDetails.root}/${id}/channels`,
      removeChannel: ({ siteId, channelId }: RemoveChannelThunk) =>
        `${applicationPaths.sites.siteDetails.root}/${siteId}/channels/${channelId}`,
      fetchChannel: ({ siteId, channelId }: FetchChannelThunk) =>
        `${applicationPaths.sites.siteDetails.root}/${siteId}/channels/${channelId}`,
      updateChannel: ({ siteId, channelId }: FetchChannelThunk) =>
        `${applicationPaths.sites.siteDetails.root}/${siteId}/channels/${channelId}`,
      createChannel: (siteId: number) => `${applicationPaths.sites.siteDetails.root}/${siteId}/channels`,
      sitePhoto: (siteId: number, sitePhotoId: number) =>
        `${applicationPaths.sites.siteDetails.root}/${siteId}/photo/${sitePhotoId}`,
      uploadPhoto: (siteId: number) => `${applicationPaths.sites.siteDetails.root}/${siteId}/photo`,
      site: (siteId: number) => `${applicationPaths.sites.siteDetails.root}/${siteId}`,
      alarmForwarding: (siteId: number) => `${applicationPaths.sites.siteDetails.root}/${siteId}/alarmForwarding`,
      fetchHistory: (siteId: number) => `${applicationPaths.sites.siteDetails.root}/${siteId}/history`,
      exportHistory: (siteId: number) => `${applicationPaths.sites.siteDetails.root}/${siteId}/history/csv`,
      fetchAlarms: (siteId: number) => `${applicationPaths.sites.siteDetails.root}/${siteId}/alarms`,
      fetchAlarmConditions: () => `${applicationPaths.sites.siteDetails.root}/alarms/filters`,
      exportAlarms: (siteId: number) => `${applicationPaths.sites.siteDetails.root}/${siteId}/alarms/csv`,
      acknowledgeAlarm: (siteId: number, alarmId: number) =>
        `${applicationPaths.sites.siteDetails.root}/${siteId}/alarms/${alarmId}/acknowledge`,
      acknowledgeAllAlarms: (siteId: number) =>
        `${applicationPaths.sites.siteDetails.root}/${siteId}/alarms/acknowledge`,
      isFavourite: (siteId: number) => `${applicationPaths.sites.siteDetails.root}/${siteId}/isFavourite`,
      tracking: (siteId: number) => `${applicationPaths.sites.siteDetails.root}/${siteId}/tracking`,
      hasGpsModule: (siteId: number) => `${applicationPaths.sites.siteDetails.root}/${siteId}/hasGpsModule`,
      fetchSonicSens: (siteId: number) => `${applicationPaths.sites.siteDetails.root}/${siteId}/sonicSens`,
      updateSonicSens: (siteId: number, version: SonicSensVersion) =>
        `${applicationPaths.sites.siteDetails.root}/${siteId}/${version}`,
      legacyAlarmLevels: (siteId: number) =>
        `${applicationPaths.sites.siteDetails.root}/${siteId}/alarms/legacy/levels`,
      updateLegacyAlarmLevel: (siteId: number, channelId: number) =>
        `${applicationPaths.sites.siteDetails.root}/${siteId}/channels/${channelId}/alarms/legacy/levels/`,
      legacyAlarmConditions: (siteId: number) =>
        `${applicationPaths.sites.siteDetails.root}/${siteId}/alarms/legacy/conditions`,
      deleteLegacyAlarmCondition: (siteId: number, conditionId: number) =>
        `${applicationPaths.sites.siteDetails.root}/${siteId}/alarms/legacy/conditions/${conditionId}`,
      fetchCommands: (siteId: number) => `${applicationPaths.sites.siteDetails.root}/${siteId}/commands`,
      exportCommands: (siteId: number) => `${applicationPaths.sites.siteDetails.root}/${siteId}/commands/csv`,
      cancelAllDeviceCommands: (siteId: number) => `${applicationPaths.sites.siteDetails.root}/${siteId}/commands`,
      cancelOneDeviceCommand: (siteId: number, commandId: number) =>
        `${applicationPaths.sites.siteDetails.root}/${siteId}/commands/${commandId}`,
      alarmUnilogOptions: (siteId: number) =>
        `${applicationPaths.sites.siteDetails.root}/${siteId}/alarms/unilog/options`,
      alarmUnilogLevels: (siteId: number) => `${applicationPaths.sites.siteDetails.root}/${siteId}/alarms/unilog`,
      alarmMicrologTriggerActions: (siteId: number) =>
        `${applicationPaths.sites.siteDetails.root}/${siteId}/alarms/microlog/triggerActions`,
      addAlarmUnilogSingle: (siteId: number, channelId: number) =>
        `${applicationPaths.sites.siteDetails.root}/${siteId}/channels/${channelId}/alarms/unilog`,
      addAlarmUnilogTimeProfile: (siteId: number, channelId: number) =>
        `${applicationPaths.sites.siteDetails.root}/${siteId}/channels/${channelId}/alarms/unilog/timeProfile`,
      disableAlarmCondition: (siteId: number, conditionNumber: number) =>
        `${applicationPaths.sites.siteDetails.root}/${siteId}/alarms/unilog/conditionNumber/${conditionNumber}`,
      addAlarmUnilogBoundary: (siteId: number, channelId: number) =>
        `${applicationPaths.sites.siteDetails.root}/${siteId}/channels/${channelId}/alarms/unilog/boundary`,
      addAlarmUnilogBasedOnChannel: (siteId: number, channelId: number, conditionalChannelId: number) =>
        `${applicationPaths.sites.siteDetails.root}/${siteId}/channels/${channelId}/alarms/unilog/channel/${conditionalChannelId}`,
      fetchTransient: (siteId: number) =>
        `${applicationPaths.sites.siteDetails.root}/${siteId}/alarms/unilog/transient`,
      updateTransient: (siteId: number) =>
        `${applicationPaths.sites.siteDetails.root}/${siteId}/alarms/unilog/transient`,
      siteGpsSettings: (siteId: number) => `${applicationPaths.sites.siteDetails.root}/${siteId}/tracking/gpsSettings`,
      siteHub: (siteId: number) => `${applicationPaths.sites.siteDetails.root}/${siteId}/hub`,
      siteHubTransmitterRecording: (siteId: number) =>
        `${applicationPaths.sites.siteDetails.root}/${siteId}/hub/transmitterRecordings`,
      fetchRadarSensors: (siteId: number) => `${applicationPaths.sites.siteDetails.root}/${siteId}/radarSensors`,
      updateRadarSensors: (siteId: number, type: RadarSensorsType) =>
        `${applicationPaths.sites.siteDetails.root}/${siteId}/radarSensors/${type}`,
      gasDeploymentInfo: (siteId: number) => `${applicationPaths.sites.siteDetails.root}/${siteId}/gasDeploymentInfo`,
    },
    favouriteLists: {
      root: '/api/sites/favourite',
      favouriteAll: () => `${applicationPaths.sites.favouriteLists.root}`,
      favouriteOne: (id: number) => `${applicationPaths.sites.favouriteLists.root}/${id}`,
      favouriteLists: () => `${applicationPaths.sites.favouriteLists.root}/lists`,
      createFavouriteList: () => `${applicationPaths.sites.favouriteLists.root}/lists`,
      favouriteListsSave: (id: number) => `${applicationPaths.sites.favouriteLists.root}/lists/${id}`,
      favouriteListsLoad: () => `${applicationPaths.sites.favouriteLists.root}/lists/load`,
      favouriteOneList: (listId: number) => `${applicationPaths.sites.favouriteLists.root}/lists/${listId ?? ''}`,
      favouriteListAssociatedAccounts: () => `${applicationPaths.sites.favouriteLists.root}/accounts`,
    },
    associations: {
      getAccounts: (id: number) => `/api/sites/${id}/accounts`,
      getAvailableAssociations: (id: number) => `/api/sites/${id}/accounts/associations/available`,
      createAssociation: (id: number, accountId: number) => `/api/sites/${id}/accounts/${accountId}`,
      deleteAssociation: (id: number, accountId: number) => `api/sites/${id}/accounts/${accountId}`,
    },
    updateSiteLocation: (id: number) => `${applicationPaths.sites.root}/${id}/location`,
    validateSiteName: (name: string) => `${applicationPaths.sites.root}/validateName/${name}`,
    messages: {
      getMessages: (id: number) => `/api/sites/${id}/messages`,
      exportMessages: (id: number) => `/api/sites/${id}/messages/export`,
    },
  },
  users: {
    root: '/api/users',
    list: () => `${applicationPaths.users.root}`,
    detailedUser: (id: number) => `${applicationPaths.users.root}/${id}`,
    getAccessProfile: (userId: number) => `${applicationPaths.users.root}/${userId}/accessProfile`,
    updateUserRoles: (userId: number) => `${applicationPaths.users.root}/${userId}/accessProfile/roles`,
    getUserAccessLevels: () => `${applicationPaths.users.root}/current/accessLevels`,
    setPassword: (userId: number) => `${applicationPaths.users.root}/${userId}/setPassword`,
    resetPassword: (userId: number) => `${applicationPaths.users.root}/${userId}/resetPassword`,
    exportUsers: () => `${applicationPaths.users.root}/csv`,
    exportUserHistory: () => `${applicationPaths.users.root}/history/csv`,
    getUsersHistory: () => `${applicationPaths.users.root}/history`,
    getSelectedUserHistory: (userId: number) => `${applicationPaths.users.root}/${userId}/history`,
    getSelectedUserAccountHistory: (userId: number) => `${applicationPaths.users.root}/${userId}/accountHistory`,
    exportSelectedUserHistory: (userId: number) => `${applicationPaths.users.root}/${userId}/history/csv`,
    exportSelectedUserAccountHistory: (userId: number) => `${applicationPaths.users.root}/${userId}/accountHistory/csv`,
    alarmForwarding: (userId: number) => `${applicationPaths.users.root}/${userId}/alarmForwarding`,
    sendUserTestMessage: (userId: number) => `${applicationPaths.users.root}/${userId}/sendTestMobileMessage`,
    generateResetUserPassword: (userId: number) => `${applicationPaths.users.root}/${userId}/generateResetUserPassword`,
    verifyMobile: (userId: number) => `${applicationPaths.users.root}/${userId}/verifyMobile`,
  },
  translations: {
    root: '/api/translations',
    applications: () => `${applicationPaths.translations.root}/applications`,
    live: (languageCode: string, application: string) =>
      `${applicationPaths.translations.root}/${application}/live/${languageCode}`,
    template: (application: string) => `${applicationPaths.translations.root}/${application}/template`,
    uploadTranslations: (application: string, languageCode: string) =>
      `${applicationPaths.translations.root}/${application}/live/${languageCode}`,
  },
  fleetReport: {
    root: '/api/accounts',
    getFleetReports: (accountId: number) => `${applicationPaths.fleetReport.root}/${accountId}/fleetReports`,
    generateFleetReport: (accountId: number) =>
      `${applicationPaths.fleetReport.root}/${accountId}/fleetReports/siteList`,
    deleteFleetReport: (accountId: number, reportId: number) =>
      `${applicationPaths.fleetReport.root}/${accountId}/fleetReports/${reportId}`,
  },
  features: {
    root: '/api/features',
    flagsList: (applicationId: number) => `${applicationPaths.features.root}/${applicationId}`,
    accountFeatureFlags: () => `${applicationPaths.features.root}/account`,
    assignedAccounts: (featureId: number) => `${applicationPaths.features.root}/${featureId}/accounts`,
    feature: (featureId: number) => `${applicationPaths.features.root}/${featureId}`,
    featureAccount: (featureId: number, accountId: number) =>
      `${applicationPaths.features.root}/${featureId}/account/${accountId}`,
  },
  utilities: {
    root: '/api/utilities',
    decodeMessage: () => `${applicationPaths.utilities.root}/decodeMessage`,
    downloadDevicesConfiguration: () => `${applicationPaths.utilities.root}/configurations/csv`,
    getDeviceReconfigurationSites: (smsNumbers: string) =>
      `${applicationPaths.utilities.root}/deviceReconfiguration/sites${smsNumbers}`,
    getFirmwares: () => `${applicationPaths.utilities.root}/deviceReconfiguration/firmwares`,
    reconfigureDevice: () => `${applicationPaths.utilities.root}/deviceReconfiguration/reconfigure`,
    mapCertainLoggers: () => `${applicationPaths.utilities.root}/mapCertainLoggers`,
    deviceRecords: () => `${applicationPaths.utilities.root}/recordings`,
    recordingEntities: () => `${applicationPaths.utilities.root}/recordings/entities`,
    calculate420: () => `${applicationPaths.utilities.root}/calculate420`,
    calculateBattery: () => `${applicationPaths.utilities.root}/calculateBattery`,
    calculateMemory: () => `${applicationPaths.utilities.root}/calculateMemory`,
    updateLogRecord: (id: number) => `${applicationPaths.utilities.root}/recordings/${id}`,
    deleteLogRecord: (id: number) => `${applicationPaths.utilities.root}/recordings/${id}`,
    createLogRecord: () => `${applicationPaths.utilities.root}/recordings`,
    fetchLogRecordSites: () => `${applicationPaths.utilities.root}/recordings/sites`,
    getFirmwareUpdates: () => `${applicationPaths.utilities.root}/firmwareupdates`,
    deleteFirmwareUpdate: (id: number) => `${applicationPaths.utilities.root}/firmwareupdates/${id}`,
    retryFailedFirmwareUpdates: () => `${applicationPaths.utilities.root}/firmwareupdates/retry`,
    deleteCompletedFirmwareUpdates: () => `${applicationPaths.utilities.root}/firmwareupdates/completed`,
    validateOverlap: () => `${applicationPaths.utilities.root}/recordings/validateOverlap`,
    bulkCreateDevicesUpload: () => `${applicationPaths.utilities.root}/devices/upload/create`,
    bulkUpdateDevicesUpload: () => `${applicationPaths.utilities.root}/devices/upload/update`,
    uploadDeviceData: () => `${applicationPaths.utilities.root}/devices/upload/command`,
    exportFirmwareUpdateList: () => `${applicationPaths.utilities.root}/firmwareUpdates/csv`,
    uploadAlarmsBulk: () => `${applicationPaths.utilities.root}/alarms/upload/command`,
    enableSecureCommunication: () => `${applicationPaths.utilities.root}/enableSecureCommunication`,
    disableSecureCommunication: () => `${applicationPaths.utilities.root}/disableSecureCommunication`,
    bulkEditDevices: () => `${applicationPaths.utilities.root}/bulkChange`,
		getScheduledReports: () => `${applicationPaths.utilities.root}/scheduledReport`,
		deleteScheduledReport: (scheduledReportId: number) => 
		`${applicationPaths.utilities.root}/ScheduledReport/${scheduledReportId}`,
    monitoredSites: () => `${applicationPaths.utilities.root}/monitoredSites`,
    hierarchyLinking: () => `${applicationPaths.utilities.root}/linkedHierarchies`,
    hierarchyLinkingTopLvlAcc: () => `${applicationPaths.utilities.root}/linkedHierarchies/topLevelAccounts`,
  },
};
