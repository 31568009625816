import { useCallback } from 'react';
import { applicationPaths } from '@core/http/applicationPaths';
import { useApi } from '@core/http/useApi';
import { useAppDispatch } from '@core/store/store';
import { AccessProfile } from '@models/api/models';
import { accountsReducer } from '@core/store/library-stores/accounts';

export const useUserDetailsDataSource = () => {
  const dispatch = useAppDispatch();

  const [getUserAccessProfile, userAccessProfile, loading, error] = useApi<AccessProfile>('', 'get');

  const handleGetUserAccessProfile = useCallback(
    (userId: number) => {
      if (userId) {
        const url = applicationPaths.users.getAccessProfile(userId);
        getUserAccessProfile({ additionalOptions: { url } }).catch(() =>
          dispatch(accountsReducer.slice.actions.setUserRole(null))
        );
      }
    },
    [dispatch, getUserAccessProfile]
  );

  return {
    userAccessProfile,
    getUserAccessProfile: handleGetUserAccessProfile,
    loading,
    error,
  };
};
