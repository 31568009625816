/**
 * Datagate 3 API Doc
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { t } from "@lingui/macro";

export type SiteOrDeviceHistoryType = 'All' | 'SiteHistory' | 'DeviceHistory';

export const SiteOrDeviceHistoryOptions:Record<SiteOrDeviceHistoryType, string> = {
    All: t`All`,
    SiteHistory: t`Site History`,
    DeviceHistory: t`Device History`,
};

export interface SiteHistoryEntry { 
    id?: number;
    user?: string;
    date?: Date;
    notes?: string;
    accountId?: number;
    accountName?: string;
    smsNumber?: string;
    searchPhrase?: string;
    isSiteEntry?: boolean;
}
