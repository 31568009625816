import {
  BulkOperationContext,
  SiteMapFilterTypes,
} from '@features/DashboardPage/components/map/SiteMapFilters/BaseSiteFilters';
import { MastSiteLocationInfo } from '@models/api/mastLocationInfo';
import { GeoLocation, SiteLocationInfo, SitesListItem } from '@models/api/models';
import { SiteCluster } from '@models/api/siteCluster';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../rootReducer';
import { ActionStatus, MapEditingState, SitesSearchInFilterTypes, SitesState } from './sites.reducer';

const selectSitesState = (state: RootState) => state.sites;

export const selectSiteDetailsFormMode = createSelector(
  [selectSitesState],
  (state: SitesState) => state.siteDetailsMode
);

export const selectTextFilter = createSelector([selectSitesState], (state: SitesState) => state.textFilter);

export const selectAssociationFilter = createSelector(
  [selectSitesState],
  (state: SitesState) => state.associationFilter
);

export const selectActiveFilterBtn = createSelector([selectSitesState], (state: SitesState) => state.activeFilterBtn);
export const selectSiteDetail = (page: string, index: number) =>
  createSelector([selectSitesState], (state: SitesState) => {
    if (state.list.chunks?.[page]?.[index]) {
      return state.list.chunks[page][index];
    }
    return {};
  });
export const selectInitialChunksFetched = createSelector(
  [selectSitesState],
  (state: SitesState) => !!state.list.chunks
);

export const selectVirtualScrollIsLoading = createSelector([selectSitesState], (state: SitesState): boolean => {
  return state.virtualScrollIsLoading;
});

export const selectPageIsLoading = createSelector([selectSitesState], (state: SitesState) => state.list.pageIsLoading);
export const selectSitesListPageCleared = createSelector(
  [selectSitesState],
  (state: SitesState) => state.list.pagesAreCleared
);
export const selectSitesTotalItems = createSelector([selectSitesState], (state: SitesState) => state.list.totalItems);
export const selectSitesCurrentPage = createSelector([selectSitesState], (state: SitesState) => state.list.currentPage);
export const selectSitesTotalPages = createSelector([selectSitesState], (state: SitesState) => state.list.totalPages);
export const selectAllChunks = createSelector([selectSitesState], (state: SitesState) => state.list.chunks);

export const selectNumberOfSelectedSites = createSelector(
  [selectSitesState],
  (state: SitesState) => state.numberOfSelectedSites
);

export const selectSiteDetails = createSelector([selectSitesState], (state: SitesState) => state.details);
export const selectSiteDetailsDeviceId = createSelector(
  [selectSitesState],
  (state: SitesState) => state.details.device?.id
);
export const selectSiteDetailsDeviceInfo = createSelector(
  [selectSitesState],
  (state: SitesState) => state.details.device
);
export const selectSitePhotosStatistics = createSelector(
  [selectSitesState],
  (state: SitesState) => state.sitePhotosStatistics
);

export const selectLoadingDetailsState = createSelector([selectSitesState], (state: SitesState): ActionStatus => {
  return state.detailsLoadingStatus;
});

export const selectEditedSiteDetailsMarker = createSelector(
  [selectSitesState],
  (state: SitesState): SiteLocationInfo => {
    return state.editedSiteDetailsMarker;
  }
);

export const selectIsEditingSiteLocationOnMap = createSelector(
  [selectSitesState],
  (state: SitesState): MapEditingState => {
    return state.isEditingSiteLocationOnMap;
  }
);

export const selectEditedSiteDetailsLocation = createSelector([selectSitesState], (state: SitesState): GeoLocation => {
  return state.editedSiteDetailsMarker?.location;
});

export const selectIsDeviceUnilog = createSelector([selectSitesState], (state: SitesState): boolean => {
  return state?.details?.device?.softwareType?.isUnilog;
});

export const selectIsDeviceMicrolog = createSelector([selectSitesState], (state: SitesState): boolean => {
  return state?.details?.device?.softwareType?.isMicrolog;
});

export const selectIsDeviceBinaryAlarms = createSelector([selectSitesState], (state: SitesState): boolean => {
  return state?.details?.device?.softwareType?.isBinaryAlarms;
});

export const selectClusters = createSelector([selectSitesState], (state: SitesState): SiteCluster[] => {
  return state?.clusters;
});

export const selectSitesForMap = createSelector(
  [selectSitesState],
  (state: SitesState): MastSiteLocationInfo[] => state?.sites
);

export const selectMasts = createSelector(
  [selectSitesState],
  (state: SitesState): MastSiteLocationInfo[] => state?.masts
);

export const selectSitesSearchIn = createSelector([selectSitesState], (state: SitesState): SitesSearchInFilterTypes => {
  return state?.searchIn;
});

export const selectIsSitesListTableView = createSelector([selectSitesState], (state: SitesState): boolean => {
  return state?.table?.isTableView;
});

export const selectActionOpenedInTableView = createSelector(
  [selectSitesState],
  (state: SitesState): SiteMapFilterTypes => {
    return state?.table?.actionOpenedInTableView;
  }
);

export const selectBulkOperationContext = createSelector(
  [selectSitesState],
  (state: SitesState): BulkOperationContext => {
    return state?.table?.bulkOperationContext;
  }
);

export const selectSitesTableView = createSelector([selectSitesState], (state: SitesState): SitesListItem[] => {
  return state?.table?.sites;
});

export const selectSitesListCount = createSelector([selectSitesState], (state: SitesState): number => {
  return !state.list.pageIsLoading ? state?.list?.totalItems ?? 0 : 0;
});

export const selectSingleSiteFromList = createSelector([selectSitesState], (state: SitesState): SitesListItem => {
  return !state.list.pageIsLoading &&
    state.list.totalItems === 1 &&
    state.list.chunks[1] &&
    state.list.chunks[1].length === 1
    ? state.list.chunks[1][0]
    : null;
});

export const selectMaxNumOfSitesForSelectAll = createSelector([selectSitesState], (state: SitesState): number => {
  return state.maximumSitesForSelectAll;
});

export const selectPrevPath = createSelector([selectSitesState], (state: SitesState): string => {
  return state?.prevPath;
});
