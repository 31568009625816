import { applicationPaths } from '@core/http/applicationPaths';
import httpClient from '@core/http/httpClient';
import { buildAsyncThunk } from '@core/store/utils';
import { FavouriteSitesList, HierarchyAccount } from '@models/api/models';
import { AxiosResponse } from 'axios';
import { RootState } from '../rootReducer';
import { selectActiveFilterBtn, selectTextFilter } from '../sites/sites.selectors';
import { fetchSitesForList } from '../sites/sites.thunk';
import { setCurrentListId } from './favouriteLists.reducer';
import { selectedAccStateSelector } from '../library-stores/selectors';

export interface SetOneSiteAsFavouriteThunk {
  id: number;
}
export const setOneSiteAsFavourite = buildAsyncThunk(
  'favouriteLists/setOneAsFavorite',
  async (data: SetOneSiteAsFavouriteThunk) => {
    const response: AxiosResponse<boolean> = await httpClient.post(
      applicationPaths.sites.favouriteLists.favouriteOne(data.id)
    );
    return response.data;
  }
);

export const removeSiteFromFavourites = buildAsyncThunk(
  'favouriteLists/setOneAsNotFavorite',
  async (data: SetOneSiteAsFavouriteThunk) => {
    const response: AxiosResponse<boolean> = await httpClient.delete(
      applicationPaths.sites.favouriteLists.favouriteOne(data.id)
    );
    return response.data;
  }
);

export const setAllSitesAsFavourite = buildAsyncThunk('favouriteLists/setAllAsFavorite', async (_: void, store) => {
  const search = selectTextFilter(store.getState() as RootState);
  const filterType = selectActiveFilterBtn(store.getState() as RootState);
  const accountId = selectedAccStateSelector(store.getState() as RootState)?.selectedAccount?.id;
  const params = { search, filterType, accountId };
  const response: AxiosResponse<boolean> = await httpClient.post(
    applicationPaths.sites.favouriteLists.favouriteAll(),
    params
  );
  return response.data;
});

export const setAllSitesAsNotFavourite = buildAsyncThunk(
  'favouriteLists/setAllAsNotFavorite',
  async (_: void, store) => {
    const search = selectTextFilter(store.getState() as RootState);
    const filterType = selectActiveFilterBtn(store.getState() as RootState);
    const accountId = selectedAccStateSelector(store.getState() as RootState)?.selectedAccount?.id;
    const params = { search, filterType, accountId };
    const response: AxiosResponse<boolean> = await httpClient.delete(
      applicationPaths.sites.favouriteLists.favouriteAll(),
      { data: params }
    );
    return response.data;
  }
);

export interface FetchFavouriteListsThunk {
  search?: string;
}

export const fetchFavouriteLists = buildAsyncThunk(
  'favouriteLists/fetchFavouriteLists',
  async (params: FetchFavouriteListsThunk) => {
    const response: AxiosResponse<FavouriteSitesList[]> = await httpClient.get(
      applicationPaths.sites.favouriteLists.favouriteLists(),
      { params }
    );
    return response.data;
  }
);

export const createNewFavouriteList = buildAsyncThunk(
  'favouriteLists/createNewFavouriteList',
  async (listName: string, store) => {
    const response: AxiosResponse<FavouriteSitesList> = await httpClient.post(
      applicationPaths.sites.favouriteLists.createFavouriteList(),
      null,
      {
        params: {
          name: listName,
        },
      }
    );
    await store.dispatch(loadFavouriteList(response.data.id));
    await store.dispatch(setCurrentListId(response.data));

    return response.data;
  }
);

export const loadFavouriteList = buildAsyncThunk('favouriteLists/loadFavouriteList', async (listId: number, store) => {
  const params = { listId: listId ? listId : null };
  const response: AxiosResponse = await httpClient.post(
    applicationPaths.sites.favouriteLists.favouriteListsLoad(),
    null,
    { params }
  );
  store.dispatch(
    fetchSitesForList({
      associationFilter: (store.getState() as RootState).sites.associationFilter,
      filterType: (store.getState() as RootState).sites.activeFilterBtn,
      search: (store.getState() as RootState).sites.textFilter,
      accountId: (store.getState() as any)?.accounts?.selectedAccount?.id,
      currentPage: 1,
    })
  );
  return response.data;
});

export const saveFavouriteList = buildAsyncThunk('favouriteLists/saveFavouriteList', async (listId: number) => {
  const response: AxiosResponse = await httpClient.post(
    applicationPaths.sites.favouriteLists.favouriteListsSave(listId)
  );
  return response.data;
});

export const removeListFromFavouriteList = buildAsyncThunk(
  'favouriteLists/removeListFromFavouriteList',
  async (listId: number) => {
    const response: AxiosResponse = await httpClient.delete(
      applicationPaths.sites.favouriteLists.favouriteOneList(listId)
    );
    return response.data;
  }
);

export interface SetMultipleSitesAsFavouriteThunk {
  siteIds?: number[];
  smsNumbers?: string[];
  accountId?: number;
}

export const setMultipleSitesAsFavourite = buildAsyncThunk(
  'favouriteLists/setMultipleAsFavorite',
  async (params: SetMultipleSitesAsFavouriteThunk) => {
    const response: AxiosResponse<boolean> = await httpClient.post(
      applicationPaths.sites.favouriteLists.favouriteAll(),
      params
    );
    return response.data;
  }
);

export interface SetMultipleSitesAsNotFavouriteThunk {
  siteIds: number[];
  accountId?: number;
}

export const setMultipleSitesAsNotFavourite = buildAsyncThunk(
  'favouriteLists/setMultipleAsNotFavorite',
  async (params: SetMultipleSitesAsNotFavouriteThunk) => {
    const response: AxiosResponse<boolean> = await httpClient.delete(
      applicationPaths.sites.favouriteLists.favouriteAll(),
      { data: params }
    );
    return response.data;
  }
);
export interface GetFavouriteListAssociatedAccountsThunk {
  searchPhrase?: string;
}

export const getFavouriteListAssociatedAccounts = buildAsyncThunk(
  'favouriteLists/accounts',
  async (params: GetFavouriteListAssociatedAccountsThunk) => {
    const response: AxiosResponse<HierarchyAccount[]> = await httpClient.get(
      applicationPaths.sites.favouriteLists.favouriteListAssociatedAccounts(),
      {
        params,
      }
    );
    return response.data;
  }
);
