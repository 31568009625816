/**
 * Datagate 3 API Doc
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type GpsSettingsAccuracy = 'LowAccuracy' | 'HighAccuracy';

export const GpsSettingsAccuracy = {
  LowAccuracy: 'LowAccuracy' as GpsSettingsAccuracy,
  HighAccuracy: 'HighAccuracy' as GpsSettingsAccuracy,
};
