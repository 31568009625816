import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { actionInterceptor } from './middleware/actionsInterceptorMiddleware';
import rootReducer, { RootState } from './rootReducer';

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([actionInterceptor.middleWareFn as any]),
});

export type AppStore = typeof store;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector = <State = RootState, PartState = unknown>(
  selector: (state: State) => PartState
): ReturnType<typeof selector> => useSelector<State, ReturnType<typeof selector>>(selector); // Export a hook that can be reused to resolve types

export default store;
