import { accountTypesDictionary } from './accountTypesDictionary';
import { channelTypesDictionary } from './channelTypesDictionary';
import { configChannelTypesDictionary } from './configChannelTypesDictionary';
import { errorCodesDictionary } from './errorCodesDictionary';

export const translatedDictionaries = {
  channelTypes: channelTypesDictionary,
  configChannelTypes: configChannelTypesDictionary,
  accountTypes: accountTypesDictionary,
  errorCodes: errorCodesDictionary,
};

export enum TranslatableDictionaries {
  ChannelTypes,
  ConfigChannelTypes,
  AccountTypes,
  ErrorCodes,
}
