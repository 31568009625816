import { applicationPaths } from '@core/http/applicationPaths';
import httpClient from '@core/http/httpClient';
import { buildAsyncThunk } from '@core/store/utils';
import { AddSiteChannelRequest, SiteChannels, UpdateSiteChannelRequest } from '@models/api/models';
import { AxiosResponse } from 'axios';

export const fetchSiteChannels = buildAsyncThunk('channels/fetchSiteChannels', async (id: string) => {
  const response: AxiosResponse<SiteChannels> = await httpClient.get(
    applicationPaths.sites.siteDetails.fetchChannels(id)
  );
  return response.data;
});

export interface RemoveChannelThunk {
  siteId: number;
  channelId: number;
}

export const removeChannel = buildAsyncThunk('channels/removeChannel', async (dataThunk: RemoveChannelThunk) => {
  const response: AxiosResponse = await httpClient.delete(applicationPaths.sites.siteDetails.removeChannel(dataThunk));
  return response.data;
});

export interface FetchChannelThunk {
  siteId: number;
  channelId: number;
}

export const fetchChannel = buildAsyncThunk('channels/fetchChannel', async (dataThunk: FetchChannelThunk) => {
  const response: AxiosResponse = await httpClient.get(applicationPaths.sites.siteDetails.fetchChannel(dataThunk));
  return response.data;
});

export interface UpdateChannelThunk {
  channelDetails: FetchChannelThunk;
  channelData: UpdateSiteChannelRequest;
}

export const updateChannel = buildAsyncThunk('channels/updateChannel', async (dataThunk: UpdateChannelThunk) => {
  const response: AxiosResponse = await httpClient.put(
    applicationPaths.sites.siteDetails.updateChannel(dataThunk.channelDetails),
    dataThunk.channelData
  );
  return response.data;
});

export interface CreateChannelThunk {
  siteID: number;
  channelData: AddSiteChannelRequest;
}

export const createChannel = buildAsyncThunk('channels/createChannel', async (dataThunk: CreateChannelThunk) => {
  const response: AxiosResponse = await httpClient.post(
    applicationPaths.sites.siteDetails.createChannel(dataThunk.siteID),
    dataThunk.channelData
  );
  return response.data;
});
