import { applicationPaths } from '@core/http/applicationPaths';
import httpClient from '@core/http/httpClient';
import { AxiosResponse } from 'axios';
import { buildAsyncThunk } from '../utils';

export const getUnitPreferences = buildAsyncThunk('preferences/getUnitPreferences', async (accId: number) => {
  const response: AxiosResponse<Array<[string, string]>> = await httpClient.get(
    applicationPaths.preferences.units(accId)
  );
  return { accId, data: response.data };
});

export interface UpdateUnitPreferencesThunk {
  accId?: number;
  data?: Record<string, string>;
}

export const updateUnitPreferences = buildAsyncThunk(
  'preferences/updateUnitPreferences',
  async ({ accId, data }: UpdateUnitPreferencesThunk) => {
    const response: AxiosResponse = await httpClient.put(applicationPaths.preferences.units(accId), data);
    return response.data;
  }
);
