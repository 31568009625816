import { t } from '@lingui/macro';
import CustomObject from '@models/interfaces/CustomObject';

export const accountTypesDictionary = (): CustomObject => {
  return {
    Account: t`Account`,
    PMZ: t`PMZ`,
    DMA: t`DMA`,
    PCorr: t`PCorr`,
    Drainage: t`Drainage`,
  };
};
