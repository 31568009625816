export enum PermissionsTypes {
  // DataGate
  CanEditAccounts = 'Can edit accounts',
  CanEditTranslations = 'Can edit translations',
  CanEditEnglishTranslations = 'Can edit en-GB translations',
  CanReadTemplateTranslations = 'Can see translations',
  CanRemotelyControlDevices = 'Can remotely control devices',
  CanSetAlarms = 'Can set alarms',
  CanEditSites = 'Can edit sites',
  CanSwapDevices = 'Can swap devices',
  CanCreateEditDevices = 'Can create/edit devices',
  CanAdvanceControlSonicSens = 'Can advance control Sonic Sens',
  CanAdvanceControlGasDeploymentInfo = 'Can advance control gas deployment',
  CanSeeEditFleetReports = 'Can see and edit fleet reports',
	CanManageScheduledReports = 'Can manage scheduled reports',
  CanAddTopLevelAccount = 'Can add top level account',
  CanAccessOtherPeopleDatagateData = 'Can access other people Datagate data',
  CanCreateTopLevelAccount = 'Can create top level account',
  CanSeeSites = 'Can see sites',
  CanUpdateDeviceFirmware = 'Can update device firmware',
  CanAdvanceConfigureDevice = 'Can advance configure device',
  CanAccessFunctions = 'Can access functions',
  CanAccessAdvancedFunctions = 'Can access advanced functions',
  CanRecycleSites = 'Can recycle sites',
  CanAcknowledgeAlarms = 'Can acknowledge alarms',
  CanSetSiteStartEndDate = 'Can set start and end date for sites',
  CanEditDeviceRecordings = 'Can edit device recordings',
  CanSeeEclRsrpRsrqInfo = 'Can see ECL, RSRP, RSRQ info',
  CanChangeDeviceOwnerAndAmendNotes = 'Can change device ownership and amend notes',
  CanControlRadarSensors = 'Can control Radar Sensors',
  CanManageSmsNotifications = 'Can Manage Sms Notifications',
  CanCreateUsersInRestrictedAccounts = 'Can create users in restricted accounts',
  CanManageMonitoredSites = 'Can manage monitored sites',
  CanLinkHierarchy = 'Can Link Hierarchy',

  // User module
  CanSeeUsers = 'Can see users',
  CanEditUsers = 'Can edit users',
  CanAssignRolesToUser = 'Can create users and assign roles',
  CanImpersonateUser = 'Can impersonate user',
  CanViewRoles = 'Can view Roles',
  CanManageRoles = 'Can manage roles',
  CanResetUserPassword = 'Can reset user password',
  CanAccessOtherPeopleUsersData = 'Can access other people Users module data',
  CanAccessInternalRoles = 'Can access internal roles',
  CanAccessAdminRoles = 'Can access admin roles',
  CanManageFeatureFlags = 'Can Manage Feature Flags',

  //GIS
  CanSeeImports = 'Can see imports',
  CanEditImports = 'Can edit imports',
}

export enum PressViewPermissionsTypes {
  CanUpdateCallinSettings = 'Can update call in settings',
  CanEditAccounts = 'Can edit accounts',
  CanSubmitPMZSettings = 'Can submit PMZ settings',
  CanRemotelyControlDevices = 'Can remotely control devices',
  CanSeeEclRsrpRsrqInfo = 'Can see ECL, RSRP, RSRQ info',
}

export enum PermaNetWebPermissionsTypes {
  CanEditSites = 'Can edit sites',
  CanEditAccounts = 'Can edit accounts',
  CanGenerateFleetReports = 'Can generate fleet report',
  CanRemotelyControlDevices = 'Can remotely control devices',
  CanEditLoggerMode = 'Can edit logger mode',
  CanAmendThreshold = 'Can amend threshold',
  CanEditCorrelations = 'Can edit correlations',
  CanEditLeakConfirmations = 'Can edit leak confirmations',
  CanViewDeviceRecordings = 'Can view device recordings',
  CanEditLastNote = 'Can edit last note',
  CanEditInactiveSites = 'Can edit inactive sites',
  CanCreateExtendedRecordings = 'Can create extended recordings',
  CanEditSurveys = 'Can edit surveys (DxMic)',
  CanRecycleSites = 'Can recycle sites',
  CanSeeEclRsrpRsrqInfo = 'Can see ECL, RSRP, RSRQ info',
  CanUseExtendedRepeatForSoundRecording = 'Can use extended repeat for sound recording',
}

export enum SpillguardPermissionsTypes {
  CanManageInvestigations = 'Can manage investigations',
  CanManageRainfall = 'Can manage rainfall',
}

export enum DataViewPermissionsTypes {
  CanSeeAnyAccount = 'Can see any account',
  CanSeeAndEditFleetReports = 'Can see and edit fleet reports',
  CanEditSites = 'Can edit sites',
  CanRemotelyControlDevices = 'Can remotely control devices',
  CanSetAlarms = 'Can set alarms',
  CanSeeEclRsrpRsrqInfo = 'Can see ECL, RSRP, RSRQ info',
}

export enum MaintenancePermissionsTypes {
  CanAccessMaintenance = 'Can access maintenance',
  CanEditMaintenance = 'Can edit maintenance',
  CanRequestMaintenance = 'Can request maintenance',
  CanAccessMaintenanceOutstanding = 'Can access maintenance outstanding',
  CanExportOutstandingMaintenance = 'Can export outstanding maintenance',
  CanCancelMaintenanceReportRequest = 'Can cancel maintenance report request',
  CanAccessMaintenanceTechWorkload = 'Can access maintenance tech workload',
  CanAccessMaintenanceHistory = 'Can access maintenance history',
  CanAssignMaintenance = 'Can assign maintenance',
  CanApproveMaintenance = 'Can approve maintenance',
  CanAccessMaintenanceUpload = 'Can access maintenance upload',
  CanSeeAllWorkloadUsers = 'Can see all workload users',
  CanAccessMaintenanceStats = 'Can access maintenance stats',
  CanAccessOtherPeopleMaintenanceData = 'Can access other people maintenance data',
}

export enum StepTestPermissionsTypes {
  CanSeeAnyAccount = 'Can see any account',
  CanScheduleStepTest = 'Can schedule Step Test',
  CanManageCredits = 'Can manage credits',
}

export enum GISManagementPermissionsTypes {
  CanSeeImports = 'Can see imports',
  CanEditImports = 'Can edit imports',
}
