import httpClient from '@core/http/httpClient';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPathPayload } from '@hwm/ui-lib';
import { AxiosResponse } from 'axios';
import { AccountModel } from '@models/api/models';
import { applicationPaths } from '@core/http/applicationPaths';

const fetchSubaccounts = createAsyncThunk(
  `accounts/fetchSubaccounts`,
  async (params: getPathPayload<typeof applicationPaths.accounts.subaccounts>) => {
    const response: AxiosResponse<AccountModel[]> = await httpClient.get(applicationPaths.accounts.subaccounts(params));

    return response.data;
  }
);

const fetchById = createAsyncThunk(
  `accounts/fetchById`,
  async (params: getPathPayload<typeof applicationPaths.accounts.fetchAccount>) => {
    const response: AxiosResponse<AccountModel> = await httpClient.get(applicationPaths.accounts.fetchAccount(params));

    return response.data;
  }
);

export const accountsThunks = { fetchSubaccounts, fetchById };
