/**
 * Datagate 3 API Doc
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type DeviceAssignmentFilterTypes = 'All' | 'NoSiteAssignment' | 'NoOwnerAssignment' | 'NoAssignment';

export const DeviceAssignmentFilterTypes = {
  All: 'All' as DeviceAssignmentFilterTypes,
  NoSiteAssignment: 'NoSiteAssignment' as DeviceAssignmentFilterTypes,
  NoOwnerAssignment: 'NoOwnerAssignment' as DeviceAssignmentFilterTypes,
  NoAssignment: 'NoAssignment' as DeviceAssignmentFilterTypes,
};
