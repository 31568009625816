import { applicationPaths } from '@core/http/applicationPaths';
import httpClient from '@core/http/httpClient';
import { AccountType } from '@models/api/accountType';
import { Application, ChannelType, Country, Unit, GasZone } from '@models/api/models';
import { AxiosResponse } from 'axios';
import { buildAsyncThunk } from '../utils';

export const fetchCountriesDictionary = buildAsyncThunk(
  'dictionaries/fetchCountriesDictionary',
  async (noArgs: void) => {
    const response: AxiosResponse<Country[]> = await httpClient.get(applicationPaths.dictionaries.countries());
    return response.data;
  }
);

export const fetchUnitsDictionary = buildAsyncThunk('dictionaries/fetchUnitsDictionary', async (noArgs: void) => {
  const response: AxiosResponse<Unit[]> = await httpClient.get(applicationPaths.dictionaries.units());
  return response.data;
});

export const fetchAccountTypes = buildAsyncThunk('dictionaries/fetchAccountTypes', async (noArgs: void) => {
  const response: AxiosResponse<AccountType[]> = await httpClient.get(applicationPaths.dictionaries.accountTypes());
  return response.data;
});

export const fetchConfigChannelTypes = buildAsyncThunk('dictionaries/fetchConfigChannelTypes', async (noArgs: void) => {
  const response: AxiosResponse<ChannelType[]> = await httpClient.get(
    applicationPaths.dictionaries.configChannelTypes()
  );
  return response.data;
});

export const fetchApplications = buildAsyncThunk('dictionaries/fetchApplications', async (noArgs: void) => {
  const response: AxiosResponse<Application[]> = await httpClient.get(applicationPaths.dictionaries.applications());
  return response.data;
});

export const fetchChannelTypes = buildAsyncThunk('channels/fetchChannelTypes', async (noArgs: void) => {
  const response: AxiosResponse<ChannelType[]> = await httpClient.get(applicationPaths.dictionaries.channelTypes());
  return response.data;
});

export const fetchGasZones = buildAsyncThunk('dictionaries/fetchGasZones', async (noArgs: void) => {
  const response: AxiosResponse<GasZone[]> = await httpClient.get(applicationPaths.dictionaries.gasZones());
  return response.data;
});
