/**
 * Datagate 3 API Doc
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type GisSiteValidationStatus = 'NotValidated' | 'Validated' | 'UnableToValidate';

export const GisSiteValidationStatus = {
  NotValidated: 'NotValidated' as GisSiteValidationStatus,
  Validated: 'Validated' as GisSiteValidationStatus,
  UnableToValidate: 'UnableToValidate' as GisSiteValidationStatus,
};
