import { applicationPaths } from '@core/http/applicationPaths';
import httpClient from '@core/http/httpClient';
import { buildAsyncThunk } from '@core/store/utils';
import { DevicesSearchInFilterTypes } from '@features/Devices/list/DevicesList';
import { AdditionalSignalsLevelHistoryEntry } from '@models/api/additionalSignalsLevelHistoryEntry';
import { BatteryLevelHistoryEntry } from '@models/api/batteryLevelHistoryEntry';
import { DeviceCommandStatus } from '@models/api/deviceCommandStatus';
import { DeviceLog } from '@models/api/deviceLog';
import { DownloadDeviceLogsEntry } from '@models/api/downloadDeviceLogsEntry';
import {
  CreateEditDeviceRequest,
  Device,
  DeviceAssignmentFilterTypes,
  DeviceConfiguration,
  DeviceDetails,
  HierarchyAccount,
} from '@models/api/models';
import { SignalLevelHistoryEntry } from '@models/api/signalLevelHistoryEntry';
import CustomObject from '@models/interfaces/CustomObject';
import { PagedResponse } from '@models/interfaces/PagedResponse';
import getFileFromResponse from '@shared/utils/getFileFromResponse';
import { AxiosResponse } from 'axios';

export interface FetchDeviceOwnersThunk {
  name: string;
}

export const fetchOwners = buildAsyncThunk('devices/fetchOwners', async (data: FetchDeviceOwnersThunk) => {
  let params = {};

  if (data.name) {
    params = {
      name: data.name,
    };
  }

  const response: AxiosResponse<HierarchyAccount[]> = await httpClient.get(applicationPaths.devices.owners(), {
    params: params,
  });

  return response.data;
});

export interface FetchDevicesThunk {
  ascending?: boolean | null;
  orderBy?: string | null;
  ownerId?: number;
  searchPhrase?: string;
  assignmentType?: string;
  pageSize?: number;
  currentPage?: number;
  searchOptions?: string;
  filterByAccount?: number;
}

export const fetchDevices = buildAsyncThunk('devices/fetchDevices', async (params: FetchDevicesThunk) => {
  const response: AxiosResponse<PagedResponse<Device>> = await httpClient.get(applicationPaths.devices.list(), {
    params,
  });
  return response.data;
});

export interface FetchDeviceDetailsThunk {
  id: number;
}

export const fetchDeviceDetails = buildAsyncThunk(
  'devices/fetchDeviceDetails',
  async (params: FetchDeviceDetailsThunk) => {
    const response: AxiosResponse<DeviceDetails> = await httpClient.get(applicationPaths.devices.details(params.id));
    return response.data;
  }
);

export interface UpdateDeviceDetailsThunk {
  id: number;
  params: CreateEditDeviceRequest;
}

export const updateDeviceDetails = buildAsyncThunk(
  'devices/updateDeviceDetails',
  async (requestConfig: UpdateDeviceDetailsThunk) => {
    const response: AxiosResponse<DeviceDetails> = await httpClient.put(
      applicationPaths.devices.details(requestConfig.id),
      requestConfig.params
    );
    return response.data;
  }
);

export interface CreateDeviceThunk {
  params: CreateEditDeviceRequest;
}

export const createDevice = buildAsyncThunk('devices/createDevice', async (requestConfig: CreateDeviceThunk) => {
  const response: AxiosResponse<number> = await httpClient.post(applicationPaths.devices.root, requestConfig.params);
  return response.data;
});

export interface GetAllowedDeviceOwnersThunk {
  searchPhrase?: string;
}

export const getAvailableDeviceOwners = buildAsyncThunk(
  'Devices/getAvailableDeviceOwners',
  async (data: GetAllowedDeviceOwnersThunk) => {
    let params = {};
    if (data.searchPhrase) {
      params = {
        searchPhrase: data.searchPhrase,
      };
    }
    const response: AxiosResponse = await httpClient.get(applicationPaths.accounts.hierarchy(), {
      params: params,
    });
    return response.data;
  }
);

export interface GetBatteryLevelHistoryThunk {
  deviceId: number;
  from?: string;
  to?: string;
}

export const getBatteryLevelHistory = buildAsyncThunk(
  'devices/getBatteryLevelHistory',
  async ({ deviceId, ...params }: GetBatteryLevelHistoryThunk) => {
    const response: AxiosResponse<BatteryLevelHistoryEntry[]> = await httpClient.get(
      applicationPaths.devices.history.getBatteryLevelHistory(deviceId),
      {
        params: params,
      }
    );
    return response.data;
  }
);

export interface GetSignalLevelHistoryThunk {
  deviceId: number;
  from?: string;
  to?: string;
}

export const getSignalLevelHistory = buildAsyncThunk(
  'devices/getSignalLevelHistory',
  async ({ deviceId, ...params }: GetSignalLevelHistoryThunk) => {
    const response: AxiosResponse<SignalLevelHistoryEntry[]> = await httpClient.get(
      applicationPaths.devices.history.getSignalLevelHistory(deviceId),
      {
        params: params,
      }
    );
    return response.data;
  }
);

export const getAdditionalSignalsLevelHistory = buildAsyncThunk(
  'devices/additionalSignalsLevelHistory',
  async ({ deviceId, ...params }: GetSignalLevelHistoryThunk) => {
    const response: AxiosResponse<AdditionalSignalsLevelHistoryEntry[]> = await httpClient.get(
      applicationPaths.devices.history.getAdditionalSignalsLevelHistory(deviceId),
      {
        params: params,
      }
    );
    return response.data;
  }
);

export interface SetDeviceConfigThunk {
  id: number;
  configType: string;
  dataType: string;
  data: CustomObject;
}

export const setDeviceConfig = buildAsyncThunk(
  'devices/setDeviceConfig',
  async ({ id, configType, dataType, data }: SetDeviceConfigThunk) => {
    const response: AxiosResponse = await httpClient.post(
      applicationPaths.devices.config.setDeviceConfig(id, configType, dataType),
      data
    );
    return response.data;
  }
);

export const getDeviceConfig = buildAsyncThunk('devices/getDeviceConfig', async (id: number) => {
  const response: AxiosResponse<DeviceConfiguration> = await httpClient.get(
    applicationPaths.devices.config.getDeviceConfig(id)
  );
  return response.data;
});

export const requestDeviceConfig = buildAsyncThunk('devices/requestDeviceConfig', async (id: number) => {
  const response: AxiosResponse = await httpClient.post(applicationPaths.devices.config.getDeviceConfig(id));
  return response.data;
});

export const getDeviceLogs = buildAsyncThunk('devices/getDeviceLogs', async (id: number) => {
  const response: AxiosResponse<DeviceLog[]> = await httpClient.get(applicationPaths.devices.logs.getDeviceLogs(id));
  return response.data;
});

export interface DownloadDeviceLogThunk {
  deviceId: number;
  logId: number;
}

export const downloadDeviceLogs = buildAsyncThunk(
  'devices/downloadDeviceLog',
  async ({ deviceId, logId }: DownloadDeviceLogThunk) => {
    const response: AxiosResponse<DownloadDeviceLogsEntry[]> = await httpClient.get(
      applicationPaths.devices.logs.downloadDeviceLog(deviceId, logId)
    );
    return response.data;
  }
);

export interface FetchCommandsThunk {
  deviceId: number;
  params: {
    ascending: boolean | null;
    orderBy: string | null;
    from: string;
    to: string;
    status: DeviceCommandStatus;
    commandType?: string;
    pageSize: number;
    currentPage: number;
  };
}

export const fetchCommands = buildAsyncThunk('devices/getCommands', async (thunkData: FetchCommandsThunk) => {
  const response: AxiosResponse = await httpClient.get(applicationPaths.devices.fetchCommands(thunkData.deviceId), {
    params: thunkData.params,
  });
  return response.data;
});

export interface ExportCommandsThunk {
  deviceId: number;
  params: {
    from: string;
    to: string;
    status: DeviceCommandStatus;
    commandType?: string;
    decodingEnabled: boolean;
  };
}

export const exportCommands = buildAsyncThunk('devices/exportCommands', async (thunkData: ExportCommandsThunk) => {
  const response: AxiosResponse = await httpClient.get(applicationPaths.devices.exportCommands(thunkData.deviceId), {
    params: thunkData.params,
  });
  return getFileFromResponse(response);
});

export interface CancelAllDeviceCommandsThunk {
  siteId: number;
  params: {
    from: string;
    to: string;
    status: DeviceCommandStatus;
    commandType?: string;
  };
}

export const cancelAllDeviceCommands = buildAsyncThunk(
  'devices/cancelAllDeviceCommands',
  async (thunkData: CancelAllDeviceCommandsThunk) => {
    const response: AxiosResponse = await httpClient.delete(
      applicationPaths.devices.cancelAllDeviceCommands(thunkData.siteId),
      { data: { ...thunkData.params } }
    );
    return response.data;
  }
);

export interface CancelOneDeviceCommandThunk {
  deviceId: number;
  deviceCommandId: number;
}

export const cancelOneDeviceCommand = buildAsyncThunk(
  'devices/cancelOneDeviceCommand',
  async (thunkData: CancelOneDeviceCommandThunk) => {
    const response: AxiosResponse = await httpClient.delete(
      applicationPaths.devices.cancelOneDeviceCommand(thunkData.deviceId, thunkData.deviceCommandId)
    );
    return response.data;
  }
);

export const exportConfiguration = buildAsyncThunk('devices/exportConfiguration', async (id: number) => {
  const response: AxiosResponse = await httpClient.get(applicationPaths.devices.config.exportConfig(id), {
    responseType: 'blob',
  });
  return getFileFromResponse(response);
});

export interface ExportDevicesThunk {
  selectType: DeviceAssignmentFilterTypes;
  searchIn: DevicesSearchInFilterTypes;
  search: string;
  accountId?: number;
}

export const exportDevices = buildAsyncThunk('devices/exportDevices', async (thunkData: ExportDevicesThunk) => {
  const response: AxiosResponse = await httpClient.get(applicationPaths.devices.exportDevices(), { params: thunkData });
  return getFileFromResponse(response);
});
