import { createAppConstants } from '@hwm/ui-lib';

export const inputErrorBorder = '2px solid red';

export const styleConstants = createAppConstants(
  {},
  {
    tableScrollWidth: 12,
    borderRadius: '8px',
    colors: {
      black: '#000000',
      darkBlue: '#243259',
      green: '#18c474',
      grey: '#6a7082',
      grey2: '#E6E8F2',
      inputBorder: '#CDC9C9',
      lightGrey: '#F2F2F2',
      orange: '#dc8f15',
      primaryColor: '#228ec2',
      red: '#c2224f',
      redSecondary: '#D1234D',
      secondaryColor: '#596794',
      disabledBackground: '#F0F1F5',
      violet: '#6574a0',
      warningColor: '#DF7C1F',
      white: '#FFF',
      fontDefaultColor: 'rgba(0, 0, 0, 0.87)',
      fontDarkBlue: '#353E53',
      sites: {
        quiet: '#F2C400',
        quietHighlighted: '#F8BDBD',
        alarm: '#F40016',
      },
    },
  }
);
