import { applicationPaths } from '@core/http/applicationPaths';
import { httpClient } from '@core/http/httpClient';
import { AccountFeatureFlagModel } from '@hwm/ui-lib';
import { UserMainAccountModel } from '@models/api/models';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

export const getUserProfile = createAsyncThunk(`userProfile/getUserProfile`, async () => {
  const response: AxiosResponse<UserMainAccountModel> = await httpClient.get(applicationPaths.accounts.profile());

  return response.data;
});

export const getUserPermissions = createAsyncThunk(`userProfile/getUserPermissions`, async () => {
  const response: AxiosResponse<string[]> = await httpClient.get(applicationPaths.accounts.permissions());

  return response.data;
});

export const getTopLvlFeatureFlags = createAsyncThunk(`userProfile/getTopLvlFeatureFlags`, async () => {
  const response: AxiosResponse<AccountFeatureFlagModel[]> = await httpClient.get(
    applicationPaths.features.accountFeatureFlags()
  );

  return response.data;
});

export const userProfileThunks = {
  getUserProfile,
  getUserPermissions,
  getTopLvlFeatureFlags,
};
