import store from '@core/store/store';
import { environment } from '../../environment';
import { createAppHttpClient } from '@hwm/ui-lib';
import axios from 'axios';

export const httpClient = createAppHttpClient(
  () => store.getState(),
  {
    sessionCookieDomain: environment.sessionCookieDomain,
    sessionCookieIsSecure: environment.sessionCookieSecure,
    sessionIdleTimeout: environment.sessionCookieIdleTimeout,
  },
  {
    baseURL: environment.apiUrl,
  }
);

export default httpClient;

//

const basicClient = axios.create({
  headers: {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
  },
  baseURL: environment.apiUrl,
});
export const simpleClient = basicClient;
