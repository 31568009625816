import { buildAsyncThunk } from '../utils';
import { applicationPaths } from '@core/http/applicationPaths';
import { AxiosResponse } from 'axios';
import { Application, ApplicationRole, CreateRoleRequest, UpdateRoleAccessLevelRequest } from '@models/api/models';
import httpClient from '@core/http/httpClient';
import { unwrapResult } from '@reduxjs/toolkit';
import { omitAbortSignalConfig } from '@hwm/ui-lib';

export const fetchApplicationsList = buildAsyncThunk('roles/applicationsList', async (noArgs: void) => {
  const response: AxiosResponse<Application[]> = await httpClient.get(applicationPaths.roles.applicationList());
  return response.data;
});

export const fetchRolesList = buildAsyncThunk('roles/rolesList', async (id: number) => {
  const response: AxiosResponse<ApplicationRole> = await httpClient.get(applicationPaths.roles.rolesList(id));
  return response.data;
});

export const fetchAssignableRolesList = buildAsyncThunk('roles/assignableRolesList', async (id: number) => {
  const response: AxiosResponse<ApplicationRole> = await httpClient.get(
    applicationPaths.roles.assignableRolesList(id),
    { signal: omitAbortSignalConfig }
  );
  return response.data;
});

export const fetchApplicationPermissions = buildAsyncThunk('roles/applicationPermissions', async (id: number) => {
  const response: AxiosResponse = await httpClient.get(applicationPaths.roles.appPermissionsList(id));
  return response.data;
});

export interface CreateRoleThunk {
  applicationId: number;
  roleData: CreateRoleRequest;
}

export const createRole = buildAsyncThunk('roles/createRole', async (data: CreateRoleThunk) => {
  const response: AxiosResponse = await httpClient.post(
    applicationPaths.roles.rolesList(data.applicationId),
    data.roleData
  );
  return response.data;
});

export interface DeleteRoleThunk {
  applicationId: number;
  roleId: number;
}

export const deleteRole = buildAsyncThunk('roles/deleteRole', async (data: DeleteRoleThunk) => {
  await httpClient.delete(applicationPaths.roles.deleteRole(data.applicationId, data.roleId));
});

export interface EditRoleNameThunk {
  applicationId: number;
  roleId: number;
  requestData: {
    name: string;
  };
}

export const editRoleName = buildAsyncThunk('roles/editRoleName', async (data: EditRoleNameThunk) => {
  const response: AxiosResponse<ApplicationRole> = await httpClient.patch(
    applicationPaths.roles.roleName(data.applicationId, data.roleId),
    data.requestData
  );
  return response.data;
});

export interface EditRoleAccessLevelThunk {
  applicationId: number;
  roleId: number;
  requestData: UpdateRoleAccessLevelRequest;
}

export const editRoleAccesss = buildAsyncThunk('roles/editRoleAccess', async (data: EditRoleAccessLevelThunk) => {
  const response: AxiosResponse<ApplicationRole> = await httpClient.patch(
    applicationPaths.roles.roleAccessLevel(data.applicationId, data.roleId),
    data.requestData
  );
  return response.data;
});

export interface EditRolePermissionsThunk {
  applicationId: number;
  roleId: number;
  requestData: {
    permissions: number[];
  };
}

export const editRolePermissions = buildAsyncThunk(
  'roles/editRolePermissions',
  async (data: EditRolePermissionsThunk) => {
    const response: AxiosResponse<ApplicationRole> = await httpClient.patch(
      applicationPaths.roles.rolePermissions(data.applicationId, data.roleId),
      data.requestData
    );
    return response.data;
  }
);

export interface EditRoleThunk {
  applicationId: number;
  roleId: number;
  editPermissions: boolean;
  editName: boolean;
  editPermissionsRequestData: {
    permissions: number[];
  };
  editNameRequestData: {
    name: string;
  };
}

export const editRole = buildAsyncThunk('roles/editRole', async (data: EditRoleThunk, store) => {
  const editPermissionsData: EditRolePermissionsThunk = {
    applicationId: data.applicationId,
    roleId: data.roleId,
    requestData: data.editPermissionsRequestData,
  };
  const editRoleNameData: EditRoleNameThunk = {
    applicationId: data.applicationId,
    roleId: data.roleId,
    requestData: data.editNameRequestData,
  };
  if (data.editName) {
    await store.dispatch(editRoleName(editRoleNameData)).then(unwrapResult);
  }
  if (data.editPermissions) {
    await store.dispatch(editRolePermissions(editPermissionsData)).then(unwrapResult);
  }
});
