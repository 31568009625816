import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchUsersList, fetchUserDetails, createUser } from './users.thunk';
import { UserCollection, UserDetails, UserListItem } from '@models/api/models';

export const usersSlice = createSlice({
  name: 'usersData',
  initialState: {
    userList: {
      list: [] as UserListItem[],
      total: 0,
      currentPage: 0,
      pageSize: 0,
    },
    userDetails: {
      details: {} as UserDetails,
    },
    userSearchParams: {
      searchInputData: '',
    },
  },
  reducers: {
    setUserSearchParams: (state, action: PayloadAction<string>) => {
      state.userSearchParams.searchInputData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsersList.fulfilled, (state, action: PayloadAction<UserCollection>) => {
        state.userList.list = action.payload.items;
        state.userList.total = action.payload.itemsTotal;
        state.userList.currentPage = action.payload.currentPage;
        state.userList.pageSize = action.payload.pageSize;
      })
      .addCase(fetchUserDetails.fulfilled, (state, action: PayloadAction<UserDetails>) => {
        state.userDetails.details = action.payload;
      });
  },
});

export type UsersState = ReturnType<typeof usersSlice.reducer>;
export const { setUserSearchParams } = usersSlice.actions;

export default usersSlice;
