/**
 * Datagate 3 API Doc
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ScheduleRecurrency } from './scheduleRecurrency';

export enum FleetReportDeliveryType {
  Emails = 'Emails',
  SendToMe = 'SendToMe',
}

export interface GenerateFleetReportRequest {
  selectAll?: boolean;
  channelSettings?: boolean;
  meterReadings?: boolean;
  time?: Date;
  callInSettings?: boolean;
  installTimes?: boolean;
  callInReport?: boolean;
  days?: number;
  includeToday?: boolean;
  locations?: boolean;
  parameterReport?: boolean;
  flatlines?: boolean;
  overReadingInput?: number;
  overReading?: boolean;
  negativeValues?: boolean;
  zeroValues?: boolean;
  period?: number;
  alarmSettings?: boolean;
  onlyShowSitesInAlarm?: boolean;
  sensorSerialNumber?: boolean;
  lastBatteryReplacement?: boolean;
  nextBatteryReplacement?: boolean;
  maintenanceNotes?: boolean;
  externalBattery?: boolean;
  technologyType?: boolean;
  pipeSize?: boolean;
  pipeMaterial?: boolean;
  valveLidModified?: boolean;
  serviceDate?: boolean;
  warrantyExpiryDate?: boolean;
  equipmentServiceCost?: boolean;
  fileName?: string;
  delivery?: FleetReportDeliveryType;
  emails?: string[];
  scheduleEnabled?: boolean;
  scheduleRecurrency?: ScheduleRecurrency;
  scheduleInterval?: number;
  scheduleDay?: number;
  scheduleWeekday?: string;
  scheduledReportDate?: Date;
  scheduleWeek?: string;
  favouriteListId?: number;
  simExpiryDate?: boolean;
}
