import { GisUploadOption } from '@models/enums/GisUploadOption';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GisManagementProperties } from '../../../models/gis/gisManagementProperties';

export const gisManagementSlice = createSlice({
  name: 'gisManagement',
  initialState: {
    returnUrl: null,
    uploadOption: GisUploadOption.Any,
  } as GisManagementProperties,
  reducers: {
    setReturnUrl(state, action: PayloadAction<string>) {
      state.returnUrl = action.payload;
    },
    setUploadOption(state, action: PayloadAction<GisUploadOption>) {
      state.uploadOption = action.payload;
    },
  },
});

export type GisManagementState = ReturnType<typeof gisManagementSlice.reducer>;
export const { setReturnUrl, setUploadOption } = gisManagementSlice.actions;
