import { AxiosResponse } from 'axios';
import { buildAsyncThunk } from '../utils';
import httpClient from '@core/http/httpClient';
import { SitesStatistics } from '@models/api/models';
import { applicationPaths } from '@core/http/applicationPaths';

interface FetchStatsThunk {
  accountId?: number;
}

export const fetchStats = buildAsyncThunk('statistics/fetch', async (params: FetchStatsThunk) => {
  const response: AxiosResponse<SitesStatistics> = await httpClient.get(applicationPaths.sites.sitesStats, { params });
  return response.data;
});
