/**
 * Datagate 3 API Doc
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type PipeType = 'WasteFoulPipe' | 'WasteSurfacePipe' | 'CleanPipe';

export const PipeType = {
  WasteFoulPipe: 'WasteFoulPipe' as PipeType,
  WasteSurfacePipe: 'WasteSurfacePipe' as PipeType,
  CleanPipe: 'CleanPipe' as PipeType,
};
