import { FavouriteSitesList } from '@models/api/models';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchFavouriteLists, removeListFromFavouriteList } from './favouriteLists.thunks';

export const defaultFavouriteSiteList: FavouriteSitesList = { id: 0, name: 'Empty' };
export const favouriteListsSlice = createSlice({
  name: 'favouriteLists',
  initialState: {
    activeList: defaultFavouriteSiteList,
    favouriteLists: [] as FavouriteSitesList[],
  },
  reducers: {
    setCurrentListId(state, action: PayloadAction<FavouriteSitesList>) {
      state.activeList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFavouriteLists.fulfilled, (state, action: PayloadAction<FavouriteSitesList[]>) => {
      state.favouriteLists = [defaultFavouriteSiteList, ...action.payload];
    });
    builder.addCase(removeListFromFavouriteList.fulfilled, (state) => {
      state.favouriteLists = state.favouriteLists.filter((list) => list.id !== state.activeList.id);
      state.activeList = defaultFavouriteSiteList;
    });
  },
});

export type FavouriteListsState = ReturnType<typeof favouriteListsSlice.reducer>;
export const { setCurrentListId } = favouriteListsSlice.actions;
