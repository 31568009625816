import { applicationPaths } from '@core/http/applicationPaths';
import httpClient from '@core/http/httpClient';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { buildAsyncThunk } from '../utils';

export const getAssociatedAccounts = createAsyncThunk('Associations/getAssociatedAccounts', async (siteId: number) => {
  const response: AxiosResponse = await httpClient.get(applicationPaths.sites.associations.getAccounts(siteId));
  return response.data;
});

export interface GetAccountsToAssociateThunk {
  id: number;
  name?: string;
}

export const getAccountsToAssociate = createAsyncThunk(
  'Associations/getAccountsToAssociate',
  async (data: GetAccountsToAssociateThunk) => {
    let params = {};
    if (data.name) {
      params = {
        name: data.name,
      };
    }
    const response: AxiosResponse = await httpClient.get(
      applicationPaths.sites.associations.getAvailableAssociations(data.id),
      {
        params: params,
      }
    );
    return response.data;
  }
);

export interface CreateSiteAssociationThunk {
  siteId: number;
  accountId: number;
}

export const createAccountAssociation = buildAsyncThunk(
  'Associations/createAccountAssociation',
  async (data: CreateSiteAssociationThunk) => {
    const response: AxiosResponse = await httpClient.post(
      applicationPaths.sites.associations.createAssociation(data.siteId, data.accountId)
    );
    return response.data;
  }
);

export interface DeleteAssociatedAccountThunk {
  siteId: number;
  accountId: number;
}

export const deleteAssociatedAccount = buildAsyncThunk(
  'Associations/deleteAssociatedAccount',
  async (data: DeleteAssociatedAccountThunk) => {
    const response: AxiosResponse = await httpClient.delete(
      applicationPaths.sites.associations.deleteAssociation(data.siteId, data.accountId)
    );
    return response.data;
  }
);
