/**
 * Datagate 3 API Doc
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type BulkEditOptions =
  | 'AssociateWithAccount'
  | 'ChangeOwner'
  | 'DisassociateWithAccount'
  | 'DisassociateAllAccounts'
  | 'RemoveDeviceOff'
  | 'RecycleSites';

export const BulkEditOptions = {
  AssociateWithAccount: 'AssociateWithAccount' as BulkEditOptions,
  ChangeOwner: 'ChangeOwner' as BulkEditOptions,
  DisassociateWithAccount: 'DisassociateWithAccount' as BulkEditOptions,
  DisassociateWithAccounts: 'DisassociateWithAccounts' as BulkEditOptions,
  DisassociateAllAccounts: 'DisassociateAllAccounts' as BulkEditOptions,
  RemoveDeviceOff: 'RemoveDeviceOff' as BulkEditOptions,
  RecycleSites: 'RecycleSites' as BulkEditOptions,
};
