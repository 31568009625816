/**
 * Datagate 3 API Doc
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type ImportStatus =
  | 'Uploaded'
  | 'ParsingProperties'
  | 'ParsingPropertiesFailed'
  | 'ParsedProperties'
  | 'ParsingMaterialCodes'
  | 'ParsingMaterialCodesFailed'
  | 'ParsedMaterialCodes'
  | 'Validating'
  | 'ValidationFailed'
  | 'Validated'
  | 'Importing'
  | 'ImportFailed'
  | 'Imported'
  | 'ImportedWithWarning';

export const ImportStatus = {
  Uploaded: 'Uploaded' as ImportStatus,
  ParsingProperties: 'ParsingProperties' as ImportStatus,
  ParsingPropertiesFailed: 'ParsingPropertiesFailed' as ImportStatus,
  ParsedProperties: 'ParsedProperties' as ImportStatus,
  ParsingMaterialCodes: 'ParsingMaterialCodes' as ImportStatus,
  ParsingMaterialCodesFailed: 'ParsingMaterialCodesFailed' as ImportStatus,
  ParsedMaterialCodes: 'ParsedMaterialCodes' as ImportStatus,
  Validating: 'Validating' as ImportStatus,
  ValidationFailed: 'ValidationFailed' as ImportStatus,
  Validated: 'Validated' as ImportStatus,
  Importing: 'Importing' as ImportStatus,
  ImportFailed: 'ImportFailed' as ImportStatus,
  Imported: 'Imported' as ImportStatus,
  ImportedWithWarning: 'ImportedWithWarning' as ImportStatus,
};
