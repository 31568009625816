import { Device, DeviceDetails } from '@models/api/models';
import { PagedResponse } from '@models/interfaces/PagedResponse';
import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchDeviceDetails, fetchDevices } from './devices.thunks';

export const devicesListAdapter = createEntityAdapter({
  selectId: (img: Device) => img.id,
});

export const devicesSlice = createSlice({
  name: 'devices',
  initialState: {
    devicesListEntity: devicesListAdapter.getInitialState(),
    deviceSmsNumber: '',
    returnRoute: '',
  },
  reducers: {
    setDeviceDetailsReturnRoute(state, action: PayloadAction<string>) {
      state.returnRoute = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDevices.fulfilled, (state, action: PayloadAction<PagedResponse<Device>>) => {
      devicesListAdapter.setAll(state.devicesListEntity, action.payload.items);
    });
    builder.addCase(fetchDevices.rejected, (state) => {
      devicesListAdapter.setAll(state.devicesListEntity, []);
    });
    builder.addCase(fetchDeviceDetails.fulfilled, (state, action: PayloadAction<DeviceDetails>) => {
      state.deviceSmsNumber = action.payload.smsNumber;
    });
  },
});

export type DevicesState = ReturnType<typeof devicesSlice.reducer>;

export const { setDeviceDetailsReturnRoute } = devicesSlice.actions;
