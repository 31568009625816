import { t } from '@lingui/macro';
import DescriptionIcon from '@material-ui/icons/Description';
import DevicesIcon from '@material-ui/icons/Devices';
import GroupIcon from '@material-ui/icons/Group';
import SettingsIcon from '@material-ui/icons/Settings';
import TuneIcon from '@material-ui/icons/Tune';
import { AccountsIcon, CommandsIcon, ReceivedAlarmsIcon } from '@shared/components/Icons/Icons';
import { styleConstants } from '@styles/constants';
import { ActionMenuItem } from 'shared/components/ActionsMenu/ActionsMenu';
import HistoryIcon from '@shared/components/Icons/HistoryIcon';

export const actionIconStyles = {
  width: '40px',
  height: '38px',
  borderRadius: '5px',
  padding: '7px',
  marginRight: '10px',
  background: styleConstants.colors.blue,
  fill: '#fff',
};
export interface CreateEditAccountHeaderActionsConfigProps {
  onSitesClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onAccountsClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onPreferencesClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onUsersClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onDevicesClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onAlarmsClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onReportingClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onAppSettingsClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onSitesHistoryClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const CreateEditAccountHeaderActionsConfig = (
  args: CreateEditAccountHeaderActionsConfigProps,
  canAccessUsersFromActionsMenu: boolean
) =>
  [
    {
      absolute: true,
      slug: '/dashboard',
      label: t`Sites`,
      icon: <CommandsIcon style={actionIconStyles} />,
      onBtnClick: args.onSitesClick,
    },
    {
      label: t`Users`,
      slug: 'users',
      onBtnClick: args.onUsersClick,
      icon: <GroupIcon style={actionIconStyles} />,
      disabled: !canAccessUsersFromActionsMenu,
    },
    {
      absolute: true,
      slug: '/accounts',
      label: t`Accounts`,
      icon: <AccountsIcon style={actionIconStyles} />,
      onBtnClick: args.onAccountsClick,
    },
    {
      label: t`Devices`,
      slug: 'devices',
      icon: <DevicesIcon style={actionIconStyles} />,
      onBtnClick: args.onDevicesClick,
    },
    {
      slug: 'alarms',
      label: t`Alarms`,
      icon: <ReceivedAlarmsIcon style={actionIconStyles} />,
      onBtnClick: args.onAlarmsClick,
    },
    {
      slug: 'reporting',
      label: t`Reporting`,
      icon: <DescriptionIcon style={actionIconStyles} />,
      onBtnClick: args.onReportingClick,
    },
    {
      slug: 'appSettings',
      label: t`App Settings`,
      icon: <SettingsIcon style={actionIconStyles} />,
      onBtnClick: args.onAppSettingsClick,
    },
    {
      label: t`Sites History`,
      icon: <HistoryIcon style={actionIconStyles} />,
      onBtnClick: args.onSitesHistoryClick,
    },
    {
      label: t`Unit Preferences`,
      icon: <TuneIcon style={actionIconStyles} />,
      onBtnClick: args.onPreferencesClick,
    },
  ] as ActionMenuItem[];
