import { UserApplicationRole, AccountModel, UserMainAccountModel } from '@models/api/models';
import {
  BaseAccountStoreModel,
  CountriesType,
  accountsStoreName,
  handleFulfilledAction,
  handleMultipleLoadingActions,
} from '@hwm/ui-lib';
import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { accountsThunks } from './accounts.thunks';
import { actionInterceptor } from '../middleware/actionsInterceptorMiddleware';
import { userProfileThunks } from './user-profile.thunks';

export interface AccountStoreState extends BaseAccountStoreModel<AccountModel, UserMainAccountModel> {
  roles: UserApplicationRole[];
  accountsListContext: AccountModel;
  previouslyVisitedAccount: AccountModel;
  isAlarmForwardingEnabled: boolean;
}

export const accountsAdapter = createEntityAdapter({
  selectId: (acc: AccountModel) => acc.id || acc['accountId'],
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const initialState: AccountStoreState = {
  error: null,
  hierarchy: [],
  loadingPending: 0,
  selectedAccount: null,
  subaccounts: accountsAdapter.getInitialState(),
  userRole: '',
  userMainAccount: null,
  includeArchived: false,
  roles: [],
  accountsListContext: null,
  previouslyVisitedAccount: null,
  isAlarmForwardingEnabled: false,
};

const slice = createSlice({
  name: accountsStoreName,
  initialState,
  reducers: {
    setSelectedAccount(state, action: PayloadAction<{ account: AccountModel }>) {
      state.selectedAccount = action.payload.account;
    },
    setHierarchy(state, action: PayloadAction<{ hierarchy: AccountModel[] }>) {
      state.hierarchy = action.payload.hierarchy;
    },
    accountsReceived(state, action: PayloadAction<{ accounts: AccountModel[] }>) {
      accountsAdapter.setAll(state.subaccounts, action.payload.accounts);
    },
    setUserMainAccount: (state, { payload }: { payload: UserMainAccountModel }) => {
      state.userMainAccount = payload;
    },
    setUserRole: (state, { payload }: { payload: string }) => {
      state.userRole = payload;
    },
    setIncludeArchived: (state, { payload }: { payload: boolean }) => {
      state.includeArchived = payload;
    },
    setRoles: (state, action: PayloadAction<UserApplicationRole[]>) => {
      state.roles = action.payload;
    },
    setPreviouslyVisitedAccount(state, action) {
      state.previouslyVisitedAccount = action.payload ?? {};
    },
    setAccountsListContext(state, action: PayloadAction<AccountModel>) {
      state.accountsListContext = action.payload;
    },
    setOwnAccountAlarmForwarding(state, action: PayloadAction<boolean>) {
      state.isAlarmForwardingEnabled = action.payload;
    },
    changeLanguage(state, action: PayloadAction<CountriesType>) {
      if (!state.userMainAccount) return;
      state.userMainAccount.languageCode = action.payload;
    },
  },
  extraReducers: (builder) => {
    handleMultipleLoadingActions([accountsThunks.fetchSubaccounts, accountsThunks.fetchById], builder);
    handleFulfilledAction(accountsThunks.fetchSubaccounts, builder, (state, payload) => {
      accountsAdapter.setAll(state.subaccounts, payload);
    });
    handleFulfilledAction(accountsThunks.fetchById, builder, (state, payload) => {
      state.selectedAccount = payload;
      state.hierarchy = [...(payload?.ancestors ?? []), payload];
    });
  },
});

export const accountsReducer = {
  slice,
};

actionInterceptor.addActionsCallback<typeof accountsThunks.fetchById.fulfilled>({
  actionTypes: [accountsThunks.fetchById.fulfilled.type],
  callback: ({ dispatch, action }) => {
    const accId = action.payload?.['id'] ?? action.payload?.['accountId'];

    dispatch(accountsThunks.fetchSubaccounts(accId));
  },
});

actionInterceptor.addActionsCallback<typeof userProfileThunks.getUserProfile.fulfilled>({
  actionTypes: [userProfileThunks.getUserProfile.fulfilled.type],
  callback: ({ dispatch, action }) => {
    const accountId = new URLSearchParams(window.location.search).get('accountId');

    if (accountId) {
      dispatch(accountsThunks.fetchById(Number(accountId)));
    } else {
      dispatch(
        slice.actions.setSelectedAccount({
          account: action.payload,
        })
      );
      dispatch(
        slice.actions.setHierarchy({
          hierarchy: [action.payload],
        })
      );
    }
  },
});
