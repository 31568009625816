import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUnitPreferences, updateUnitPreferences } from './preferences.thunk';

export const preferencesSlice = createSlice({
  name: 'preferences',
  initialState: {
    accId: null as number,
    units: null as Record<string, string>,
    loading: 0,
  },
  reducers: {
    setUnitsToNull: (state) => (state.units = null),
  },
  extraReducers: (builder) => {
    builder.addCase(getUnitPreferences.pending, (state) => {
      state.loading = state.loading + 1;
    });
    builder.addCase(
      getUnitPreferences.fulfilled,
      (state, action: PayloadAction<{ accId: number; data: Record<string, string> }>) => {
        state.accId = action.payload.accId;
        state.units = action.payload.data;
        state.loading = Math.max(state.loading - 1, 0);
      }
    );
    builder.addCase(getUnitPreferences.rejected, (state) => {
      state.loading = Math.max(state.loading - 1, 0);
    });
    builder.addCase(updateUnitPreferences.pending, (state) => {
      state.loading = state.loading + 1;
    });
    builder.addCase(updateUnitPreferences.fulfilled, (state) => {
      state.loading = Math.max(state.loading - 1, 0);
    });
    builder.addCase(updateUnitPreferences.rejected, (state) => {
      state.loading = Math.max(state.loading - 1, 0);
    });
  },
});

export type UnitPreferencesState = ReturnType<typeof preferencesSlice.reducer>;
