import { actionInterceptor } from '../middleware/actionsInterceptorMiddleware';
import { loadLocale } from '@core/locales/localeLoader';
import { fetchCountriesDictionary } from '../dictionaries/dictionaries.thunk';
import {
  AccountFeatureFlagModel,
  BaseUserProfileStoreModel,
  OidcModel,
  handleFulfilledAction,
  handleLoadingAction,
  userProfileStoreName,
} from '@hwm/ui-lib';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { userProfileThunks } from './user-profile.thunks';
import { i18n } from '@lingui/core';
import { accountsReducer } from './accounts';
import { es, en, de, fr, fa, hr, ko, tr, vi, zh, el, ro, it, sv, fi, nb, da, pl, lt } from 'make-plural/plurals';

export interface UserProfileStoreState extends BaseUserProfileStoreModel {}

const initialState: UserProfileStoreState = {
  username: '',
  userId: null,
  type: null,
  language: 'en-GB',
  permissions: undefined,
  featureFlags: undefined,
  oidc: {
    idToken: '',
    isLoadingUser: true,
    user: null as any,
    accessToken: '',
  },
  error: null,
  loadingPending: 0,
};

const slice = createSlice({
  name: userProfileStoreName,
  initialState,
  reducers: {
    setName: (state, action: PayloadAction<string>) => {
      state.username = action.payload;
    },
    setOidcUser: (state, action: PayloadAction<Partial<OidcModel>>) => {
      state.oidc = { ...state.oidc, ...action.payload };
    },
    setFeatureFlags: (state, action: PayloadAction<AccountFeatureFlagModel[]>) => {
      state.featureFlags = action.payload;
    },
  },
  extraReducers: (builder) => {
    handleLoadingAction(userProfileThunks.getUserProfile, builder);
    handleLoadingAction(userProfileThunks.getUserPermissions, builder);
    handleLoadingAction(userProfileThunks.getTopLvlFeatureFlags, builder);
    handleFulfilledAction(userProfileThunks.getUserProfile, builder, (state, payload) => {
      state.username = payload.name;
      state.language = payload.languageCode;
      state.userId = payload?.userId;
      state.type = payload?.type?.toString();
    });
    handleFulfilledAction(userProfileThunks.getUserPermissions, builder, (state, payload) => {
      state.permissions = payload;
    });
    handleFulfilledAction(userProfileThunks.getTopLvlFeatureFlags, builder, (state, payload) => {
      state.featureFlags = payload;
    });
  },
});

export const userProfileReducer = {
  slice,
};

actionInterceptor.addActionsCallback<any>({
  actionTypes: [userProfileReducer.slice.actions.setOidcUser.type],
  callback: ({ dispatch, state }) => {
    if (state[userProfileStoreName].loadingPending <= 0) {
      if (!state[userProfileStoreName].username) {
        dispatch(userProfileThunks.getUserProfile());
      }
      if (!state[userProfileStoreName].permissions) {
        dispatch(userProfileThunks.getUserPermissions());
      }
      if (!state[userProfileStoreName].featureFlags) {
        dispatch(userProfileThunks.getTopLvlFeatureFlags());
      }
    }
  },
});

actionInterceptor.addActionsCallback<typeof userProfileThunks.getUserProfile.fulfilled>({
  actionTypes: [userProfileThunks.getUserProfile.fulfilled.type],
  callback: ({ action: { payload }, dispatch }) => {
    dispatch(accountsReducer.slice.actions.setUserMainAccount(payload ?? null));
    //
    dispatch(fetchCountriesDictionary());
  },
});

actionInterceptor.addActionsCallback<any>({
  actionTypes: [userProfileThunks.getUserProfile.fulfilled.type],
  callback: async ({ action }) => {
    const lang = action.payload.languageCode ?? 'en-GB';
    const locales = await loadLocale(lang);

    i18n.loadLocaleData({
      'de-DE': { plurals: de },
      'en-GB': { plurals: en },
      'en-US': { plurals: en },
      'es-ES': { plurals: es },
      'fa-IR': { plurals: fa },
      'fr-FR': { plurals: fr },
      'hr-HR': { plurals: hr },
      'ko-KR': { plurals: ko },
      'tr-TR': { plurals: tr },
      'vi-VN': { plurals: vi },
      'zh-SG': { plurals: zh },
      'zh-TW': { plurals: zh },
      'el-GR': { plurals: el },
      'ro-RO': { plurals: ro },
      'it-IT': { plurals: it },
      'sv-SE': { plurals: sv },
      'fi-FI': { plurals: fi },
      'nb-NO': { plurals: nb },
      'da-DK': { plurals: da },
      'pl-PL': { plurals: pl },
      'lt-LT': { plurals: lt },
    });

    i18n.load(lang, locales?.messages ?? {});
    i18n.activate(lang);
  },
});
