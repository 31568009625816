/**
 * Datagate 3 API Doc
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { t } from "@lingui/macro";

export type ScheduledReportType = 'NewLeaksReport';
//those types do not exists at all in report scheduler. For now we got just NewLeaksReport, and API is not recognizing ALL so its filtering reporttype == All
export const ScheduledReportOptions:Record<ScheduledReportType, string> = {
    NewLeaksReport: t`NewLeaksReport`,
};

export interface ScheduledReport { 
    id?: number;
    reportType?: string;
    accountName?: string;
    reportCreationDate?: Date;
    createdBy?: string;
    reportFrequency?: string;
    reportSettings?: string;
    reportDestination?: string;
    exportFileName?: string;
}

