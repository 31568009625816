/**
 * Datagate 3 API Doc
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type SonicSensVersion = 'SonicSens2' | 'SonicSens3';

export const SonicSensVersion = {
  SonicSens2: 'SonicSens2' as SonicSensVersion,
  SonicSens3: 'SonicSens3' as SonicSensVersion,
};
