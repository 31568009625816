import { SitesStatistics } from '@models/api/models';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStats } from './statistics.thunks';

export const statisticsSlice = createSlice({
  name: 'statistics',
  initialState: {
    expired: 0,
    inAlarm: 0,
    quietSites: 0,
    total: 0,
    active: 0,
  } as SitesStatistics,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchStats.fulfilled, (state, action: PayloadAction<SitesStatistics>) => {
      state.expired = action.payload.expired;
      state.inAlarm = action.payload.inAlarm;
      state.total = action.payload.total;
      state.quietSites = action.payload.quietSites;
      state.active = action.payload.active;
    });
  },
});

export type StatisticsState = ReturnType<typeof statisticsSlice.reducer>;
