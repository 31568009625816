/**
 * Identity Server 3 API Doc
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type AccessLevel = 'External' | 'Internal' | 'Admin';

export const AccessLevel = {
  External: 'External' as AccessLevel,
  Internal: 'Internal' as AccessLevel,
  Admin: 'Admin' as AccessLevel,
};
