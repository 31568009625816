import CustomObject from '@models/interfaces/CustomObject';
import { TranslatableDictionaries, translatedDictionaries } from '@shared/dictionaries';

const translateDictionaryItem = (name: string, dictionaryType: TranslatableDictionaries): string => {
  let translatedItem: string;
  let dictionary: CustomObject = getDictionary(dictionaryType);

  if (dictionary) {
    translatedItem = !!dictionary[name] ? dictionary[name] : name;
  } else {
    translatedItem = name;
  }
  return translatedItem;
};

const getDictionary = (dictionaryType: TranslatableDictionaries): CustomObject => {
  let dictionary: CustomObject;
  switch (dictionaryType) {
    case TranslatableDictionaries.ChannelTypes:
      dictionary = translatedDictionaries.channelTypes();
      break;

    case TranslatableDictionaries.ConfigChannelTypes:
      dictionary = translatedDictionaries.configChannelTypes();
      break;

    case TranslatableDictionaries.AccountTypes:
      dictionary = translatedDictionaries.accountTypes();
      break;

    case TranslatableDictionaries.ErrorCodes:
      dictionary = translatedDictionaries.errorCodes();
      break;
  }

  return dictionary;
};

const getDictionaryKeyFromValue = (translatedValue: string, dictionaryType: TranslatableDictionaries) => {
  let dictionaryKey: string = translatedValue;
  let dictionary: CustomObject = getDictionary(dictionaryType);
  if (!dictionary) {
    return dictionaryKey;
  }

  for (let key in dictionary) {
    if (dictionary[key] === translatedValue) {
      dictionaryKey = key;
      break;
    }
  }
  return dictionaryKey;
};

const translateChannelType = (unitChannelType: string): string =>
  translateDictionaryItem(unitChannelType, TranslatableDictionaries.ChannelTypes);

const translateConfigChannelType = (unitChannelType: string): string =>
  translateDictionaryItem(unitChannelType, TranslatableDictionaries.ConfigChannelTypes);

const translateAccountTypes = (unitChannelType: string): string =>
  translateDictionaryItem(unitChannelType, TranslatableDictionaries.AccountTypes);

const getOriginalChannelType = (translatedValue: string): string =>
  getDictionaryKeyFromValue(translatedValue, TranslatableDictionaries.ChannelTypes);

const translateErrorCode = (errorCode: string): string => {
  let message = translateDictionaryItem(errorCode, TranslatableDictionaries.ErrorCodes);
  const isTranslated = message !== errorCode;
  if (!isTranslated) {
    message = `Error has occured: ${message}`;
  }

  return message;
};

export const translate = {
  channelType: translateChannelType,
  configChannelType: translateConfigChannelType,
  accountType: translateAccountTypes,
  errorCode: translateErrorCode,
  originalChannelType: getOriginalChannelType,
};
