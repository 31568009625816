import {
  BulkOperationContext,
  SiteMapFilterTypes,
} from '@features/DashboardPage/components/map/SiteMapFilters/BaseSiteFilters';
import { MastSiteLocationInfo } from '@models/api/mastLocationInfo';
import {
  Device,
  DeviceDetails,
  GeoLocation,
  ListFilterTypes,
  SiteDetails,
  SiteLocation,
  SiteLocationInfo,
  SitesAssociationFilter,
  SitesList,
  SitesListItem,
  SitesListItemPagedResponse,
  SitesAndMastsOnMap,
} from '@models/api/models';
import { SiteCluster } from '@models/api/siteCluster';
import { SiteClustersList } from '@models/api/siteClustersList';
import FormMode from '@models/enums/FormMode';
import { PagedResponse } from '@models/interfaces/PagedResponse';
import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../rootReducer';
import {
  createSiteDetails,
  fetchClusters,
  fetchDevicesToLink,
  fetchNumberOfSelectedSites,
  fetchSiteDetails,
  fetchSitesForList,
  fetchSitesForMap,
  updateSiteDetails,
  updateSiteLocation,
} from './sites.thunk';

export interface ISitesListChunk {
  [key: string]: Array<SitesListItem>;
}

export interface ISitesList {
  chunks: ISitesListChunk;
  pageIsLoading: boolean;
  totalItems: number;
  pagesAreCleared: boolean;
  totalPages: number;
  currentPage: number;
}

export interface ActionStatus {
  actionFinished: boolean;
  error: boolean;
}

export interface UpdateSiteItemFavouriteStatus {
  id: number;
  isFavourite: boolean;
}

export interface SitePhotosStatistics {
  uploaded: number;
  pending: number;
}

export enum MapEditingState {
  Closed,
  Pending,
  Open,
}

export enum SitesSearchInFilterTypes {
  All = 'All',
  Address = 'Address',
  ID = 'ID',
  Serial = 'Serial',
  SMS = 'SMS',
}

export const MAX_NUM_OF_SITES_SELECT_ALL = 1000;

const devicesListToLinkAdapter = createEntityAdapter({
  selectId: (d: Device) => d.id,
});

export const siteListDefaultFiltersValues = {
  activeFilterBtn: ListFilterTypes.All,
  textFilter: '',
  searchIn: SitesSearchInFilterTypes.All,
  associationFilter: SitesAssociationFilter.Associated,
  currentPage: 1,
};

export const sitesSlice = createSlice({
  name: 'sites',
  initialState: {
    masts: [] as MastSiteLocationInfo[],
    sites: [] as MastSiteLocationInfo[],
    clusters: [] as SiteCluster[],
    textFilter: siteListDefaultFiltersValues.textFilter,
    associationFilter: siteListDefaultFiltersValues.associationFilter,
    activeFilterBtn: siteListDefaultFiltersValues.activeFilterBtn,
    showVirtualScroll: true,
    virtualScrollIsLoading: true,
    list: {
      chunks: null,
      pageIsLoading: false,
      totalItems: 0,
      pagesAreCleared: false,
      totalPages: 0,
      currentPage: siteListDefaultFiltersValues.currentPage,
    } as ISitesList,
    numberOfSelectedSites: 0,
    details: {} as SiteDetails,
    detailsLoadingStatus: {
      actionFinished: false,
      error: false,
    } as ActionStatus,
    sitePhotosStatistics: {
      uploaded: 0,
      pending: 0,
    },
    siteDetailsMode: FormMode.Readonly,
    editedSiteDetailsMarker: {} as SiteLocationInfo,
    isEditingSiteLocationOnMap: MapEditingState.Closed,
    devicesListToLinkEntity: devicesListToLinkAdapter.getInitialState(),
    searchIn: siteListDefaultFiltersValues.searchIn,
    table: {
      sites: [] as SitesListItem[],
      isTableView: false,
      actionOpenedInTableView: null as SiteMapFilterTypes,
      bulkOperationContext: null as BulkOperationContext,
    },
    maximumSitesForSelectAll: null,
    prevPath: null,
  },
  reducers: {
    setSiteDetailsFormMode(state, action: PayloadAction<FormMode>) {
      state.siteDetailsMode = action.payload;
    },
    setTableViewSites(state, action) {
      state.table.sites = action.payload;
    },
    setBulkOperationContext(state, action) {
      state.table.bulkOperationContext = action.payload;
    },
    setActionOpenedInTableView(state, action) {
      state.table.actionOpenedInTableView = action.payload;
    },
    setIsSiteListTableView(state, action) {
      state.table.isTableView = action.payload;
    },
    setTextFilter(state, action) {
      state.textFilter = action.payload;
    },

    setAssociationFilter(state, action) {
      state.associationFilter = action.payload;
    },
    setActiveFilterBtn(state, action) {
      state.activeFilterBtn = action.payload;
    },
    setSitesListPages(state, action: PayloadAction<SitesListItemPagedResponse>) {
      state.list = {
        ...state.list,
        chunks: {
          [action.payload.currentPage]: action.payload.items,
        },
      };
    },
    setSitesListPagesClear(state) {
      state.list.chunks = {};
      state.list.pagesAreCleared = true;
      state.list.currentPage = 1;
    },
    setSitesListTotalItems(state, action) {
      state.list.totalItems = action.payload;
    },
    setSitesListPagesAreLoading(state) {
      state.list.pageIsLoading = true;
    },
    setSitesListCurrentPage(state, action: PayloadAction<number>) {
      state.list.currentPage = action.payload;
    },
    updateDetails(state, action: PayloadAction<SiteDetails>) {
      state.details = action.payload;
    },
    updateLocationInDetails(state, action: PayloadAction<SiteLocation>) {
      state.details.location = action.payload;
    },
    updateDeviceInDetails(state, action: PayloadAction<DeviceDetails>) {
      if (state?.details?.device) {
        state.details.device = action.payload;
      }
    },
    resetDetails(state) {
      state.details = {};
      state.sitePhotosStatistics = { pending: 0, uploaded: 0 };
      state.detailsLoadingStatus.actionFinished = false;
      state.detailsLoadingStatus.error = false;
    },
    setEditedSiteDetailsMarker(state, action: PayloadAction<SiteLocationInfo>) {
      state.editedSiteDetailsMarker = action.payload;
    },
    setEditedSiteDetailsMarkerLocation(state, action: PayloadAction<GeoLocation>) {
      state.editedSiteDetailsMarker.location = action.payload;
    },
    setIsEditingSiteDetailsMarker(state, action: PayloadAction<MapEditingState>) {
      state.isEditingSiteLocationOnMap = action.payload;
    },
    setSitePhotosStatistics(state, action: PayloadAction<SitePhotosStatistics>) {
      state.sitePhotosStatistics = action.payload;
    },
    switchAllSitesFavouriteState(state, action: PayloadAction<{ isFavourite: boolean; forOnePage?: boolean }>) {
      const { isFavourite, forOnePage } = action.payload;

      if (forOnePage && state.table.isTableView) {
        const favoriteSitesOnPage = state.table.sites.filter((site) => site.isSelected)?.length ?? 0;
        const favoriteSitesOnOtherPages = state.numberOfSelectedSites - favoriteSitesOnPage;

        state.numberOfSelectedSites = isFavourite
          ? Math.max(0, state.table.sites.length + favoriteSitesOnOtherPages)
          : Math.max(0, state.numberOfSelectedSites - favoriteSitesOnPage);

        state.table.sites.forEach((site) => (site.isSelected = isFavourite));
        return;
      }

      const newChunks: ISitesListChunk = {};
      const keys: string[] = Object.keys(state.list.chunks);
      keys.forEach((key) => {
        newChunks[key] = state.list.chunks[key].map((item) => {
          let newItem: SitesListItem = { ...item, isSelected: isFavourite };
          return newItem;
        });
      });
      state.list = { ...state.list, chunks: newChunks };
      state.table.sites.forEach((site) => (site.isSelected = isFavourite));
      state.numberOfSelectedSites = isFavourite ? state.list.totalItems : 0;
    },
    updateOneSiteFavouriteState(state, action: PayloadAction<UpdateSiteItemFavouriteStatus>) {
      const isFavourite = action.payload.isFavourite;

      state.numberOfSelectedSites = isFavourite
        ? Math.max(0, state.numberOfSelectedSites + 1)
        : Math.max(0, state.numberOfSelectedSites - 1);

      if (state.table.isTableView) {
        const favouriteSite = state.table.sites.find((site) => site.id === action.payload.id);
        favouriteSite.isSelected = isFavourite;
        return;
      }

      if (state.list.chunks) {
        const keys: string[] = Object.keys(state.list.chunks);
        const chunkNo = keys.find((key) => state.list.chunks[key].some((item) => item.id === action.payload.id));

        if (!chunkNo) return;

        const newChunk = state.list.chunks[chunkNo].map((item) => {
          if (item.id === action.payload.id) {
            item.isSelected = isFavourite;
          }
          return item;
        });

        state.list.chunks[chunkNo] = newChunk;
      }
    },
    resetClusters(state) {
      state.clusters = [];
    },
    setSitesSearchIn(state, action: PayloadAction<SitesSearchInFilterTypes>) {
      state.searchIn = action.payload;
    },
    setMaxSitesForSelectAll(state, action: PayloadAction<number>) {
      state.maximumSitesForSelectAll = action.payload;
    },
    setPrevPath(state, action: PayloadAction<string>) {
      state.prevPath = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchClusters.fulfilled, (state, action: PayloadAction<SiteClustersList>) => {
      state.clusters = action.payload.clusters?.sort((a, b) => a.sitesCount - b.sitesCount);
    });

    builder.addCase(fetchSitesForMap.fulfilled, (state, action: PayloadAction<SitesAndMastsOnMap>) => {
      state.sites = action.payload?.sites ?? [];
      state.masts = action.payload?.masts ?? [];
    });
    builder.addCase(fetchSitesForList.pending, (state) => {
      state.virtualScrollIsLoading = true;
      state.list.pageIsLoading = true;
    });
    builder.addCase(fetchSitesForList.rejected, (state) => {
      state.virtualScrollIsLoading = false;
    });
    builder.addCase(fetchSitesForList.fulfilled, (state, action: PayloadAction<SitesList>) => {
      if (state.table.isTableView) {
        state.table.sites = action.payload.items;
      } else {
        const totalPagesCount = Math.ceil(action.payload.itemsTotal / action.payload.pageSize);
        state.list.chunks = {
          ...state.list.chunks,
          [action.payload.currentPage]: action.payload.items,
        };
        state.list.totalItems = action.payload.itemsTotal;
        state.list.pageIsLoading = false;
        state.list.pagesAreCleared = false;
        state.list.totalPages = totalPagesCount;
      }
      state.virtualScrollIsLoading = false;
    });

    builder.addCase(fetchSiteDetails.fulfilled, (state, action) => {
      state.details = action.payload;
      state.detailsLoadingStatus.actionFinished = true;
      state.detailsLoadingStatus.error = false;
    });
    builder.addCase(fetchSiteDetails.pending, (state, action) => {
      state.details = {};
      state.detailsLoadingStatus.actionFinished = false;
      state.detailsLoadingStatus.error = false;
    });
    builder.addCase(fetchSiteDetails.rejected, (state, action) => {
      state.detailsLoadingStatus.actionFinished = false;
      state.detailsLoadingStatus.error = true;
    });
    builder.addCase(createSiteDetails.fulfilled, (state, action: PayloadAction<number>) => {
      state.details.id = action.payload;
    });
    builder.addCase(updateSiteDetails.fulfilled, (state, action: PayloadAction<SiteDetails>) => {
      state.details = action.payload;
    });
    builder.addCase(updateSiteLocation.fulfilled, (state, action) => {
      state.details = { ...state.details, location: action.payload };
    });
    builder.addCase(fetchDevicesToLink.fulfilled, (state, action: PayloadAction<PagedResponse<Device>>) => {
      devicesListToLinkAdapter.setAll(state.devicesListToLinkEntity, action.payload.items);
    });
    builder.addCase(fetchDevicesToLink.rejected, (state) => {
      devicesListToLinkAdapter.setAll(state.devicesListToLinkEntity, []);
    });
    builder.addCase(fetchNumberOfSelectedSites.fulfilled, (state, action: PayloadAction<number>) => {
      state.numberOfSelectedSites = action.payload;
    });
    builder.addCase(fetchNumberOfSelectedSites.rejected, (state) => {
      state.numberOfSelectedSites = 0;
    });
  },
});
export type SitesState = ReturnType<typeof sitesSlice.reducer>;

export const devicesListToLinkEntitySelectors = devicesListToLinkAdapter.getSelectors<RootState>(
  (state) => state.sites.devicesListToLinkEntity
);

export const {
  setSiteDetailsFormMode,
  setSitesListPages,
  setTextFilter,
  setAssociationFilter,
  setActiveFilterBtn,
  setSitesListPagesAreLoading,
  setSitesListPagesClear,
  setSitesListTotalItems,
  setSitesListCurrentPage,
  updateDetails,
  resetDetails,
  setEditedSiteDetailsMarker,
  setEditedSiteDetailsMarkerLocation,
  setIsEditingSiteDetailsMarker,
  updateDeviceInDetails,
  updateLocationInDetails,
  switchAllSitesFavouriteState,
  updateOneSiteFavouriteState,
  setSitePhotosStatistics,
  resetClusters,
  setSitesSearchIn,
  setIsSiteListTableView,
  setActionOpenedInTableView,
  setBulkOperationContext,
  setTableViewSites,
  setMaxSitesForSelectAll,
  setPrevPath,
} = sitesSlice.actions;
